/* eslint-disable max-len */
import React from 'react'

const CodeUpdateIcon = props => (
  <svg width={26} height={22} viewBox='0 0 26 22' {...props}>
    <path
      fill='#FFF'
      d='M2.786 0C1.258 0 0 1.242 0 2.75v10.083h1.857V5.5h22.286v13.75a.91.91 0 0 1-.929.917H20.43V22h2.785C24.742 22 26 20.758 26 19.25V2.75C26 1.242 24.742 0 23.214 0H2.786zm0 1.833h20.428a.91.91 0 0 1 .929.917v.917H1.857V2.75a.91.91 0 0 1 .929-.917zm6.456 11L7.225 22h1.901l2.017-9.167h-1.9zm-4.56.083l-4.559 4.5 4.56 4.502 1.313-1.297-3.247-3.204 3.247-3.205-1.313-1.296zm9.25 0l-1.313 1.296 3.247 3.205-3.247 3.204 1.313 1.297 4.56-4.501-4.56-4.501z'
    />
  </svg>
)

export default CodeUpdateIcon
