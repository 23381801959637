export const colors = {
  black: 'rgb(40, 40, 40)',
  white: 'rgb(255, 255, 255)',
  blackThree: 'rgb(19, 19, 19)',
  greyishBrown: 'rgb(74, 74, 74)',
  greyishBrown50: 'rgba(74, 74, 74, 0.5)',
  paleTurquoise: 'rgb(172, 247, 208)',
  turquoiseThree: 'rgb(175, 236, 216)',
  greenishTeal: 'rgb(69, 203, 159)'
}

export const commonStyles = {
  gridContainer: {
    height: '100%'
  }
}

export default {
  typography: {
    fontFamily: '"Brandon Text", sans-serif'
  },
  overrides: {
    MuiGrid: {
      'spacing-xs-8': {
        width: 'calc(100% + 10px)',
        margin: -5
      }
    },
    MuiButton: {
      root: {
        padding: '0 16px',
        minWidth: 'auto',
        fontWeight: 'bold',
        letterSpacing: 1,
        fontSize: 12,
        minHeight: 40,
        borderRadius: 4
      },
      sizeSmall: {
        padding: '0 16px',
        minWidth: 'auto',
        fontSize: 12,
        minHeight: 30
      },
      disabled: {
        opacity: 0.5
      }
    },
    MuiFormLabel: {
      root: {
        color: colors.greyishBrown50,
        '&$focused': {
          color: colors.greenishTeal
        }
      },
      focused: {
        color: colors.greenishTeal
      }
    },
    MuiInput: {
      root: {
        fontWeight: 'bold',
        color: colors.greyishBrown,
        lineHeight: '19px'
      },
      underline: {
        '&::before': {
          borderBottom: `1px solid #d7d7d7`
        },
        '&::after': {
          borderBottom: `1px solid ${colors.greenishTeal}`
        },
        '&:hover': {
          '&::before': {
            borderBottom: `1px solid ${colors.greyishBrown}`
          }
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid ${colors.greenishTeal}`
        }
      },
      focused: {
        '&::after': {
          backgroundColor: colors.greenishTeal
        }
      },
      formControl: {
        'label + &': {
          marginTop: 15
        }
      }
    },
    MuiSelect: {
      selectMenu: {
        lineHeight: '19px'
      },
      select: {
        '&:focus': {
          background: 'transparent'
        }
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -8
      },
      label: {
        color: colors.greyishBrown,
        fontSize: '12px',
        marginLeft: '2px'
      }
    },
    MuiCheckbox: {
      root: {
        width: 25,
        height: 16
      },
      checked: {
        color: colors.greyishBrown
      },
      colorSecondary: {
        color: colors.greyishBrown,
        '&$checked': {
          color: colors.greenishTeal
        }
      }
    },
    MuiCircularProgress: {
      circle: {
        color: colors.black
      }
    },
    MuiMenuItem: {
      selected: {
        backgroundColor: '#c1f5da !important'
      }
    },
    MuiTouchRipple: {
      child: {
        opacity: 1,
        backgroundColor: colors.paleTurquoise
      }
    }
  }
}
