import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import './style.css'

export default function Loader () {
  return (
    <div className='loader'>
      <CircularProgress size={50} />
    </div>
  )
}
