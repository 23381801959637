/* eslint-disable no-shadow */
import {
  all, call, put, takeLatest, select
} from 'redux-saga/effects'
import { Api, FixtureApi } from '../services'
import {
  getBlogPostsSuccess, getBlogPostsFailed, getBlogPostsByTagSuccess, getBlogPostsByTagFailed, getBlogPostBySlugSuccess, getBlogPostBySlugFailed,
  getBlogPostsByAuthorSuccess, getBlogPostsByAuthorFailed, getBlogPostsBySearchSuccess, getBlogPostsBySearchFailed,
  postBriefFormSuccess, postBriefFormFailed, postJoinFormSuccess, postJoinFormFailed, getAuthorState, getTagsState, getSearchState,
  getRequestLimit
} from '../redux/reducer'
import debugConfig from '../config/debugConfig'

const api = debugConfig.useFixtures ? FixtureApi : Api.create()

function * getBlogPosts (api, action) {
  const { page } = action.payload
  const limit = yield select(getRequestLimit)

  try {
    const result = yield call(api.getPosts, page, limit)
    yield put(getBlogPostsSuccess(result.data.data))
  } catch (error) {
    yield put(getBlogPostsFailed(error))
  }
}

function * getBlogPostBySlug (api, action) {
  try {
    const result = yield call(api.searchPostBySlug, action.payload)
    yield put(getBlogPostBySlugSuccess(result.data.data))
  } catch (error) {
    yield put(getBlogPostBySlugFailed(error))
  }
}

function * getBlogPostsByTag (api, action) {
  const { tag } = action.payload
  const limit = yield select(getRequestLimit)
  const { page } = yield select(getTagsState)

  try {
    const result = yield call(api.searchPostsByTag, tag, page, limit)
    yield put(getBlogPostsByTagSuccess(result.data.data))
  } catch (error) {
    yield put(getBlogPostsByTagFailed(error))
  }
}

function * getBlogPostsByAuthor (api, action) {
  const { authorName } = action.payload
  const limit = yield select(getRequestLimit)
  const authorState = yield select(getAuthorState)
  const { page } = authorState

  try {
    const result = yield call(api.searchPostsByAuthor, authorName, page, limit)
    yield put(getBlogPostsByAuthorSuccess(result.data.data))
  } catch (error) {
    yield put(getBlogPostsByAuthorFailed(error))
  }
}

function * getBlogPostsBySearch (api, action) {
  const { searchTerm } = action.payload
  const limit = yield select(getRequestLimit)
  const searchState = yield select(getSearchState)
  const { page } = searchState

  try {
    const result = yield call(api.searchPostsBySearch, searchTerm, page, limit)
    yield put(getBlogPostsBySearchSuccess(result.data.data))
  } catch (error) {
    yield put(getBlogPostsBySearchFailed(error))
  }
}

function * postBriefUsForm (api, action) {
  try {
    const result = yield call(api.submitBriefForm, action.payload)
    yield put(postBriefFormSuccess(result.data))
    let dataLayer = window.dataLayer = window.dataLayer || []
    if (dataLayer) {
      dataLayer.push({'event': 'postBriefFormSuccess', 'page': window.location.href})
    }
  } catch (error) {
    yield put(postBriefFormFailed(error))
  }
}

function * postJoinUsForm (api, action) {
  try {
    const result = yield call(api.submitJoinUsForm, action.payload)
    yield put(postJoinFormSuccess(result.data))
  } catch (error) {
    yield put(postJoinFormFailed(error))
  }
}

export default function * rootSaga () {
  yield all([
    takeLatest('GET_BLOG_POSTS_REQUEST', getBlogPosts, api),
    takeLatest('GET_POSTS_BY_SLUG_REQUEST', getBlogPostBySlug, api),
    takeLatest('GET_POSTS_BY_TAG_REQUEST', getBlogPostsByTag, api),
    takeLatest('GET_POSTS_BY_AUTHOR_REQUEST', getBlogPostsByAuthor, api),
    takeLatest('GET_POSTS_BY_SEARCH_REQUEST', getBlogPostsBySearch, api),
    takeLatest('POST_BRIEF_FORM_REQUEST', postBriefUsForm, api),
    takeLatest('POST_JOIN_FORM_REQUEST', postJoinUsForm, api)
  ])
}

/* eslint-enable */
