import Loadable from '@yeutech-lab/react-loadable'
import Loader from '../components/_common/Loader/Loader'

const Home = Loadable({ loader: () => import('./Home/Home'), loading: Loader })
const Services = Loadable({ loader: () => import('./Services/Services'), loading: Loader })
const Packages = Loadable({ loader: () => import('./Packages/Packages'), loading: Loader })
const Process = Loadable({ loader: () => import('./Process/Process'), loading: Loader })
const Technologies = Loadable({ loader: () => import('./Technologies/Technologies'), loading: Loader })
const Projects = Loadable({ loader: () => import('./Projects/Projects'), loading: Loader })
const CaseStudies = Loadable({ loader: () => import('./CaseStudies/CaseStudies'), loading: Loader })
const Clients = Loadable({ loader: () => import('./Clients/Clients'), loading: Loader })
const Company = Loadable({ loader: () => import('./Company/Company'), loading: Loader })
const Team = Loadable({ loader: () => import('./Team/Team'), loading: Loader })
const Office = Loadable({ loader: () => import('./Office/Office'), loading: Loader })
const Blog = Loadable({ loader: () => import('./Blog/Blog'), loading: Loader })
const Post = Loadable({ loader: () => import('../components/blog/Post/Post'), loading: Loader })
const Visit = Loadable({ loader: () => import('./Visit/Visit'), loading: Loader })
const Brief = Loadable({ loader: () => import('./Brief/Brief'), loading: Loader })
const Join = Loadable({ loader: () => import('./Join/Join'), loading: Loader })

export {
  Home,
  Services,
  Packages,
  Process,
  Technologies,
  Projects,
  CaseStudies,
  Clients,
  Company,
  Team,
  Office,
  Blog,
  Post,
  Visit,
  Brief,
  Join
}
