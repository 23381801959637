import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-id-swiper'
import { Link } from 'react-router-dom'
import ScrollAnimation from '../../_common/ScrollAnimation'
import { TABLET_VIEW } from '../../../constants/dimentions'
import './style.css'

ExamplesSlider.propTypes = {
  examples: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    slug: PropTypes.string
  }))
}

function ExamplesSlider (props) {
  const { examples } = props
  const sliderSettings = {
    slidesPerView: 'auto',
    centeredSlides: false,
    loop: true,
    autoplay: {
      disableOnInteraction: false
    },
    noSwiping: false,
    speed: 600,
    spaceBetween: 21,
    breakpoints: {
      [`${TABLET_VIEW}`]: {
        spaceBetween: 12
      }
    }
  }
  return (
    <div className='examples'>
      <ScrollAnimation effect='fadeInBigBottom'>
        <h3 className='examples__title'>Examples</h3>
      </ScrollAnimation>
      <ScrollAnimation effect='fadeInRight'>
        <Slider {...sliderSettings}>
          {examples && examples.map(({
            id, title, text, image, slug
          }) => (
            <Link to={`/portfolio/projects/${slug}`} key={id}>
              <img src={image} alt={title} />
              <div className='example-text example-text_title'>{title}</div>
              <div className='example-text example-text_description'>{text}</div>
            </Link>
          ))}
        </Slider>
      </ScrollAnimation>
    </div>
  )
}

export default ExamplesSlider
