import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Picture from '../../_common/Picture'
import { H5Bold } from '../../typography'
import './style.css'

PackageBanner.propTypes = {
  headline: PropTypes.string,
  shortDescription: PropTypes.string,
  images: PropTypes.shape({
    bg: PropTypes.shape({})
  }),
  title: PropTypes.string
}

function PackageBanner (props) {
  const {
    headline, shortDescription, images, title
  } = props
  return (
    <div className='package-item__banner'>
      <div className='banner-content'>
        <h2 className='banner-content__headline'>{headline}</h2>
        <div className='banner-content__feature'>
          <div className='feature__content'>
            <h1 className='title package-item__link-title'>
              <Link to='/approach/packages'>approach / packages / </Link>
            </h1>
            <H5Bold className='feature__content-title'>{title}</H5Bold>
            <div className='feature__content-short'>
              <p>{shortDescription}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='banner-bg'>
        <Picture {...images.bg} alt={`${title} background`} />
      </div>
    </div>
  )
}

export default PackageBanner
