import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

Reason.propTypes = {
  firstText: PropTypes.string.isRequired,
  firstWords: PropTypes.arrayOf(PropTypes.string).isRequired,
  secondText: PropTypes.string,
  secondWords: PropTypes.arrayOf(PropTypes.string)
}

function Reason ({
  firstText, firstWords, secondText = '', secondWords = ''
}) {
  const markWords = words => words.map((word, i) => (
    <span key={i}>
      <mark className='reason__mark'>
        <span className='reason__mark-text'>{word}</span>
      </mark>
      {i !== words.length - 1 ? ' ' : ''}
    </span>
  ))

  return (
    <div className='reason slider-item stripe f'>
      <p className='reason__text'>
        {firstText}
        {markWords(firstWords)}
        {secondText}
        {secondWords && markWords(secondWords)}
      </p>
    </div>
  )
}

export default Reason
