/* eslint-disable max-len */
import React from 'react'

const UsersGroupIcon = props => (
  <svg width={28} height={26} viewBox='0 0 28 26' {...props}>
    <path
      fill='#FFF'
      d='M14 0c-2.195 0-4 1.805-4 4 0 1.094.445 2.094 1.172 2.82v.008c-.445.281-.836.64-1.188 1.047C9.922 5.735 8.156 4 6 4 3.805 4 2 5.805 2 8a4.01 4.01 0 0 0 1.125 2.766C1.273 11.789 0 13.742 0 16h2c0-2.219 1.781-4 4-4 .906 0 1.727.297 2.398.797L10 14v-2c0-2.219 1.781-4 4-4 2.219 0 4 1.781 4 4v2l1.602-1.203A3.984 3.984 0 0 1 22 12c2.219 0 4 1.781 4 4h2c0-2.258-1.273-4.21-3.125-5.234A4.006 4.006 0 0 0 26 8c0-2.195-1.805-4-4-4-2.156 0-3.922 1.734-3.992 3.875a5.218 5.218 0 0 0-1.18-1.055A3.981 3.981 0 0 0 18 4c0-2.195-1.805-4-4-4zm0 2c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zM6 6c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm16 0c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zM8 14c-2.195 0-4 1.805-4 4a4.01 4.01 0 0 0 1.125 2.766C3.273 21.789 2 23.742 2 26h2c0-2.219 1.781-4 4-4 2.219 0 4 1.781 4 4h2c0-2.258-1.273-4.21-3.125-5.234A4.006 4.006 0 0 0 12 18c0-2.195-1.805-4-4-4zm6 12h2c0-2.219 1.781-4 4-4 2.219 0 4 1.781 4 4h2c0-2.258-1.273-4.21-3.125-5.234A4.006 4.006 0 0 0 24 18c0-2.195-1.805-4-4-4-2.195 0-4 1.805-4 4a4.01 4.01 0 0 0 1.125 2.766C15.273 21.789 14 23.742 14 26zM8 16c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm12 0c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2z'
    />
  </svg>
)

export default UsersGroupIcon
