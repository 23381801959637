import React from 'react'

const AstericksIcon = props => (
  <svg width={26} height={24} viewBox='0 0 24 26' {...props}>
    <path
      fill='#FFF'
      d='M12 0v11.702L.591 8.089 0 10.005l11.3 3.581L4 22.743 5.556 24l7.44-9.33 7.44 9.33 1.556-1.257-7.3-9.157L26 10.006l-.607-1.917-11.4 3.613V0z'
    />
  </svg>
)

export default AstericksIcon
