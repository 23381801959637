import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ScrollAnimation from '../../../_common/ScrollAnimation'
import './style.css'

Time.propTypes = {
  time: PropTypes.shape({
    val: PropTypes.string,
    name: PropTypes.string,
    option: PropTypes.string
  })
}

function Time (props) {
  const { time } = props
  const { val, name, option } = time
  return (
    <div className='service-time'>
      <ScrollAnimation className='service-time__content' effect='fadeInRight'>
        <div className='service-separator' />
        <div className='service-time__val'>{val}</div>
        <div className='service-time__name'>{name}</div>
        {option && (
          <div className={cn('service-time__option', { 'option-free': option === 'free' })}>
            {option}
          </div>
        )}
      </ScrollAnimation>
    </div>
  )
}

export default Time
