import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const H5Bold = ({ children, className }) => <h5 className={cn('H5Bold', className)}>{children}</h5>

H5Bold.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default H5Bold
