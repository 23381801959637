/* eslint-disable max-len */
import React from 'react'

const CloudIcon = props => (
  <svg width={29} height={24} viewBox='0 0 29 24' {...props}>
    <path
      fill='#FFF'
      d='M14.5 0c-3.32 0-6.21 2.314-7.01 5.494-1.953.29-3.504 1.852-3.808 3.882A5.468 5.468 0 0 0 0 14.536c0 3.002 2.443 5.451 5.438 5.451h5.437V18.17H5.437a3.637 3.637 0 0 1-3.625-3.634 3.645 3.645 0 0 1 2.903-3.563l.744-.213-.021-.767c0-1.504 1.217-2.725 2.626-2.732l.97.021.113-.788c.376-2.668 2.677-4.677 5.353-4.677a5.443 5.443 0 0 1 5.013 3.322l.318.78.793-.27a3.59 3.59 0 0 1 1.126-.198 3.637 3.637 0 0 1 3.625 3.634c0 .426-.085.859-.255 1.292l-.255.695.623.398a3.619 3.619 0 0 1 1.7 3.066 3.637 3.637 0 0 1-3.625 3.634h-3.625v1.817h3.625c2.994 0 5.437-2.449 5.437-5.451a5.415 5.415 0 0 0-1.968-4.188c.106-.418.155-.844.155-1.263 0-3.308-2.916-6.005-6.357-5.366A7.244 7.244 0 0 0 14.5 0zm1.813 10.902v1.817h1.812v-1.817h-1.813zm-3.625 3.634v1.817H14.5v-1.817h-1.813zm3.624 0v1.817h1.813v-1.817h-1.813zm-3.625 3.634v1.817H14.5V18.17h-1.813zm3.626 0v1.817h1.812V18.17h-1.813zm-3.625 3.634v1.817H14.5v-1.817h-1.813z'
    />
  </svg>
)

export default CloudIcon
