/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URLS } from '../../../constants/urls'
import NavigationLinkTablet from '../NavigationLinkTablet/NavigationLinkTablet'
import MuiButton from '../../_common/MuiButton/MuiButton'
import LogoIcon from '../../_icons/LogoIcon'
import './style.css'

class TabletNavigation extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }

  state = {
    activeLinkId: 0,
    showSubNavigation: false,
    colorTheme: 'light'
  }

  DARK = 'dark'

  LIGHT = 'light'

  componentDidMount () {
    this.setNavigationTheme()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { location } = this.props
    const { pathname } = location

    if (prevProps.location.pathname !== pathname && pathname === '/') {
      this.setState({ activeLinkId: 0, showSubNavigation: false })
      this.toggleNavigationColorTheme(this.LIGHT)
    }
    if (prevProps.location.pathname !== pathname && prevProps.location.pathname === '/') {
      this.toggleNavigationColorTheme(this.DARK)
      this.setNavigationTheme()
    }
  }

  setNavigationTheme = () => {
    const { location } = this.props
    const { pathname } = location
    const isHomePage = pathname === '/'
    let routeId = 0
    Object.values(URLS).map(url => (pathname.match(url.path) && pathname !== isHomePage ? (routeId = url.id) : 0))
    this.setState({ activeLinkId: routeId, colorTheme: isHomePage ? this.LIGHT : this.DARK })
    // eslint-disable-next-line max-len
    const isSubNavigation = (!isHomePage && Object.values(URLS).find(url => url.id === routeId) && Object.values(URLS).find(url => url.id === routeId).routes) || false
    if (isSubNavigation) this.setState({ showSubNavigation: true })
  }

  toggleNavigationColorTheme = (theme) => {
    this.setState({ colorTheme: theme })
  }

  handleNavigationLinkClick = (id) => {
    this.setState({ activeLinkId: id })
    this.toggleMenuPanel(id)
  }

  toggleMenuPanel = (id) => {
    const { activeLinkId } = this.state
    const isSubNavigation = Object.values(URLS).find(url => url.id === id).routes
    if (id !== activeLinkId) this.setState({ showSubNavigation: isSubNavigation })
  }

  render () {
    const { activeLinkId, showSubNavigation, colorTheme } = this.state
    return (
      <div
        className={
          `header-tablet theme__${colorTheme}
           sub-navigation__${showSubNavigation ? 'expanded' : 'hidden'}
          `}
      >
        <div className='header-tablet__menu-panel'>
          <Link className='header-tablet__logo' to='/'>
            <LogoIcon className='header-tablet__header-logo' darkTheme={colorTheme === this.LIGHT} />
            <div className='header-tablet__header-title'>7 glyphs</div>
          </Link>
          <nav className='tablet-navigation__body'>
            <ul className='tablet-navigation__body-routes'>
              {Object.keys(URLS).map(route => (
                <div key={URLS[route].path}>
                  <NavigationLinkTablet
                    {...URLS[route]}
                    activeLinkId={activeLinkId}
                    onActiveLinkIdChange={this.handleNavigationLinkClick}
                  />
                </div>
              ))}
            </ul>
          </nav>
          <Link to='/get-in-touch/brief' className='tablet-navigation__btn'>
            <MuiButton kind='black' onClick={() => this.handleNavigationLinkClick(URLS.getInTouch.id)}>Brief us</MuiButton>
          </Link>
        </div>
      </div>
    )
  }
}

export default withRouter(TabletNavigation)
