import {
  EMAIL, PHONE, FACEBOOK, INSTAGRAM, TWITTER, LINKEDIN
} from '../constants/links'
import { FOOTER_URLS } from '../constants/urls'
import IMAGES from '../utils/imagesProvider'

export default {
  footerLinks: FOOTER_URLS,
  contacts: {
    email: EMAIL,
    phone: PHONE,
    address:
      `137 Buckley Avenue, Hobsonville,
       Auckland, NZ`,
    socials: [
      {
        icon: IMAGES.icon_footer_twitter,
        alt: 'twitter link',
        linkTo: TWITTER
      },
      {
        icon: IMAGES.icon_footer_instagram,
        alt: 'instagram link',
        linkTo: INSTAGRAM
      },
      {
        icon: IMAGES.icon_footer_facebook,
        alt: 'facebook link',
        linkTo: FACEBOOK
      },
      {
        icon: IMAGES.icon_footer_linkedin,
        alt: 'linkedIn link',
        linkTo: LINKEDIN
      }
    ]
  },
  copyrightInfo: `7 glyphs™ Ltd. Copyright © ${new Date().getFullYear()}. All rights reserved.`
}
