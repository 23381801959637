/* eslint-disable max-len */
import React from 'react'

const StarImprovementsIcon = props => (
  <svg width={28} height={28} viewBox='0 0 28 28' {...props}>
    <path
      fill='#FFF'
      d='M14-.078l-.922 2.187-3.172 7.532-9.922 1.078 7.422 7-2.11 10.328L14 22.75l8.695 5.297-2.101-10.328 7.414-7-9.914-1.078L14-.08zm0 5.148l2.71 6.43 6.782.734-5.078 4.797 1.406 6.922L14 20.406l-5.828 3.547 1.414-6.922-5.07-4.797 6.773-.734L14 5.07z'
    />
  </svg>
)

export default StarImprovementsIcon
