import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import DesignSlider from '../DesignSlider/DesignSlider'
import SupportPlans from '../SupportPlans/SupportPlans'
import TechStackList from '../TechStackList/TechStackList'
import ServiceFeatures from '../ServiceFeatures/ServiceFeatures'
import Hours from './Hours/Hours'
import Time from './Time/Time'
import IMAGES from '../../../utils/imagesProvider'
import { View } from '../../typography'
import './style.css'

PackService.propTypes = {
  index: PropTypes.number,
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  time: PropTypes.shape({
    val: PropTypes.string,
    name: PropTypes.string,
    option: PropTypes.string
  }),
  keyItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.shape({})
  })),
  stackItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
    linkTo: PropTypes.string
  })),
  level: PropTypes.string,
  designExamples: PropTypes.shape({}),
  supportPlans: PropTypes.shape({})
}

function PackService (props) {
  const {
    index, title, text, time, keyItems, stackItems, level, id, designExamples, supportPlans
  } = props
  const serviceSupport = id === 'service_support'
  const subservice = level === 'subservice'
  const ServiceBlock = () => (
    <div className={cn('service-content-block', { bordered: time, 'service-support': serviceSupport })}>
      <div className='service__title-block'>
        <span className='service-index'>
          {
            level === 'service' ? `0${index + 1}` : <img src={IMAGES.icon_star} alt='star' />
          }
        </span>
        <h4 className='service-title'>{title}</h4>
      </div>
      <View className='service-text'>{text}</View>
      {serviceSupport && <Hours />}
    </div>
  )
  return (
    <div className={cn('service', { sublevel: subservice })}>
      <ServiceBlock />
      {time && <Time time={time} />}
      {keyItems && <ServiceFeatures keyItems={keyItems} />}
      {stackItems && <TechStackList stackItems={stackItems} />}
      {designExamples && <DesignSlider {...designExamples} />}
      {supportPlans && <SupportPlans supportPlans={supportPlans} />}
    </div>
  )
}

export default PackService
