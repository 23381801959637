import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Waypoint from 'react-waypoint'

export default class ScrollAnimation extends Component {
  state = {
    isReach: false
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    effect: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    duration: PropTypes.number,
    delay: PropTypes.number,
    offset: PropTypes.number
  }

  static defaultProps = {
    className: '',
    id: '',
    duration: 1000,
    delay: 0,
    offset: 20
  }

  handleWaypointEnter = () => {
    const { isReach } = this.state
    !isReach && this.setState({ isReach: true })
  }

  render () {
    const {
      children, effect, duration, delay, offset, className, id
    } = this.props
    const { isReach } = this.state
    return (
      <div
        className={cn('scroll-animation', className)}
        id={id}
        style={isReach ? { animation: `${effect} ${duration}ms cubic-bezier(0.4, 0, 0.2, 1) ${delay}ms both` } : null}
      >
        <Waypoint
          bottomOffset={offset}
          onEnter={this.handleWaypointEnter}
        />
        {children}
        <Waypoint
          topOffset={offset}
          onEnter={this.handleWaypointEnter}
        />
      </div>
    )
  }
}
