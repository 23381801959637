import IMAGES from '../utils/imagesProvider'
import { SERVICES } from './commonServices'
import { NBRSP } from '../constants/textFormat'

export default {
  development: [
    {
      id: 'dev_ios',
      title: 'iOS application development',
      subTitle: 'iOS application development',
      headline: 'Enhance your business with an iOS app',
      shortDescription: 'The most beautiful iOS applications created with the best technologies ' +
        'suited to your business needs. Easy to use with excellent user experience.',
      description: 'To make sure that your business thrives in a world where mobile phones are ubiquitous, a beautiful ' +
        'and easy to use iOS application is essential. Plenty of mobile phone users prefer Apple for its user interface and ' +
        'high-quality hardware components, which is why having an iOS application will allow you to reach a bigger audience. ' +
        '7 glyphs creates beautiful iOS applications that allow you to build your brand and improve your customer engagement.',
      label: 'popular',
      tags: 'Research | UX | UI | iOS Development | Testing | Release | Support',
      text: 'The most beautiful iOS applications created with the best technologies suited to your business needs. ' +
        'Easy to use with excellent user experience.',
      time: {
        inWeeks: '19 – 56 weeks',
        inHours: '380 – 1400 hours'
      },
      linkName: 'iOS application development',
      linkTo: 'ios-application-development',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.ios,
          md: IMAGES.packages.bgLarge.ios,
          lg: IMAGES.packages.bgLarge.ios
        },
        cover: IMAGES.packages.covers.development.ios,
        serviceCover: IMAGES.packages.service.development.ios
      },
      reasons: [
        {
          id: 1, firstText: 'We follow the ', firstWords: ['latest', 'trends'], secondText: ' in iOS design and development'
        },
        {
          id: 2, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 3, firstText: 'Our services allow clients to ', firstWords: ['explore']
        },
        {
          id: 4, firstText: 'We use the ', firstWords: ['best', 'technology'], secondText: ' in our solutions'
        },
        {
          id: 5, firstText: 'Our code is ', firstWords: ['easy'], secondText: ' to maintain and ', secondWords: ['support']
        },
        {
          id: 6, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 7, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 8, firstText: 'We ', firstWords: ['readily'], secondText: ' provide support and ', secondWords: ['advising']
        },
        {
          id: 9, firstText: 'Our clients are ', firstWords: ['highly', 'involved'], secondText: ' in the process'
        },
        {
          id: 10, firstText: 'We have a ', firstWords: ['transparent'], secondText: ' and systematic ', secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.competitor_analysis_and_research_dev,
        SERVICES.ux_design_ios,
        SERVICES.ui_design,
        SERVICES.app_dev_ios,
        SERVICES.testing_and_automation_ios,
        SERVICES.deployment_ios,
        SERVICES.support
      ],
      examples: [
        {
          id: 14,
          image: IMAGES.projects.StrongHer[0].sm,
          title: 'StrongHer',
          text: 'Turn-key project development',
          slug: 'StrongHer'
        },
        {
          id: 13,
          image: IMAGES.projects.Grabaseat[0].sm,
          title: 'Grabaseat',
          text: 'Mobile app development',
          slug: 'Grabaseat'
        },
        {
          id: 1,
          image: IMAGES.projects.LazyAz[0].sm,
          title: 'LazyAz',
          text: 'Turn-key project development',
          slug: 'LazyAz'
        },
        {
          id: 2,
          image: IMAGES.projects.Goodnest[0].sm,
          title: 'Goodnest',
          text: 'Mobile app development',
          slug: 'Goodnest-Professional'
        },
        {
          id: 3,
          image: IMAGES.projects.SuperShuttle[0].sm,
          title: 'Super Shuttle',
          text: 'Turn-key project development',
          slug: 'Super-Shuttle'
        },
        {
          id: 4,
          image: IMAGES.projects.WhipAround[0].sm,
          title: 'Whip Around',
          text: 'Turn-key project development',
          slug: 'Whip-Around'
        },
        {
          id: 5,
          image: IMAGES.projects.RaisingChildren[0].sm,
          title: 'Raising Children',
          text: 'Mobile and Web development',
          slug: 'Raising-Children'
        },
        {
          id: 6,
          image: IMAGES.projects.Hoist[0].sm,
          title: 'Hoist',
          text: 'UX/UI Design',
          slug: 'Hoist'
        },
        {
          id: 7,
          image: IMAGES.projects.Bubbly[0].sm,
          title: 'Bubbly',
          text: 'iOS and Web development',
          slug: 'Bubbly'
        },
        {
          id: 8,
          image: IMAGES.projects.Brightly[0].sm,
          title: 'Brightly',
          text: 'iOS development',
          slug: 'Brightly'
        },
        {
          id: 9,
          image: IMAGES.projects.Offerbook[0].sm,
          title: 'OfferBook',
          text: 'iOS development',
          slug: 'Offerbook'
        },
        {
          id: 10,
          image: IMAGES.projects.Dayly[0].sm,
          title: 'Day.ly',
          text: 'iOS development',
          slug: 'Day.ly'
        },
        {
          id: 11,
          image: IMAGES.projects.FeedTime[0].sm,
          title: 'Feed Time',
          text: 'iOS and Web development',
          slug: 'Feed-Time'
        },
        {
          id: 12,
          image: IMAGES.projects.EasyWords[0].sm,
          title: 'Easy Words Learning',
          text: 'iOS and Web development',
          slug: 'Easy-Words'
        }
      ]
    },
    {
      id: 'dev_android',
      title: 'Android application development',
      subTitle: 'Android application development',
      headline: 'Let your business flourish with an Android app',
      shortDescription: 'Created with the most sophisticated and usability, Android applications we create are designed to help your business grow.',
      description: 'A large percentage of mobile phone users use Android phones, so having an Android application can ' +
        'add portability and location-targeting to your business, not to mention the expanse of your reach to your existing ' +
        'and potential customers. 7 glyphs can help you build a sophisticated and easy to use Android application that will ' +
        'make your business grow.',
      label: '',
      tags: 'Research | UX | UI | Android Development | Testing | Release | Support',
      text: 'Created with the most sophisticated and usability, Android applications we create are designed to help your business grow.',
      time: {
        inWeeks: '19 – 56 weeks',
        inHours: '380 – 1400 hours'
      },
      linkName: 'Android application development',
      linkTo: 'android-application-development',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.android,
          md: IMAGES.packages.bgLarge.android,
          lg: IMAGES.packages.bgLarge.android
        },
        cover: IMAGES.packages.covers.development.android,
        serviceCover: IMAGES.packages.service.development.android
      },
      reasons: [
        {
          id: 1,
          firstText: 'We follow the ',
          firstWords: ['latest', 'trends'],
          secondText: ' in Android design and development'
        },
        {
          id: 2, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 3, firstText: 'Our services allow clients to ', firstWords: ['explore']
        },
        {
          id: 4, firstText: 'We use the ', firstWords: ['best', 'technology'], secondText: ' in our solutions'
        },
        {
          id: 5,
          firstText: 'Our code is ',
          firstWords: ['easy'],
          secondText: ' to maintain and ',
          secondWords: ['support']
        },
        {
          id: 6, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 7, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 8,
          firstText: 'We ',
          firstWords: ['readily'],
          secondText: ' provide support and ',
          secondWords: ['advising']
        },
        {
          id: 9, firstText: 'Our clients are ', firstWords: ['highly', 'involved'], secondText: ' in the process'
        },
        {
          id: 10,
          firstText: 'We have a ',
          firstWords: ['transparent'],
          secondText: ' and systematic ',
          secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.competitor_analysis_and_research_dev,
        SERVICES.ux_design_android,
        SERVICES.ui_design,
        SERVICES.app_dev_android,
        SERVICES.testing_and_automation_android,
        SERVICES.deployment_android,
        SERVICES.support
      ],
      examples: [
        {
          id: 7,
          image: IMAGES.projects.StrongHer[1].sm,
          title: 'StrongHer',
          text: 'Turn-key project development',
          slug: 'StrongHer'
        },
        {
          id: 6,
          image: IMAGES.projects.Grabaseat[0].sm,
          title: 'Grabaseat',
          text: 'Mobile app development',
          slug: 'Grabaseat'
        },
        {
          id: 1,
          image: IMAGES.projects.LazyAz[2].sm,
          title: 'LazyAz',
          text: 'Turn-key project development',
          slug: 'LazyAz'
        },
        {
          id: 2,
          image: IMAGES.projects.Goodnest[3].sm,
          title: 'Goodnest',
          text: 'Mobile app development',
          slug: 'Goodnest-Professional'
        },
        {
          id: 3,
          image: IMAGES.projects.SuperShuttle[2].sm,
          title: 'Super Shuttle',
          text: 'Turn-key project development',
          slug: 'Super-Shuttle'
        },
        {
          id: 4,
          image: IMAGES.projects.WhipAround[3].sm,
          title: 'Whip Around',
          text: 'Turn-key project development',
          slug: 'Whip-Around'
        },
        {
          id: 5,
          image: IMAGES.projects.RaisingChildren[2].sm,
          title: 'Raising Children',
          text: 'Mobile and Web development',
          slug: 'Raising-Children'
        }
      ]
    },
    {
      id: 'dev_mobile',
      title: 'Mobile application development',
      subTitle: 'Mobile application development',
      headline: `A${NBRSP}unique experience with a functional mobile application.`,
      shortDescription: 'With a sleek interface, simple navigation, and optimized performance, each mobile application we create is aimed at catering to the needs of your business.',
      description: 'Business owners usually contemplate on whether their companies really need an application or not, ' +
        'but having a mobile application is an excellent investment because it has features that your customers won’t ' +
        'find in a traditional website. The convenience and personalization of a mobile application allows your customers ' +
        'to engage more and be updated with your latest products and services anytime and anywhere. Our team of expert ' +
        'developers at 7 glyphs can help you turn your design ideas into a beautiful and usable application.',
      label: 'popular',
      tags: 'Research | UX | UI | App Development | Testing | Release | Support',
      text: 'With a sleek interface, simple navigation, and optimized performance, each mobile application we create is aimed at catering to the needs of your business.',
      time: {
        inWeeks: '19 – 56 weeks',
        inHours: '760 – 2800 hours'
      },
      linkName: 'Mobile application development',
      linkTo: 'mobile-application-development',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.mobile,
          md: IMAGES.packages.bgLarge.mobile,
          lg: IMAGES.packages.bgLarge.mobile
        },
        cover: IMAGES.packages.covers.development.mobile,
        serviceCover: IMAGES.packages.service.development.mobile
      },
      reasons: [
        {
          id: 1,
          firstText: 'We follow the ',
          firstWords: ['latest', 'trends'],
          secondText: ' in Mobile applications design and development'
        },
        { id: 2, firstText: 'We create ', firstWords: ['intuitive', 'interfaces'] },
        { id: 3, firstText: 'Our services allow clients to ', firstWords: ['explore'] },
        {
          id: 4, firstText: 'We use the ', firstWords: ['best', 'technology'], secondText: ' in our solutions'
        },
        {
          id: 5, firstText: 'Our code is ', firstWords: ['easy'], secondText: ' to maintain and ', secondWords: ['support']
        },
        {
          id: 6, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 7, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 8, firstText: 'We ', firstWords: ['readily'], secondText: ' provide support and ', secondWords: ['advising']
        },
        {
          id: 9, firstText: 'Our clients are ', firstWords: ['highly', 'involved'], secondText: ' in the process'
        },
        {
          id: 10, firstText: 'We have a ', firstWords: ['transparent'], secondText: ' and systematic ', secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.competitor_analysis_and_research_dev,
        SERVICES.ux_design_mobile,
        SERVICES.ui_design,
        SERVICES.app_dev_mobile,
        SERVICES.testing_and_automation_mobile,
        SERVICES.deployment_mobile,
        SERVICES.support
      ],
      examples: [
        {
          id: 14,
          image: IMAGES.projects.StrongHer[3].sm,
          title: 'StrongHer',
          text: 'Turn-key project development',
          slug: 'StrongHer'
        },
        {
          id: 13,
          image: IMAGES.projects.Grabaseat[0].sm,
          title: 'Grabaseat',
          text: 'Mobile app development',
          slug: 'Grabaseat'
        },
        {
          id: 1,
          image: IMAGES.projects.LazyAz[2].sm,
          title: 'LazyAz',
          text: 'Turn-key project development',
          slug: 'LazyAz'
        },
        {
          id: 2,
          image: IMAGES.projects.Goodnest[0].sm,
          title: 'Goodnest',
          text: 'Mobile app development',
          slug: 'Goodnest-Professional'
        },
        {
          id: 3,
          image: IMAGES.projects.SuperShuttle[2].sm,
          title: 'Super Shuttle',
          text: 'Turn-key project development',
          slug: 'Super-Shuttle'
        },
        {
          id: 4,
          image: IMAGES.projects.WhipAround[0].sm,
          title: 'Whip Around',
          text: 'Turn-key project development',
          slug: 'Whip-Around'
        },
        {
          id: 5,
          image: IMAGES.projects.RaisingChildren[2].sm,
          title: 'Raising Children',
          text: 'Mobile and Web development',
          slug: 'Raising-Children'
        },
        {
          id: 6,
          image: IMAGES.projects.Hoist[0].sm,
          title: 'Hoist',
          text: 'UX/UI Design',
          slug: 'Hoist'
        },
        {
          id: 7,
          image: IMAGES.projects.Bubbly[0].sm,
          title: 'Bubbly',
          text: 'iOS and Web development',
          slug: 'Bubbly'
        },
        {
          id: 8,
          image: IMAGES.projects.Brightly[0].sm,
          title: 'Brightly',
          text: 'iOS development',
          slug: 'Brightly'
        },
        {
          id: 9,
          image: IMAGES.projects.Offerbook[0].sm,
          title: 'OfferBook',
          text: 'iOS development',
          slug: 'Offerbook'
        },
        {
          id: 10,
          image: IMAGES.projects.Dayly[0].sm,
          title: 'Day.ly',
          text: 'iOS development',
          slug: 'Day.ly'
        },
        {
          id: 11,
          image: IMAGES.projects.FeedTime[0].sm,
          title: 'Feed Time',
          text: 'iOS and Web development',
          slug: 'Feed-Time'
        },
        {
          id: 12,
          image: IMAGES.projects.EasyWords[0].sm,
          title: 'Easy Words Learning',
          text: 'iOS and Web development',
          slug: 'Easy-Words'
        }
      ]
    },
    {
      id: 'dev_web',
      title: 'Web application development',
      subTitle: 'Web application development',
      headline: 'Improve your business\'s efficiency.',
      shortDescription: 'Simple, intuitive, and elegant. Our web applications are specifically created with premier tools and technology to cater to your business. Easy to maintain with technical support readily available.',
      description: 'A web application is an excellent tool to boost the online presence of your business and makes for a ' +
        'good investment as well. It’s an easy to use platform that helps you interact better with existing and potential ' +
        'customers, which is why having a team of professional designers and web developers is crucial in building your ' +
        'desired web application. 7 glyphs will help you materialize the perfect web application that is simple, intuitive, ' +
        'and elegant to complement the needs of your business.',
      label: 'popular',
      tags: 'Research | UX | UI | Web App Development | Testing | Release | Support',
      text: 'Simple, intuitive, and elegant. Our web applications are specifically created with premier tools and technology to cater to your business. Easy to maintain with technical support readily available.',
      time: {
        inWeeks: '19 – 52 weeks',
        inHours: '380 – 1300 hours'
      },
      linkName: 'Web application development',
      linkTo: 'web-application-development',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.web,
          md: IMAGES.packages.bgLarge.web,
          lg: IMAGES.packages.bgLarge.web
        },
        cover: IMAGES.packages.covers.development.web,
        serviceCover: IMAGES.packages.service.development.web
      },
      reasons: [
        {
          id: 1,
          firstText: 'We follow the ',
          firstWords: ['latest', 'trends'],
          secondText: ' in Web Design and Development'
        },
        {
          id: 2, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 3, firstText: 'Our services allow clients to ', firstWords: ['explore']
        },
        {
          id: 4, firstText: 'We use the ', firstWords: ['best', 'technology'], secondText: ' in our solutions'
        },
        {
          id: 5,
          firstText: 'Our code is ',
          firstWords: ['easy'],
          secondText: ' to maintain and ',
          secondWords: ['support']
        },
        {
          id: 6, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 7, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 8,
          firstText: 'We ',
          firstWords: ['readily'],
          secondText: ' provide support and ',
          secondWords: ['advising']
        },
        {
          id: 9, firstText: 'Our clients are ', firstWords: ['highly', 'involved'], secondText: ' in the process'
        },
        {
          id: 10,
          firstText: 'We have a ',
          firstWords: ['transparent'],
          secondText: ' and systematic ',
          secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.competitor_analysis_and_research_dev,
        SERVICES.ux_design_web,
        SERVICES.ui_design,
        SERVICES.app_dev_web,
        SERVICES.testing_and_automation_web,
        SERVICES.deployment_web,
        SERVICES.support
      ],
      examples: [
        {
          id: 9,
          image: IMAGES.projects.StrongHer[10].sm,
          title: 'StrongHer',
          text: 'Turn-key project development',
          slug: 'StrongHer'
        },
        {
          id: 8,
          image: IMAGES.projects.RogueTravel[0].sm,
          title: 'Rogue Travel',
          text: 'Web application development',
          slug: 'Rogue-Travel'
        },
        {
          id: 9,
          image: IMAGES.projects.WolfAndFox[0].sm,
          title: 'Wolf and Fox',
          text: 'Web application development',
          slug: 'Wolf-and-Fox'
        },
        {
          id: 10,
          image: IMAGES.projects.QuarryTrucks[0].sm,
          title: 'Quarry Trucks',
          text: 'Web application development',
          slug: 'Quarry-Trucks'
        },
        {
          id: 11,
          image: IMAGES.projects.SharedSpace[0].sm,
          title: 'Shared Space',
          text: 'UX/UI Design',
          slug: 'Shared-Space'
        },
        {
          id: 12,
          image: IMAGES.projects.StarShipIT[0].sm,
          title: 'StarShipIT',
          text: 'UX/UI Design',
          slug: 'StarShipIT'
        },
        {
          id: 1,
          image: IMAGES.projects.LazyAz[4].sm,
          title: 'LazyAz',
          text: 'Turn-key project development',
          slug: 'LazyAz'
        },
        {
          id: 2,
          image: IMAGES.projects.OpenSeas[0].sm,
          title: 'OpenSeas',
          text: 'Web application development',
          slug: 'Open-Seas'
        },
        {
          id: 3,
          image: IMAGES.projects.SuperShuttle[4].sm,
          title: 'Super Shuttle',
          text: 'Turn-key project development',
          slug: 'Super-Shuttle'
        },
        {
          id: 4,
          image: IMAGES.projects.RaisingChildren[4].sm,
          title: 'Raising Children',
          text: 'Turn-key project development',
          slug: 'Raising-Children'
        },
        {
          id: 5,
          image: IMAGES.projects.MVNZ[0].sm,
          title: 'MustvisitNZ',
          text: 'Web application development',
          slug: 'Must-Visit-NZ'
        },
        {
          id: 6,
          image: IMAGES.projects.FeedTime[3].sm,
          title: 'Feed Time',
          text: 'iOS and Web development',
          slug: 'Feed-Time'
        },
        {
          id: 7,
          image: IMAGES.projects.EasyWords[3].sm,
          title: 'Easy Words Learning',
          text: 'iOS and Web development',
          slug: 'Easy-Words'
        }
      ]
    },
    {
      id: 'dev_backend',
      title: 'Backend development & solutions',
      subTitle: 'Backend development & solutions',
      headline: `A solid foundation for${NBRSP}your business.`,
      shortDescription: 'The most durable, effective and scalable backend solutions using different cloud computing platforms. Everything is tested and monitored to guarantee the efficiency of use.',
      description: 'Having the right architecture and backend solution at the beginning is a very crucial part in any ' +
        'application. It allows your business to grow successfully without difficulties and enables a smooth experience ' +
        'for your user. Moreover, it guarantees both you and your customers that the user data is protected and secured ' +
        'at all costs. 7 glyphs handles backend development professionally.',
      label: '',
      tags: 'API Development | Testing | Cloud setup & continuous delivery | Support',
      text: 'The most durable, effective and scalable backend solutions using different cloud computing platforms. Everything is tested and monitored to guarantee the efficiency of use.',
      time: {
        inWeeks: '8 – 14 weeks',
        inHours: '160 – 350 hours'
      },
      linkName: 'Backend development & solutions',
      linkTo: 'backend-development-and-solutions',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.backend,
          md: IMAGES.packages.bgLarge.backend,
          lg: IMAGES.packages.bgLarge.backend
        },
        cover: IMAGES.packages.covers.development.backend,
        serviceCover: IMAGES.packages.service.development.backend
      },
      reasons: [
        {
          id: 1,
          firstText: 'We use AWS, Google Cloud, and Azure ',
          firstWords: ['cloud infrastructure'],
          secondText: ' to host solutions'
        },
        {
          id: 2, firstText: 'We utilize the ', firstWords: ['most', 'efficient'], secondText: ' practices'
        },
        {
          id: 3,
          firstText: 'We use ',
          firstWords: ['continuous delivery'],
          secondText: ' and ',
          secondWords: ['continuous integration']
        },
        {
          id: 4,
          firstText: 'We understand how to ',
          firstWords: ['scale'],
          secondText: ' projects based on their ',
          secondWords: ['growth']
        },
        {
          id: 5,
          firstText: 'We ',
          firstWords: ['monitor'],
          secondText: ' the solution for errors and fix them ',
          secondWords: ['immediately']
        },
        {
          id: 6,
          firstText: 'Our code is ',
          firstWords: ['easy'],
          secondText: ' to maintain and ',
          secondWords: ['support']
        },
        {
          id: 7, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 8, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 9,
          firstText: 'We ',
          firstWords: ['readily'],
          secondText: ' provide support and ',
          secondWords: ['advising']
        },
        {
          id: 10,
          firstText: 'We have a ',
          firstWords: ['transparent'],
          secondText: ' and systematic ',
          secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.app_dev_backend,
        SERVICES.testing_and_automation_backend,
        SERVICES.cloud_setup,
        SERVICES.support
      ],
      examples: [
        {
          id: 13,
          image: IMAGES.projects.StrongHer[10].sm,
          title: 'StrongHer',
          text: 'Turn-key project development',
          slug: 'StrongHer'
        },
        {
          id: 1,
          image: IMAGES.projects.RogueTravel[0].sm,
          title: 'Rogue Travel',
          text: 'Web application development',
          slug: 'Rogue-Travel'
        },
        {
          id: 2,
          image: IMAGES.projects.WolfAndFox[0].sm,
          title: 'Wolf and Fox',
          text: 'Web application development',
          slug: 'Wolf-and-Fox'
        },
        {
          id: 3,
          image: IMAGES.projects.LazyAz[0].sm,
          title: 'LazyAz',
          text: 'Turn-key project development',
          slug: 'LazyAz'
        },
        {
          id: 4,
          image: IMAGES.projects.SuperShuttle[0].sm,
          title: 'Super Shuttle',
          text: 'Turn-key project development',
          slug: 'Super-Shuttle'
        },
        {
          id: 5,
          image: IMAGES.projects.WhipAround[0].sm,
          title: 'Whip Around',
          text: 'Turn-key project development',
          slug: 'Whip-Around'
        },
        {
          id: 7,
          image: IMAGES.projects.Bubbly[0].sm,
          title: 'Bubbly',
          text: 'iOS and Web development',
          slug: 'Bubbly'
        },
        {
          id: 9,
          image: IMAGES.projects.Offerbook[0].sm,
          title: 'OfferBook',
          text: 'iOS development',
          slug: 'Offerbook'
        },
        {
          id: 11,
          image: IMAGES.projects.FeedTime[0].sm,
          title: 'Feed Time',
          text: 'iOS and Web development',
          slug: 'Feed-Time'
        },
        {
          id: 12,
          image: IMAGES.projects.EasyWords[0].sm,
          title: 'Easy Words Learning',
          text: 'iOS and Web development',
          slug: 'Easy-Words'
        }
      ]
    },
    {
      id: 'dev_turn_key',
      title: 'Turn-key project development',
      subTitle: 'Turn-key project development',
      headline: `Build a strong digital presence for${NBRSP}your business.`,
      shortDescription: 'The most complete and effective turn-key solutions developed using the latest technologies and industry best practices. Everything is built to satisfy your specific business requirements.',
      description: 'To make sure that your business thrives in a world where digital products are ubiquitous, ' +
        'a turn-key project will be very beneficial. It\'s complete, scalable, and ready to use once it\'s turned over to you. ' +
        '7 glyphs creates the most stunning digital products that allow you to build your brand and improve your customer engagement.',
      label: '',
      tags: 'Research | UX | UI | App and Web Development | Testing | Release | Support',
      text: 'The most complete and effective turn-key solutions developed using the latest technologies and industry best practices. Everything is built to satisfy your specific business requirements.',
      time: {
        inWeeks: '22 – 64 weeks',
        inHours: '440 – 1600 hours'
      },
      linkName: 'Turn-key project development',
      linkTo: 'turn-key-project-development',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.turn_key,
          md: IMAGES.packages.bgLarge.turn_key,
          lg: IMAGES.packages.bgLarge.turn_key
        },
        cover: IMAGES.packages.covers.development.turn_key,
        serviceCover: IMAGES.packages.service.development.turn_key
      },
      reasons: [
        {
          id: 1,
          firstText: 'Cost-effective and ',
          firstWords: ['saves'],
          secondText: ' time and money in the long run.'
        },
        {
          id: 2,
          firstText: 'Everything is developed ',
          firstWords: ['pixel', 'perfect'],
          secondText: ' according to the design.'
        },
        {
          id: 3,
          firstText: 'Support is provided throughout the whole ',
          firstWords: ['project life cycle.']
        },
        {
          id: 4,
          firstText: 'All components are ',
          firstWords: ['perfectly', 'crafted'],
          secondText: ' to complement each other.'
        },
        {
          id: 5,
          firstText: 'Reactive ',
          firstWords: ['support'],
          secondText: ' is provided for every component and the product as a whole.'
        },
        {
          id: 6,
          firstText: 'We understand how to ',
          firstWords: ['scale'],
          secondText: ' projects based on their ',
          secondWords: ['growth']
        },
        {
          id: 7,
          firstText: 'We ',
          firstWords: ['monitor'],
          secondText: ' the solution for errors and fix them ',
          secondWords: ['immediately']
        },
        {
          id: 8, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 9, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 10,
          firstText: 'We have a ',
          firstWords: ['transparent'],
          secondText: ' and systematic ',
          secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.competitor_analysis_and_research_dev,
        SERVICES.ux_design_turn_key,
        SERVICES.ui_design_turn_key,
        SERVICES.app_dev_turn_key,
        SERVICES.testing_and_automation_turn_key,
        SERVICES.deployment_turn_key,
        SERVICES.support
      ],
      examples: [
        {
          id: 5,
          image: IMAGES.projects.StrongHer[0].sm,
          title: 'StrongHer',
          text: 'Turn-key project development',
          slug: 'StrongHer'
        },
        {
          id: 1,
          image: IMAGES.projects.LazyAz[4].sm,
          title: 'LazyAz',
          text: 'Turn-key project development',
          slug: 'LazyAz'
        },
        {
          id: 2,
          image: IMAGES.projects.SuperShuttle[0].sm,
          title: 'Super Shuttle',
          text: 'Turn-key project development',
          slug: 'Super-Shuttle'
        },
        {
          id: 3,
          image: IMAGES.projects.WhipAround[4].sm,
          title: 'Whip Around',
          text: 'Turn-key project development',
          slug: 'Whip-Around'
        },
        {
          id: 4,
          image: IMAGES.projects.RaisingChildren[0].sm,
          title: 'Raising Children',
          text: 'Turn-key project development',
          slug: 'Raising-Children'
        }
      ]
    }
  ],
  design: [
    {
      id: 'design_ui',
      title: 'UX/UI Design',
      subTitle: 'UX/UI Design',
      headline: `A solid foundation for${NBRSP}your business.`,
      shortDescription: 'Ensuring the most effective user experience and user interface design as a crucial part of the user journey based on your ' +
        'business requirements. We optimize UX and UI through product reviews, analyses, and usability testing.',
      description: 'User experience and user interface design are equally essential parts of the product because these ' +
        'are how users interact with it. The most efficient applications are those that contain rich visual elements ' +
        'without compromising quickness and efficiency. 7 glyphs understands the importance of design and software ' +
        'based on user experience, and we are here to help you create a satisfactory interaction between you and your users.',
      label: '',
      tags: 'Analysis | Research | UX | UI | Usability testing',
      text: 'Ensuring the most effective user experience and user interface design as a crucial part of the user journey based on your ' +
      'business requirements.',
      time: {
        inWeeks: '22 – 80 weeks',
        inHours: '200 – 700 hours'
      },
      linkName: 'UX/UI Design',
      linkTo: 'ux-ui-design',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.ui,
          md: IMAGES.packages.bgLarge.ui,
          lg: IMAGES.packages.bgLarge.ui
        },
        cover: IMAGES.packages.covers.design.ui,
        serviceCover: IMAGES.packages.service.design.ui
      },
      reasons: [
        {
          id: 1, firstText: 'We ', firstWords: ['review'], secondText: ' your product'
        },
        {
          id: 2, firstText: 'We ', firstWords: ['analyze'], secondText: ' your competitors'
        },
        {
          id: 3, firstText: 'We ', firstWords: ['examine'], secondText: ' the market'
        },
        {
          id: 4, firstText: 'We ', firstWords: ['optimize'], secondText: ' user journey'
        },
        { id: 5, firstText: 'We perform ', firstWords: ['usability', 'testing'] },
        {
          id: 6,
          firstText: 'We follow the ',
          firstWords: ['latest', 'trends'],
          secondText: ' in UX and UI design development'
        },
        {
          id: 7, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 8, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 9, firstText: 'Our clients are ', firstWords: ['highly', 'involved'], secondText: ' in the process'
        },
        {
          id: 10,
          firstText: 'We have a ',
          firstWords: ['transparent'],
          secondText: ' and systematic ',
          secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.product_analysis_ui,
        SERVICES.competitor_analysis_and_research_ui,
        SERVICES.ux_design_ui,
        SERVICES.ui_design_ui,
        SERVICES.usability_testing
      ],
      examples: [
        {
          id: 16,
          image: IMAGES.projects.StrongHer[0].sm,
          title: 'StrongHer',
          text: 'Turn-key project development',
          slug: 'StrongHer'
        },
        {
          id: 8,
          image: IMAGES.projects.RogueTravel[0].sm,
          title: 'Rogue Travel',
          text: 'Web application development',
          slug: 'Rogue-Travel'
        },
        {
          id: 9,
          image: IMAGES.projects.WolfAndFox[0].sm,
          title: 'Wolf and Fox',
          text: 'Web application development',
          slug: 'Wolf-and-Fox'
        },
        {
          id: 10,
          image: IMAGES.projects.QuarryTrucks[0].sm,
          title: 'Quarry Trucks',
          text: 'Web application development',
          slug: 'Quarry-Trucks'
        },
        {
          id: 11,
          image: IMAGES.projects.SharedSpace[0].sm,
          title: 'Shared Space',
          text: 'UX/UI Design',
          slug: 'Shared-Space'
        },
        {
          id: 12,
          image: IMAGES.projects.StarShipIT[0].sm,
          title: 'StarShipIT',
          text: 'UX/UI Design',
          slug: 'StarShipIT'
        },
        {
          id: 1,
          image: IMAGES.projects.LazyAz[0].sm,
          title: 'LazyAz',
          text: 'Turn-key project development',
          slug: 'LazyAz'
        },
        {
          id: 2,
          image: IMAGES.projects.OpenSeas[0].sm,
          title: 'OpenSeas',
          text: 'Web application development',
          slug: 'Open-Seas'
        },
        {
          id: 13,
          image: IMAGES.projects.WhipAround[0].sm,
          title: 'Whip Around',
          text: 'Turn-key project development',
          slug: 'Whip-Around'
        },
        {
          id: 3,
          image: IMAGES.projects.SuperShuttle[0].sm,
          title: 'Super Shuttle',
          text: 'Turn-key project development',
          slug: 'Super-Shuttle'
        },
        {
          id: 4,
          image: IMAGES.projects.RaisingChildren[0].sm,
          title: 'Raising Children',
          text: 'Turn-key project development',
          slug: 'Raising-Children'
        },
        {
          id: 14,
          image: IMAGES.projects.Hoist[0].sm,
          title: 'Hoist',
          text: 'UX/UI Design',
          slug: 'Hoist'
        },
        {
          id: 15,
          image: IMAGES.projects.Bubbly[0].sm,
          title: 'Bubbly',
          text: 'iOS and Web development',
          slug: 'Bubbly'
        },
        {
          id: 5,
          image: IMAGES.projects.MVNZ[0].sm,
          title: 'MustvisitNZ',
          text: 'Web application development',
          slug: 'Must-Visit-NZ'
        },
        {
          id: 6,
          image: IMAGES.projects.FeedTime[0].sm,
          title: 'Feed Time',
          text: 'iOS and Web development',
          slug: 'Feed-Time'
        },
        {
          id: 7,
          image: IMAGES.projects.EasyWords[0].sm,
          title: 'Easy Words Learning',
          text: 'iOS and Web development',
          slug: 'Easy-Words'
        }
      ]
    },
    {
      id: 'design_logo',
      title: 'Logo Design',
      subTitle: 'Logo Design',
      headline: `Creating${NBRSP}a memorable logo${NBRSP}design`,
      shortDescription: 'We create unique logo designs which represent your brand idea and philosophy. Our designs are ' +
        'iconic and distinct from your competitors.',
      description: 'The most successful businesses don\'t have to parade their names for them to be recognized by customers. ' +
        'A memorable logo with a timeless, unique design is enough. \n' +
        'Our team of professional designers at 7 glyphs can craft the perfect logo for you that is easily recognizable and remembered.',
      label: '',
      tags: 'Analysis | Research | Brandmark | In life',
      text: 'We create unique logo designs which represent your brand idea and philosophy. Our designs are ' +
      'iconic and distinct from your competitors.',
      time: {
        inWeeks: '3 – 5 weeks',
        inHours: '60 – 120 hours'
      },
      linkName: 'Logo brand design',
      linkTo: 'logo-brand-design',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.logo,
          md: IMAGES.packages.bgLarge.logo,
          lg: IMAGES.packages.bgLarge.logo
        },
        cover: IMAGES.packages.covers.design.logo,
        serviceCover: IMAGES.packages.service.design.logo
      },
      reasons: [
        {
          id: 1, firstText: 'We ', firstWords: ['review'], secondText: ' your product'
        },
        {
          id: 2, firstText: 'We ', firstWords: ['analyze'], secondText: ' your competitors'
        },
        {
          id: 3, firstText: 'We ', firstWords: ['examine'], secondText: ' the market'
        },
        {
          id: 4, firstText: 'We ', firstWords: ['optimize'], secondText: ' user journey'
        },
        { id: 5, firstText: 'We perform ', firstWords: ['usability', 'testing'] },
        {
          id: 6,
          firstText: 'We follow the ',
          firstWords: ['latest', 'trends'],
          secondText: ' in UX and UI design development'
        },
        {
          id: 7, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 8, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 9, firstText: 'Our clients are ', firstWords: ['highly', 'involved'], secondText: ' in the process'
        },
        {
          id: 10,
          firstText: 'We have a ',
          firstWords: ['transparent'],
          secondText: ' and systematic ',
          secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.competitor_analysis_and_research_logo,
        SERVICES.brandmark,
        SERVICES.logo_in_life
      ]
    },
    {
      id: 'design_brand',
      title: 'Brandbook',
      subTitle: 'Brandbook',
      headline: `Timeless brandbook for${NBRSP}your dynamic business`,
      shortDescription: 'We design and develop a brandbook that can be used for any design that is required for your business. We make sure that it looks and feels consistent across different channels throughout the years.',
      description: 'A brandbook is like your company\'s manual. It unifies your identity by connecting different brand ' +
        'elements like the logo, colors, and typography. It also keeps your brand message cohesive and consistent throughout time. ' +
        '7 glyphs creates powerful brandbooks that boosts consistent perception of your brand.',
      label: 'popular',
      tags: 'Analysis | Research | Brandmark | In life',
      text: 'We design and develop a brandbook that can be used for any design that is required for your business. We make sure that it looks and feels consistent.',
      time: {
        inWeeks: '4 – 7 weeks',
        inHours: '80 – 170 hours'
      },
      linkName: 'Brandbook & brand identity',
      linkTo: 'brandbook-and-brand-identity',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.brandbook,
          md: IMAGES.packages.bgLarge.brandbook,
          lg: IMAGES.packages.bgLarge.brandbook
        },
        cover: IMAGES.packages.covers.design.brandbook,
        serviceCover: IMAGES.packages.service.design.brandbook
      },
      reasons: [
        {
          id: 1, firstText: 'We ', firstWords: ['review'], secondText: ' your product'
        },
        {
          id: 2, firstText: 'We ', firstWords: ['analyze'], secondText: ' your competitors'
        },
        {
          id: 3, firstText: 'We ', firstWords: ['examine'], secondText: ' the market'
        },
        {
          id: 4, firstText: 'We ', firstWords: ['optimize'], secondText: ' user journey'
        },
        { id: 5, firstText: 'We perform ', firstWords: ['usability', 'testing'] },
        {
          id: 6,
          firstText: 'We follow the ',
          firstWords: ['latest', 'trends'],
          secondText: ' in UX and UI design development'
        },
        {
          id: 7, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 8, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 9, firstText: 'Our clients are ', firstWords: ['highly', 'involved'], secondText: ' in the process'
        },
        {
          id: 10,
          firstText: 'We have a ',
          firstWords: ['transparent'],
          secondText: ' and systematic ',
          secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.competitor_analysis_and_research_brand,
        SERVICES.brandmark,
        SERVICES.identity,
        SERVICES.brand_in_life
      ]
    }
  ],
  care: [
    {
      id: 'care_10',
      title: '10 hours support',
      subTitle: '‘7 glyphs Care’ Support 10 hours',
      headline: `Supporting your product${NBRSP}as you${NBRSP}go.`,
      shortDescription: 'Our services don\'t end after launching your product. We also provide continuous support after launch.',
      description: 'We have a variety of support packages which suit products of any size. During support, we update the ' +
        'code base according to the latest standards and best practices. We also update dependencies and install security ' +
        'updates on all platforms. Our monitoring systems allow us to react and fix critical bugs as soon as possible.',
      label: '',
      tags: 'Server maintenance | Development | Design',
      text: 'Our services don\'t end after launching your product. We also provide continuous support after launch.',
      time: {
        inWeeks: '1 month',
        inHours: '10 hours'
      },
      linkName: '10 hours support',
      linkTo: 'ten-hours-support',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.ten_hours,
          md: IMAGES.packages.bgLarge.ten_hours,
          lg: IMAGES.packages.bgLarge.ten_hours
        },
        cover: IMAGES.packages.covers.care.ten_hours,
        serviceCover: IMAGES.packages.service.care.ten_hours
      },
      reasons: [
        {
          id: 1, firstText: 'We follow the ', firstWords: ['latest', 'trends'], secondText: ' in Design and Development'
        },
        {
          id: 2, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 3, firstText: 'Our services allow clients to ', firstWords: ['explore']
        },
        {
          id: 4, firstText: 'We use the ', firstWords: ['best', 'technology'], secondText: ' in our solutions'
        },
        {
          id: 5,
          firstText: 'Reactive ',
          firstWords: ['support'],
          secondText: ' is provided for every component and the product as a whole.'
        },
        {
          id: 6,
          firstText: 'We understand how to ',
          firstWords: ['scale'],
          secondText: ' projects based on their ',
          secondWords: ['growth']
        },
        {
          id: 7,
          firstText: 'We ',
          firstWords: ['monitor'],
          secondText: ' the solution for errors and fix them ',
          secondWords: ['immediately']
        },
        {
          id: 8, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 9, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 10, firstText: 'We have a ', firstWords: ['transparent'], secondText: ' and systematic ', secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.dev_support,
        SERVICES.design_support,
        SERVICES.extra_features,
        SERVICES.how_many_hours_10,
        SERVICES.discount_5
      ]
    },
    {
      id: 'care_20',
      title: '20 hours Support',
      subTitle: '‘7 glyphs Care’ Support 20 hours',
      headline: `Supporting your product${NBRSP}as you${NBRSP}go.`,
      shortDescription: 'Our services don\'t end after launching your product. We also provide continuous support after launch.',
      description: 'We have a variety of support packages which suit products of any size. During support, we update the ' +
        'code base according to the latest standards and best practices. We also update dependencies and install security ' +
        'updates on all platforms. Our monitoring systems allow us to react and fix critical bugs as soon as possible.',
      label: '',
      tags: 'Server maintenance | Development | Design',
      text: 'Our services don\'t end after launching your product. We also provide continuous support after launch.',
      time: {
        inWeeks: '1 month',
        inHours: '20 hours'
      },
      linkName: '20 hours support',
      linkTo: 'twenty-hours-support',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.twenty_hours,
          md: IMAGES.packages.bgLarge.twenty_hours,
          lg: IMAGES.packages.bgLarge.twenty_hours
        },
        cover: IMAGES.packages.covers.care.twenty_hours,
        serviceCover: IMAGES.packages.service.care.twenty_hours
      },
      reasons: [
        {
          id: 1, firstText: 'We follow the ', firstWords: ['latest', 'trends'], secondText: ' in Design and Development'
        },
        {
          id: 2, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 3, firstText: 'Our services allow clients to ', firstWords: ['explore']
        },
        {
          id: 4, firstText: 'We use the ', firstWords: ['best', 'technology'], secondText: ' in our solutions'
        },
        {
          id: 5,
          firstText: 'Reactive ',
          firstWords: ['support'],
          secondText: ' is provided for every component and the product as a whole.'
        },
        {
          id: 6,
          firstText: 'We understand how to ',
          firstWords: ['scale'],
          secondText: ' projects based on their ',
          secondWords: ['growth']
        },
        {
          id: 7,
          firstText: 'We ',
          firstWords: ['monitor'],
          secondText: ' the solution for errors and fix them ',
          secondWords: ['immediately']
        },
        {
          id: 8, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 9, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 10, firstText: 'We have a ', firstWords: ['transparent'], secondText: ' and systematic ', secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.dev_support,
        SERVICES.design_support,
        SERVICES.extra_features,
        SERVICES.how_many_hours_20,
        SERVICES.discount_10
      ]
    },
    {
      id: 'care_40',
      title: '40+ hours Support',
      subTitle: '‘7 glyphs Care’ Support 40+ hours',
      headline: `Supporting your product${NBRSP}as you${NBRSP}go.`,
      shortDescription: 'Our services don\'t end after launching your product. We also provide continuous support after launch.',
      description: 'We have a variety of support packages which suit products of any size. During support, we update the ' +
        'code base according to the latest standards and best practices. We also update dependencies and install security ' +
        'updates on all platforms. Our monitoring systems allow us to react and fix critical bugs as soon as possible.',
      label: '',
      tags: 'Server maintenance | Development | Design',
      text: 'Our services don\'t end after launching your product. We also provide continuous support after launch.',
      time: {
        inWeeks: '1 month',
        inHours: '40+ hours'
      },
      linkName: '40+ hours support',
      linkTo: 'forty-hours-support',
      images: {
        bg: {
          sm: IMAGES.packages.bgLarge.forty_hours,
          md: IMAGES.packages.bgLarge.forty_hours,
          lg: IMAGES.packages.bgLarge.forty_hours
        },
        cover: IMAGES.packages.covers.care.forty_hours,
        serviceCover: IMAGES.packages.service.care.forty_hours
      },
      reasons: [
        {
          id: 1, firstText: 'We follow the ', firstWords: ['latest', 'trends'], secondText: ' in Design and Development'
        },
        {
          id: 2, firstText: 'We create ', firstWords: ['intuitive', 'interfaces']
        },
        {
          id: 3, firstText: 'Our services allow clients to ', firstWords: ['explore']
        },
        {
          id: 4, firstText: 'We use the ', firstWords: ['best', 'technology'], secondText: ' in our solutions'
        },
        {
          id: 5,
          firstText: 'Reactive ',
          firstWords: ['support'],
          secondText: ' is provided for every component and the product as a whole.'
        },
        {
          id: 6,
          firstText: 'We understand how to ',
          firstWords: ['scale'],
          secondText: ' projects based on their ',
          secondWords: ['growth']
        },
        {
          id: 7,
          firstText: 'We ',
          firstWords: ['monitor'],
          secondText: ' the solution for errors and fix them ',
          secondWords: ['immediately']
        },
        {
          id: 8, firstText: 'We employ the ', firstWords: ['best', 'practices'], secondText: ' in the industry'
        },
        {
          id: 9, firstText: 'Our team is composed of experts with ', firstWords: ['years', 'of', 'experience']
        },
        {
          id: 10, firstText: 'We have a ', firstWords: ['transparent'], secondText: ' and systematic ', secondWords: ['workflow']
        }
      ],
      services: [
        SERVICES.dev_support,
        SERVICES.design_support,
        SERVICES.extra_features,
        SERVICES.how_many_hours_40,
        SERVICES.discount_15
      ]
    }
  ]
}
