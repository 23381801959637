import { confirmEmailSendSuccess } from '../content/notifications'

import { TABLET_VIEW } from '../constants/dimentions'

export const UPDATE_SCREEN_DIMENTIONS = 'UPDATE_SCREEN_DIMENTIONS'
export const SET_SIDEBAR_THEME = 'SET_SIDEBAR_THEME'
export const SET_BODYLESS_SIDEBAR = 'SET_BODYLESS_SIDEBAR'
export const SET_LOGOLESS_SIDEBAR = 'SET_LOGOLESS_SIDEBAR'

export const GET_BLOG_POSTS_REQUEST = 'GET_BLOG_POSTS_REQUEST'
export const GET_BLOG_POSTS_SUCCESS = 'GET_BLOG_POSTS_SUCCESS'
export const GET_BLOG_POSTS_FAIL = 'GET_BLOG_POSTS_FAIL'

export const GET_POSTS_BY_SLUG_REQUEST = 'GET_POSTS_BY_SLUG_REQUEST'
export const GET_POSTS_BY_SLUG_SUCCESS = 'GET_POSTS_BY_SLUG_SUCCESS'
export const GET_POSTS_BY_SLUG_FAIL = 'GET_POSTS_BY_SLUG_FAIL'

export const GET_POSTS_BY_TAG_REQUEST = 'GET_POSTS_BY_TAG_REQUEST'
export const GET_POSTS_BY_TAG_SUCCESS = 'GET_POSTS_BY_TAG_SUCCESS'
export const GET_POSTS_BY_TAG_FAIL = 'GET_POSTS_BY_TAG_FAIL'

export const GET_POSTS_BY_AUTHOR_REQUEST = 'GET_POSTS_BY_AUTHOR_REQUEST'
export const GET_POSTS_BY_AUTHOR_SUCCESS = 'GET_POSTS_BY_AUTHOR_SUCCESS'
export const GET_POSTS_BY_AUTHOR_FAIL = 'GET_POSTS_BY_AUTHOR_FAIL'

export const GET_POSTS_BY_SEARCH_REQUEST = 'GET_POSTS_BY_SEARCH_REQUEST'
export const GET_POSTS_BY_SEARCH_SUCCESS = 'GET_POSTS_BY_SEARCH_SUCCESS'
export const GET_POSTS_BY_SEARCH_FAIL = 'GET_POSTS_BY_SEARCH_FAIL'

export const POST_BRIEF_FORM_REQUEST = 'POST_BRIEF_FORM_REQUEST'
export const POST_BRIEF_FORM_SUCCESS = 'POST_BRIEF_FORM_SUCCESS'
export const POST_BRIEF_FORM_FAIL = 'POST_BRIEF_FORM_FAIL'

export const POST_JOIN_FORM_REQUEST = 'POST_JOIN_FORM_REQUEST'
export const POST_JOIN_FORM_SUCCESS = 'POST_JOIN_FORM_SUCCESS'
export const POST_JOIN_FORM_FAIL = 'POST_JOIN_FORM_FAIL'

export const TOGGLE_FORM_NOTIFICATION = 'TOGGLE_FORM_NOTIFICATION'

export const updateScreenDimentions = payload => ({
  type: UPDATE_SCREEN_DIMENTIONS,
  payload
})

export const setSidebarTheme = payload => ({
  type: SET_SIDEBAR_THEME,
  payload
})

export const toggleBodylessSidebar = payload => ({
  type: SET_BODYLESS_SIDEBAR,
  payload
})

export const toggleLogolessSidebar = payload => ({
  type: SET_LOGOLESS_SIDEBAR,
  payload
})

export const getBlogPosts = page => ({
  type: GET_BLOG_POSTS_REQUEST,
  payload: { page }
})

export const getBlogPostsSuccess = result => ({
  type: GET_BLOG_POSTS_SUCCESS,
  payload: result
})

export const getBlogPostsFailed = error => ({
  type: GET_BLOG_POSTS_FAIL,
  payload: error
})

export const getBlogPostBySlug = slug => ({
  type: GET_POSTS_BY_SLUG_REQUEST,
  payload: slug
})

export const getBlogPostBySlugSuccess = result => ({
  type: GET_POSTS_BY_SLUG_SUCCESS,
  payload: result
})

export const getBlogPostBySlugFailed = error => ({
  type: GET_POSTS_BY_SLUG_FAIL,
  payload: error
})

export const getBlogPostsByTag = tag => ({
  type: GET_POSTS_BY_TAG_REQUEST,
  payload: { tag }
})

export const getBlogPostsByTagSuccess = result => ({
  type: GET_POSTS_BY_TAG_SUCCESS,
  payload: result
})

export const getBlogPostsByTagFailed = error => ({
  type: GET_POSTS_BY_TAG_FAIL,
  payload: error
})

export const getBlogPostsByAuthor = authorName => ({
  type: GET_POSTS_BY_AUTHOR_REQUEST,
  payload: { authorName }
})

export const getBlogPostsByAuthorSuccess = result => ({
  type: GET_POSTS_BY_AUTHOR_SUCCESS,
  payload: result
})

export const getBlogPostsByAuthorFailed = error => ({
  type: GET_POSTS_BY_AUTHOR_FAIL,
  payload: error
})

export const getBlogPostsBySearch = searchTerm => ({
  type: GET_POSTS_BY_SEARCH_REQUEST,
  payload: { searchTerm }
})

export const getBlogPostsBySearchSuccess = result => ({
  type: GET_POSTS_BY_SEARCH_SUCCESS,
  payload: result
})

export const getBlogPostsBySearchFailed = error => ({
  type: GET_POSTS_BY_SEARCH_FAIL,
  payload: error
})

export const postBriefForm = payload => ({
  type: POST_BRIEF_FORM_REQUEST,
  payload
})

export const postBriefFormSuccess = result => ({
  type: POST_BRIEF_FORM_SUCCESS,
  payload: result
})

export const postBriefFormFailed = error => ({
  type: POST_BRIEF_FORM_FAIL,
  payload: error
})

export const postJoinForm = payload => ({
  type: POST_JOIN_FORM_REQUEST,
  payload
})

export const postJoinFormSuccess = result => ({
  type: POST_JOIN_FORM_SUCCESS,
  payload: result
})

export const postJoinFormFailed = error => ({
  type: POST_JOIN_FORM_FAIL,
  payload: error
})

export const toggleFormNotification = () => ({
  type: TOGGLE_FORM_NOTIFICATION
})

const initialState = {
  screenSize: {
    width: 0,
    height: 0
  },
  isDarkTheme: false,
  isNavBody: true,
  isLogoBody: true,
  blogPosts: {
    page: 1,
    posts: [],
    fetching: false,
    success: false,
    error: null,
    limitReach: false
  },
  postBySlug: {
    page: 1,
    post: {},
    fetching: false,
    success: false,
    error: null,
    limitReach: false
  },
  postsByTag: {
    page: 1,
    posts: [],
    fetching: false,
    success: false,
    error: null,
    key: '',
    limitReach: false
  },
  postsByAuthor: {
    page: 1,
    posts: [],
    fetching: false,
    success: false,
    error: null,
    key: '',
    limitReach: false
  },
  postsBySearch: {
    page: 1,
    posts: [],
    fetching: false,
    success: false,
    error: null,
    searchTerm: '',
    limitReach: false
  },
  form: {
    success: false,
    fetching: false,
    showNotification: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCREEN_DIMENTIONS:
      return {
        ...state,
        screenSize: { ...action.payload }
      }
    case SET_SIDEBAR_THEME: {
      return {
        ...state,
        isDarkTheme: action.payload
      }
    }
    case SET_BODYLESS_SIDEBAR: {
      return {
        ...state,
        isNavBody: action.payload
      }
    }
    case SET_LOGOLESS_SIDEBAR: {
      return {
        ...state,
        isLogoBody: action.payload
      }
    }
    case GET_POSTS_BY_SLUG_REQUEST: {
      return {
        ...state,
        postBySlug: {
          ...state.postBySlug,
          post: {},
          fetching: true,
          success: false
        }
      }
    }
    case GET_POSTS_BY_SLUG_SUCCESS:
      return {
        ...state,
        postBySlug: {
          ...state.postBySlug,
          fetching: false,
          post: action.payload,
          error: null,
          success: true
        }
      }
    case GET_POSTS_BY_SLUG_FAIL:
      return {
        ...state,
        postBySlug: {
          ...state.postBySlug,
          fetching: false,
          success: false,
          error: action.payload
        }
      }
    case GET_POSTS_BY_TAG_REQUEST: {
      return {
        ...state,
        postsByTag: {
          ...state.postsByTag,
          page: state.postsByTag.key.length && action.payload.tag === state.postsByTag.key ? state.postsByTag.page : 1,
          posts: action.payload.tag === state.postsByTag.key ? state.postsByTag.posts : [],
          fetching: true,
          success: false,
          limitReach: false,
          key: action.payload.tag
        }
      }
    }
    case GET_POSTS_BY_TAG_SUCCESS:
      return {
        ...state,
        postsByTag: {
          ...state.postsByTag,
          fetching: false,
          page: action.payload.length ? state.postsByTag.page + 1 : state.postsByTag.page,
          posts: state.postsByTag.posts.concat(action.payload),
          error: null,
          success: true,
          limitReach: !action.payload.length || action.payload.length < getRequestLimit(state)
        }
      }
    case GET_POSTS_BY_TAG_FAIL:
      return {
        ...state,
        postsByTag: {
          ...state.postsByTag,
          fetching: false,
          success: false,
          error: action.payload
        }
      }
    case GET_POSTS_BY_AUTHOR_REQUEST: {
      return {
        ...state,
        postsByAuthor: {
          ...state.postsByAuthor,
          page: state.postsByAuthor.key.length && action.payload.authorName === state.postsByAuthor.key ? state.postsByAuthor.page : 1,
          posts: action.payload.authorName === state.postsByAuthor.key ? state.postsByAuthor.posts : [],
          fetching: true,
          success: false,
          key: action.payload.authorName,
          limitReach: false
        }
      }
    }
    case GET_POSTS_BY_AUTHOR_SUCCESS:
      return {
        ...state,
        postsByAuthor: {
          ...state.postsByAuthor,
          fetching: false,
          page: action.payload.length ? state.postsByAuthor.page + 1 : state.postsByAuthor.page,
          posts: state.postsByAuthor.posts.concat(action.payload),
          error: null,
          success: true,
          limitReach: !action.payload.length || action.payload.length < getRequestLimit(state)
        }
      }
    case GET_POSTS_BY_AUTHOR_FAIL:
      return {
        ...state,
        postsByAuthor: {
          ...state.postsByAuthor,
          fetching: false,
          success: false,
          error: action.payload
        }
      }
    case GET_POSTS_BY_SEARCH_REQUEST: {
      return {
        ...state,
        postsBySearch: {
          ...state.postsBySearch,
          page: state.postsBySearch.searchTerm.length && action.payload.searchTerm === state.postsBySearch.searchTerm ? state.postsBySearch.page : 1,
          posts: action.payload.searchTerm === state.postsBySearch.searchTerm ? state.postsBySearch.posts : [],
          fetching: true,
          success: false,
          searchTerm: action.payload.searchTerm
        }
      }
    }
    case GET_POSTS_BY_SEARCH_SUCCESS:
      return {
        ...state,
        postsBySearch: {
          ...state.postsBySearch,
          fetching: false,
          page: action.payload.length ? state.postsBySearch.page + 1 : state.postsBySearch.page,
          posts: state.postsBySearch.posts.concat(action.payload),
          error: null,
          success: true,
          limitReach: !action.payload.length || action.payload.length < getRequestLimit(state)
        }
      }
    case GET_POSTS_BY_SEARCH_FAIL:
      return {
        ...state,
        postsBySearch: {
          ...state.postsBySearch,
          fetching: false,
          success: false,
          error: action.payload
        }
      }
    case GET_BLOG_POSTS_REQUEST:
      return {
        ...state,
        blogPosts: {
          ...state.blogPosts,
          fetching: true,
          success: false
        }
      }
    case GET_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        blogPosts: {
          ...state.blogPosts,
          page: action.payload.length ? state.blogPosts.page + 1 : state.blogPosts.page,
          fetching: false,
          posts: state.blogPosts.posts.concat(action.payload),
          error: null,
          success: true,
          limitReach: !action.payload.length || action.payload.length < getRequestLimit(state)
        }
      }
    case GET_BLOG_POSTS_FAIL:
      return {
        ...state,
        blogPosts: {
          ...state.blogPosts,
          fetching: false,
          success: false,
          error: action.payload
        }
      }
    case POST_BRIEF_FORM_REQUEST:
      return {
        ...state,
        form: {
          fetching: true,
          success: false,
          showNotification: false,
          message: ''
        }
      }
    case POST_BRIEF_FORM_SUCCESS:
      return {
        ...state,
        form: {
          fetching: false,
          success: true,
          showNotification: true,
          message: confirmEmailSendSuccess
        }
      }
    case POST_BRIEF_FORM_FAIL:
      return {
        ...state,
        form: {
          fetching: false,
          success: false,
          notification: false
        }
      }
    case POST_JOIN_FORM_REQUEST:
      return {
        ...state,
        form: {
          fetching: true,
          success: false,
          notification: false
        }
      }
    case POST_JOIN_FORM_SUCCESS:
      return {
        ...state,
        form: {
          fetching: false,
          success: true,
          showNotification: true,
          message: confirmEmailSendSuccess
        }
      }
    case POST_JOIN_FORM_FAIL:
      return {
        ...state,
        form: {
          fetching: false,
          success: false,
          notification: false
        }
      }
    case TOGGLE_FORM_NOTIFICATION:
      return {
        ...state,
        form: {
          ...state.form,
          showNotification: !state.form.showNotification,
          success: false,
          message: ''
        }
      }

    default:
      return state
  }
}

// SELECTORS

export const getAuthorState = state => state.postsByAuthor
export const getTagsState = state => state.postsByTag
export const getSearchState = state => state.postsBySearch
export const getRequestLimit = (state) => {
  if (state.screenSize.width > TABLET_VIEW) return 6
  return 3
}

export default reducer
