/* eslint-disable max-len */
import React from 'react'

function FileIcon () {
  return (
    <svg width='12' height='14' viewBox='0 0 12 14'>
      <path fill='#4a4a4a' fillRule='nonzero' d='M8.5 0c-.898 0-1.793.35-2.477 1.044l.008-.008-5.5 5.355.688.733L6.727 1.77h.007a2.446 2.446 0 0 1 3.532 0 2.57 2.57 0 0 1 0 3.602l-6.7 6.415-.003.008a1.477 1.477 0 0 1-2.126 0 1.551 1.551 0 0 1 0-2.164v-.004l6.704-6.41.004-.005a.484.484 0 0 1 .71 0c.2.2.2.514 0 .718l-5.82 5.514.68.749 5.84-5.534.008-.004a1.567 1.567 0 0 0 0-2.168 1.494 1.494 0 0 0-2.126 0L.739 8.901l-.004.008a2.588 2.588 0 0 0 0 3.602 2.467 2.467 0 0 0 3.532 0l6.703-6.41.004-.005c1.363-1.39 1.363-3.662 0-5.052A3.452 3.452 0 0 0 8.5 0z' />
    </svg>
  )
}

export default FileIcon
