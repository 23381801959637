import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { FB_APP_ID, FB_PAGE_ID } from '../../../constants/links'

function Messenger () {
  return (
    <MessengerCustomerChat
      pageId={FB_PAGE_ID}
      appId={FB_APP_ID}
      themeColor='#000000'
      greetingDialogDisplay='hide'
    />
  )
}

export default Messenger
