import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const TagSm = ({ children, className }) => <p className={cn('TagSm', className)}>{children}</p>

TagSm.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default TagSm
