/* eslint-disable max-len */
import React from 'react'

const BrandIcon = props => (
  <svg width={26} height={26} viewBox='0 0 26 26' {...props}>
    <path
      fill='#FFF'
      d='M14.875 0c-.805 0-1.57.32-2.125.89L.875 12.86a3.018 3.018 0 0 0 0 4.234l8.031 8.031a3.018 3.018 0 0 0 4.235 0L25.117 13.25A3.01 3.01 0 0 0 26 11.125V3c0-1.648-1.352-3-3-3h-8.125zm0 2H23c.563 0 1 .438 1 1v8.125c0 .266-.11.523-.297.71L11.734 23.712a.983.983 0 0 1-1.406 0l-8.031-8.04a.97.97 0 0 1-.008-1.405l11.875-11.97c.188-.187.445-.296.711-.296zM21 4c-.555 0-1 .445-1 1 0 .555.445 1 1 1 .555 0 1-.445 1-1 0-.555-.445-1-1-1z'
    />
  </svg>
)

export default BrandIcon
