export default {
  icon_benefit_apple: require('../assets/images/team/icons/icon_benefit_apple.svg'),
  icon_benefit_avocado: require('../assets/images/team/icons/icon_benefit_avocado.svg'),
  icon_benefit_brain: require('../assets/images/team/icons/icon_benefit_brain.svg'),
  icon_benefit_coffee: require('../assets/images/team/icons/icon_benefit_coffee.svg'),
  icon_benefit_mission: require('../assets/images/team/icons/icon_benefit_mission.svg'),
  icon_benefit_shirt: require('../assets/images/team/icons/icon_benefit_shirt.svg'),
  icon_benefit_souvenir: require('../assets/images/team/icons/icon_benefit_souvenir.svg'),
  icon_benefit_target: require('../assets/images/team/icons/icon_benefit_target.svg'),
  icon_benefit_watch: require('../assets/images/team/icons/icon_benefit_watch.svg'),
  brief_banner: require('../assets/images/brief/brief_banner2x.jpg'),
  join_us_banner: require('../assets/images/brief/join_us_banner2x.jpg'),

  icon_training_blue: require('../assets/images/icons/icon_training_blue.svg'),
  icon_training: require('../assets/images/icons/icon-training.svg'),
  icon_show: require('../assets/images/icons/icon-tv-show.svg'),
  icon_show_blue: require('../assets/images/icons/icon_tv-show_blue.svg'),
  icon_bullet_active: require('../assets/images/icons/icon_bullet_active.svg'),
  icon_read_more: require('../assets/images/icons/icon_read-more.svg'),
  icon_arrow_down: require('../assets/images/icons/icon_read-more.svg'),
  icon_panel_closed: require('../assets/images/icons/icon_panel_closed.svg'),
  icon_panel_opened: require('../assets/images/icons/icon_panel_opened.svg'),
  icon_glyph_creative: require('../assets/images/icons/glyphs/glyph-creative.svg'),
  icon_glyph_learn: require('../assets/images/icons/glyphs/glyph-learn.svg'),
  icon_glyph_team: require('../assets/images/icons/glyphs/glyph-team.svg'),
  icon_glyph_understand: require('../assets/images/icons/glyphs/glyph-understand.svg'),
  icon_glyph_experience: require('../assets/images/icons/glyphs/glyph-experience.svg'),
  icon_glyph_harmony: require('../assets/images/icons/glyphs/glyph-harmony.svg'),
  icon_glyph_energy: require('../assets/images/icons/glyphs/glyph-energy.svg'),
  icon_footer_facebook: require('../assets/images/icons/icon_footer_facebook.svg'),
  icon_footer_twitter: require('../assets/images/icons/icon_footer_twitter.svg'),
  icon_footer_instagram: require('../assets/images/icons/icon_footer_instagramn.svg'),
  icon_footer_linkedin: require('../assets/images/icons/icon_footer_linkedin.svg'),
  icon_marker_white: require('../assets/images/icons/icon_marker_white.svg'),
  icon_marker_black: require('../assets/images/icons/icon_marker_black.svg'),
  icon_partner_email: require('../assets/images/icons/icon_partner_email.svg'),
  icon_partner_facebook: require('../assets/images/icons/icon_partner_facebook.svg'),
  icon_partner_instagram: require('../assets/images/icons/icon_partner_instagram.svg'),
  icon_partner_location: require('../assets/images/icons/icon_partner_location.svg'),
  icon_partner_phone: require('../assets/images/icons/icon_partner_phone.svg'),
  icon_project_appstore: require('../assets/images/icons/icon_apple-store.svg'),
  icon_project_google_play: require('../assets/images/icons/icon_google-play.svg'),
  icon_project_weblink: require('../assets/images/icons/icon_weblink.svg'),
  icon_delete: require('../assets/images/icons/icon_delete.svg'),
  icon_brief_us: require('../assets/images/icons/icon_brief_us.svg'),
  icon_level_handle: require('../assets/images/icons/level_handle.svg'),
  icon_star: require('../assets/images/icons/icon_star_green.svg'),

  home_ship_lines_sm: require('../assets/images/home/lines.png'),
  home_ship_lines_md: require('../assets/images/home/lines@2x.png'),
  home_ship_lines_lg: require('../assets/images/home/lines@3x.png'),

  home_ship_sm: require('../assets/images/home/ship-small@2x.png'),
  home_ship_md: require('../assets/images/home/ship-small@2x.png'),
  home_ship_lg: require('../assets/images/home/ship-small@3x.png'),

  home_company_bg_sm: require('../assets/images/home/home-company-bg_md.jpg'),
  home_company_bg_md: require('../assets/images/home/home-company-bg_md.jpg'),
  home_company_bg_lg: require('../assets/images/home/home-company-bg_lg.jpg'),

  logo_amazon: require('../assets/images/technologies/logo_amazon.jpg'),
  logo_ios: require('../assets/images/technologies/logo_ios.jpg'),
  logo_cloud: require('../assets/images/technologies/logo_cloud.jpg'),
  logo_css: require('../assets/images/technologies/logo_css.jpg'),
  logo_java: require('../assets/images/technologies/logo_java.jpg'),
  logo_jquery: require('../assets/images/technologies/logo_jquery.jpg'),
  logo_js: require('../assets/images/technologies/logo_js.jpg'),
  logo_firebase: require('../assets/images/technologies/logo_firebase.jpg'),
  logo_html: require('../assets/images/technologies/logo_html.jpg'),
  logo_kotlin: require('../assets/images/technologies/logo_kotlin.jpg'),
  logo_laravel: require('../assets/images/technologies/logo_laravel.jpg'),
  logo_mobx: require('../assets/images/technologies/logo_mobx.jpg'),
  logo_mongodb: require('../assets/images/technologies/logo_mongodb.jpg'),
  logo_mysql: require('../assets/images/technologies/logo_mysql.jpg'),
  logo_php: require('../assets/images/technologies/logo_php.jpg'),
  logo_postgre: require('../assets/images/technologies/logo_postgre.jpg'),
  logo_react: require('../assets/images/technologies/logo_react.jpg'),
  logo_realm: require('../assets/images/technologies/logo_realm.jpg'),
  logo_redux: require('../assets/images/technologies/logo_redux.jpg'),
  logo_socket_io: require('../assets/images/technologies/logo_socket_io.jpg'),
  logo_sqlite: require('../assets/images/technologies/logo_sqlite.jpg'),
  logo_swift: require('../assets/images/technologies/logo_swift.jpg'),
  logo_wp: require('../assets/images/technologies/logo_wp.jpg'),
  logo_android: require('../assets/images/technologies/logo_android.jpg'),
  logo_redis: require('../assets/images/technologies/logo_redis.jpg'),
  logo_w3: require('../assets/images/technologies/logo_w3.jpg'),

  projects: {
    MadFit: [
      {
        preview: require('../assets/images/projects/MadFit/Madfit-1_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-1.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-1.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-1.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-2_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-2.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-2.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-2.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-3_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-3.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-3.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-3.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-4_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-4.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-4.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-4.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-5_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-5.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-5.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-5.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-6_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-6.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-6.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-6.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-7_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-7.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-7.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-7.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-8_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-8.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-8.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-8.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-9_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-9.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-9.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-9.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-10_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-10.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-10.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-10.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-11_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-11.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-11.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-11.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-12_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-12.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-12.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-12.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-13_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-13.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-13.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-13.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-14_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-14.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-14.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-14.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-15_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-15.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-15.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-15.jpg')
      },
      {
        preview: require('../assets/images/projects/MadFit/Madfit-16_preview.jpg'),
        sm: require('../assets/images/projects/MadFit/Madfit-16.jpg'),
        md: require('../assets/images/projects/MadFit/Madfit-16.jpg'),
        lg: require('../assets/images/projects/MadFit/Madfit-16.jpg')
      }
    ],
    PresentMe: [
      {
        preview: require('../assets/images/projects/PresentMe/0.VPM_Frontpage_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/0.VPM_Frontpage.jpg'),
        md: require('../assets/images/projects/PresentMe/0.VPM_Frontpage.jpg'),
        lg: require('../assets/images/projects/PresentMe/0.VPM_Frontpage.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_desktop_1_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_desktop_1.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_desktop_1.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_desktop_1.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_desktop_2_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_desktop_2.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_desktop_2.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_desktop_2.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_desktop_3_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_desktop_3.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_desktop_3.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_desktop_3.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_desktop_4_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_desktop_4.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_desktop_4.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_desktop_4.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_desktop_5_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_desktop_5.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_desktop_5.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_desktop_5.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_laptop_1_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_laptop_1.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_laptop_1.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_laptop_1.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_laptop_2_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_laptop_2.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_laptop_2.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_laptop_2.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_laptop_3_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_laptop_3.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_laptop_3.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_laptop_3.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_laptop_4_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_laptop_4.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_laptop_4.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_laptop_4.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_mobile_1_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_mobile_1.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_mobile_1.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_mobile_1.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_mobile_2_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_mobile_2.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_mobile_2.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_mobile_2.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_mobile_3_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_mobile_3.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_mobile_3.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_mobile_3.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_tablet_1_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_tablet_1.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_tablet_1.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_tablet_1.jpg')
      },
      {
        preview: require('../assets/images/projects/PresentMe/VPM_tablet_2_preview.jpg'),
        sm: require('../assets/images/projects/PresentMe/VPM_tablet_2.jpg'),
        md: require('../assets/images/projects/PresentMe/VPM_tablet_2.jpg'),
        lg: require('../assets/images/projects/PresentMe/VPM_tablet_2.jpg')
      }
    ],
    Ally: [
      {
        preview: require('../assets/images/projects/Ally/Ally_app_1_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_app_1.jpg'),
        md: require('../assets/images/projects/Ally/Ally_app_1.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_app_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_app_2_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_app_2.jpg'),
        md: require('../assets/images/projects/Ally/Ally_app_2.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_app_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_app_3_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_app_3.jpg'),
        md: require('../assets/images/projects/Ally/Ally_app_3.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_app_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_app_4_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_app_4.jpg'),
        md: require('../assets/images/projects/Ally/Ally_app_4.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_app_4.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_app_5_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_app_5.jpg'),
        md: require('../assets/images/projects/Ally/Ally_app_5.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_app_5.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_app_6_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_app_6.jpg'),
        md: require('../assets/images/projects/Ally/Ally_app_6.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_app_6.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_web_1_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_web_1.jpg'),
        md: require('../assets/images/projects/Ally/Ally_web_1.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_web_2_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_web_2.jpg'),
        md: require('../assets/images/projects/Ally/Ally_web_2.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_web_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_web_3_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_web_3.jpg'),
        md: require('../assets/images/projects/Ally/Ally_web_3.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_web_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_web_4_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_web_4.jpg'),
        md: require('../assets/images/projects/Ally/Ally_web_4.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_web_4.jpg')
      },
      {
        preview: require('../assets/images/projects/Ally/Ally_web_5_preview.jpg'),
        sm: require('../assets/images/projects/Ally/Ally_web_5.jpg'),
        md: require('../assets/images/projects/Ally/Ally_web_5.jpg'),
        lg: require('../assets/images/projects/Ally/Ally_web_5.jpg')
      }
    ],
    Milk: [
      {
        preview: require('../assets/images/projects/Milk/Milk_lite1_preview.jpg'),
        sm: require('../assets/images/projects/Milk/Milk_lite1.jpg'),
        md: require('../assets/images/projects/Milk/Milk_lite1.jpg'),
        lg: require('../assets/images/projects/Milk/Milk_lite1.jpg')
      },
      {
        preview: require('../assets/images/projects/Milk/Milk_lite2_preview.jpg'),
        sm: require('../assets/images/projects/Milk/Milk_lite2.jpg'),
        md: require('../assets/images/projects/Milk/Milk_lite2.jpg'),
        lg: require('../assets/images/projects/Milk/Milk_lite2.jpg')
      },
      {
        preview: require('../assets/images/projects/Milk/Milk_lite3_preview.jpg'),
        sm: require('../assets/images/projects/Milk/Milk_lite3.jpg'),
        md: require('../assets/images/projects/Milk/Milk_lite3.jpg'),
        lg: require('../assets/images/projects/Milk/Milk_lite3.jpg')
      },
      {
        preview: require('../assets/images/projects/Milk/Milk_lite4_preview.jpg'),
        sm: require('../assets/images/projects/Milk/Milk_lite4.jpg'),
        md: require('../assets/images/projects/Milk/Milk_lite4.jpg'),
        lg: require('../assets/images/projects/Milk/Milk_lite4.jpg')
      },
      {
        preview: require('../assets/images/projects/Milk/Milk_lite5_preview.jpg'),
        sm: require('../assets/images/projects/Milk/Milk_lite5.jpg'),
        md: require('../assets/images/projects/Milk/Milk_lite5.jpg'),
        lg: require('../assets/images/projects/Milk/Milk_lite5.jpg')
      },
      {
        preview: require('../assets/images/projects/Milk/Milk_lite6_preview.jpg'),
        sm: require('../assets/images/projects/Milk/Milk_lite6.jpg'),
        md: require('../assets/images/projects/Milk/Milk_lite6.jpg'),
        lg: require('../assets/images/projects/Milk/Milk_lite6.jpg')
      },
      {
        preview: require('../assets/images/projects/Milk/Milk_lite7_preview.jpg'),
        sm: require('../assets/images/projects/Milk/Milk_lite7.jpg'),
        md: require('../assets/images/projects/Milk/Milk_lite7.jpg'),
        lg: require('../assets/images/projects/Milk/Milk_lite7.jpg')
      }
    ],
    RoadNinja: [
      {
        preview: require('../assets/images/projects/RoadNinja/app-1_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/app-1.jpg'),
        md: require('../assets/images/projects/RoadNinja/app-1.jpg'),
        lg: require('../assets/images/projects/RoadNinja/app-1.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/app-2_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/app-2.jpg'),
        md: require('../assets/images/projects/RoadNinja/app-2.jpg'),
        lg: require('../assets/images/projects/RoadNinja/app-2.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/app-3_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/app-3.jpg'),
        md: require('../assets/images/projects/RoadNinja/app-3.jpg'),
        lg: require('../assets/images/projects/RoadNinja/app-3.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/app-4_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/app-4.jpg'),
        md: require('../assets/images/projects/RoadNinja/app-4.jpg'),
        lg: require('../assets/images/projects/RoadNinja/app-4.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/app-5_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/app-5.jpg'),
        md: require('../assets/images/projects/RoadNinja/app-5.jpg'),
        lg: require('../assets/images/projects/RoadNinja/app-5.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/web-1_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/web-1.jpg'),
        md: require('../assets/images/projects/RoadNinja/web-1.jpg'),
        lg: require('../assets/images/projects/RoadNinja/web-1.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/web-2_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/web-2.jpg'),
        md: require('../assets/images/projects/RoadNinja/web-2.jpg'),
        lg: require('../assets/images/projects/RoadNinja/web-2.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/web-3_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/web-3.jpg'),
        md: require('../assets/images/projects/RoadNinja/web-3.jpg'),
        lg: require('../assets/images/projects/RoadNinja/web-3.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/web-4_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/web-4.jpg'),
        md: require('../assets/images/projects/RoadNinja/web-4.jpg'),
        lg: require('../assets/images/projects/RoadNinja/web-4.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/web-5_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/web-5.jpg'),
        md: require('../assets/images/projects/RoadNinja/web-5.jpg'),
        lg: require('../assets/images/projects/RoadNinja/web-5.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/web-6_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/web-6.jpg'),
        md: require('../assets/images/projects/RoadNinja/web-6.jpg'),
        lg: require('../assets/images/projects/RoadNinja/web-6.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/landing-1_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/landing-1.jpg'),
        md: require('../assets/images/projects/RoadNinja/landing-1.jpg'),
        lg: require('../assets/images/projects/RoadNinja/landing-1.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/landing-2_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/landing-2.jpg'),
        md: require('../assets/images/projects/RoadNinja/landing-2.jpg'),
        lg: require('../assets/images/projects/RoadNinja/landing-2.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/landing-3_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/landing-3.jpg'),
        md: require('../assets/images/projects/RoadNinja/landing-3.jpg'),
        lg: require('../assets/images/projects/RoadNinja/landing-3.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/landing-4_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/landing-4.jpg'),
        md: require('../assets/images/projects/RoadNinja/landing-4.jpg'),
        lg: require('../assets/images/projects/RoadNinja/landing-4.jpg')
      },
      {
        preview: require('../assets/images/projects/RoadNinja/landing-5_preview.jpg'),
        sm: require('../assets/images/projects/RoadNinja/landing-5.jpg'),
        md: require('../assets/images/projects/RoadNinja/landing-5.jpg'),
        lg: require('../assets/images/projects/RoadNinja/landing-5.jpg')
      }
    ],
    JustHatched: [
      {
        preview: require('../assets/images/projects/JustHatched/1_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/1.jpg'),
        md: require('../assets/images/projects/JustHatched/1.jpg'),
        lg: require('../assets/images/projects/JustHatched/1.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/2-0_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/2-0.jpg'),
        md: require('../assets/images/projects/JustHatched/2-0.jpg'),
        lg: require('../assets/images/projects/JustHatched/2-0.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/2-1_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/2-1.jpg'),
        md: require('../assets/images/projects/JustHatched/2-1.jpg'),
        lg: require('../assets/images/projects/JustHatched/2-1.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/2-2_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/2-2.jpg'),
        md: require('../assets/images/projects/JustHatched/2-2.jpg'),
        lg: require('../assets/images/projects/JustHatched/2-2.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/2-3_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/2-3.jpg'),
        md: require('../assets/images/projects/JustHatched/2-3.jpg'),
        lg: require('../assets/images/projects/JustHatched/2-3.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/3-0_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/3-0.jpg'),
        md: require('../assets/images/projects/JustHatched/3-0.jpg'),
        lg: require('../assets/images/projects/JustHatched/3-0.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/3-1_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/3-1.jpg'),
        md: require('../assets/images/projects/JustHatched/3-1.jpg'),
        lg: require('../assets/images/projects/JustHatched/3-1.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/3-2_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/3-2.jpg'),
        md: require('../assets/images/projects/JustHatched/3-2.jpg'),
        lg: require('../assets/images/projects/JustHatched/3-2.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/3-3_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/3-3.jpg'),
        md: require('../assets/images/projects/JustHatched/3-3.jpg'),
        lg: require('../assets/images/projects/JustHatched/3-3.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/3-4_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/3-4.jpg'),
        md: require('../assets/images/projects/JustHatched/3-4.jpg'),
        lg: require('../assets/images/projects/JustHatched/3-4.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/3-5_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/3-5.jpg'),
        md: require('../assets/images/projects/JustHatched/3-5.jpg'),
        lg: require('../assets/images/projects/JustHatched/3-5.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/4-0_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/4-0.jpg'),
        md: require('../assets/images/projects/JustHatched/4-0.jpg'),
        lg: require('../assets/images/projects/JustHatched/4-0.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/4-1_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/4-1.jpg'),
        md: require('../assets/images/projects/JustHatched/4-1.jpg'),
        lg: require('../assets/images/projects/JustHatched/4-1.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/4-2_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/4-2.jpg'),
        md: require('../assets/images/projects/JustHatched/4-2.jpg'),
        lg: require('../assets/images/projects/JustHatched/4-2.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/5-0_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/5-0.jpg'),
        md: require('../assets/images/projects/JustHatched/5-0.jpg'),
        lg: require('../assets/images/projects/JustHatched/5-0.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/5-1_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/5-1.jpg'),
        md: require('../assets/images/projects/JustHatched/5-1.jpg'),
        lg: require('../assets/images/projects/JustHatched/5-1.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/5-2_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/5-2.jpg'),
        md: require('../assets/images/projects/JustHatched/5-2.jpg'),
        lg: require('../assets/images/projects/JustHatched/5-2.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_1_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_1.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_1.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_2_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_2.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_2.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_2.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_3_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_3.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_3.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_3.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_4_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_4.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_4.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_4.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_5_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_5.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_5.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_5.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_6_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_6.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_6.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_6.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_7_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_7.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_7.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_7.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_8_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_8.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_8.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_8.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_9_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_9.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_9.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_9.jpg')
      },
      {
        preview: require('../assets/images/projects/JustHatched/JH_web_10_preview.jpg'),
        sm: require('../assets/images/projects/JustHatched/JH_web_10.jpg'),
        md: require('../assets/images/projects/JustHatched/JH_web_10.jpg'),
        lg: require('../assets/images/projects/JustHatched/JH_web_10.jpg')
      }
    ],
    Joyable: [
      {
        preview: require('../assets/images/projects/Joyable/joyable1_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable1.jpg'),
        md: require('../assets/images/projects/Joyable/joyable1.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable1.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable2_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable2.jpg'),
        md: require('../assets/images/projects/Joyable/joyable2.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable2.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable3_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable3.jpg'),
        md: require('../assets/images/projects/Joyable/joyable3.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable3.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable4_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable4.jpg'),
        md: require('../assets/images/projects/Joyable/joyable4.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable4.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable5_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable5.jpg'),
        md: require('../assets/images/projects/Joyable/joyable5.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable5.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable6_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable6.jpg'),
        md: require('../assets/images/projects/Joyable/joyable6.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable6.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable7_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable7.jpg'),
        md: require('../assets/images/projects/Joyable/joyable7.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable7.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable8_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable8.jpg'),
        md: require('../assets/images/projects/Joyable/joyable8.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable8.jpg')
      },
      {
        preview: require('../assets/images/projects/Joyable/joyable9_preview.jpg'),
        sm: require('../assets/images/projects/Joyable/joyable9.jpg'),
        md: require('../assets/images/projects/Joyable/joyable9.jpg'),
        lg: require('../assets/images/projects/Joyable/joyable9.jpg')
      }
    ],
    Foodprint: [
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app1_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app1.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app1.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app1.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app2_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app2.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app2.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app2.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app3_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app3.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app3.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app3.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app4_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app4.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app4.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app4.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app5_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app5.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app5.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app5.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app6_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app6.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app6.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app6.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app7_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app7.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app7.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app7.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app8_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app8.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app8.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app8.jpg')
      },
      {
        preview: require('../assets/images/projects/Foodprint/foodprint_app9_preview.jpg'),
        sm: require('../assets/images/projects/Foodprint/foodprint_app9.jpg'),
        md: require('../assets/images/projects/Foodprint/foodprint_app9.jpg'),
        lg: require('../assets/images/projects/Foodprint/foodprint_app9.jpg')
      }
    ],
    StrongHer: [
      {
        preview: require('../assets/images/projects/StrongHer/strongher1_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher1.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher1.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher1.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher2_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher2.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher2.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher2.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher3_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher3.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher3.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher3.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher4_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher4.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher4.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher4.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher5_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher5.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher5.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher5.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher6_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher6.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher6.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher6.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher7_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher7.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher7.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher7.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher8_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher8.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher8.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher8.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher9_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher9.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher9.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher9.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher10_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher10.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher10.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher10.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher11_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher11.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher11.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher11.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher12_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher12.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher12.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher12.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher13_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher13.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher13.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher13.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher14_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher14.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher14.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher14.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher15_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher15.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher15.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher15.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher16_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher16.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher16.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher16.jpg')
      },
      {
        preview: require('../assets/images/projects/StrongHer/strongher17_preview.jpg'),
        sm: require('../assets/images/projects/StrongHer/strongher17.jpg'),
        md: require('../assets/images/projects/StrongHer/strongher17.jpg'),
        lg: require('../assets/images/projects/StrongHer/strongher17.jpg')
      }
    ],
    MelissaChalmers: [
      {
        preview: require('../assets/images/projects/MelissaChalmers/Melissa1_preview.jpg'),
        sm: require('../assets/images/projects/MelissaChalmers/Melissa1.jpg'),
        md: require('../assets/images/projects/MelissaChalmers/Melissa1.jpg'),
        lg: require('../assets/images/projects/MelissaChalmers/Melissa1.jpg')
      },
      {
        preview: require('../assets/images/projects/MelissaChalmers/Melissa2_preview.jpg'),
        sm: require('../assets/images/projects/MelissaChalmers/Melissa2.jpg'),
        md: require('../assets/images/projects/MelissaChalmers/Melissa2.jpg'),
        lg: require('../assets/images/projects/MelissaChalmers/Melissa2.jpg')
      },
      {
        preview: require('../assets/images/projects/MelissaChalmers/Melissa3_preview.jpg'),
        sm: require('../assets/images/projects/MelissaChalmers/Melissa3.jpg'),
        md: require('../assets/images/projects/MelissaChalmers/Melissa3.jpg'),
        lg: require('../assets/images/projects/MelissaChalmers/Melissa3.jpg')
      },
      {
        preview: require('../assets/images/projects/MelissaChalmers/Melissa4_preview.jpg'),
        sm: require('../assets/images/projects/MelissaChalmers/Melissa4.jpg'),
        md: require('../assets/images/projects/MelissaChalmers/Melissa4.jpg'),
        lg: require('../assets/images/projects/MelissaChalmers/Melissa4.jpg')
      }
    ],
    RogueTravel: [
      {
        preview: require('../assets/images/projects/RogueTravel/rogue1_preview.jpg'),
        sm: require('../assets/images/projects/RogueTravel/rogue1.jpg'),
        md: require('../assets/images/projects/RogueTravel/rogue1.jpg'),
        lg: require('../assets/images/projects/RogueTravel/rogue1.jpg')
      },
      {
        preview: require('../assets/images/projects/RogueTravel/rogue2_preview.jpg'),
        sm: require('../assets/images/projects/RogueTravel/rogue2.jpg'),
        md: require('../assets/images/projects/RogueTravel/rogue2.jpg'),
        lg: require('../assets/images/projects/RogueTravel/rogue2.jpg')
      },
      {
        preview: require('../assets/images/projects/RogueTravel/rogue3_preview.jpg'),
        sm: require('../assets/images/projects/RogueTravel/rogue3.jpg'),
        md: require('../assets/images/projects/RogueTravel/rogue3.jpg'),
        lg: require('../assets/images/projects/RogueTravel/rogue3.jpg')
      },
      {
        preview: require('../assets/images/projects/RogueTravel/rogue4_preview.jpg'),
        sm: require('../assets/images/projects/RogueTravel/rogue4.jpg'),
        md: require('../assets/images/projects/RogueTravel/rogue4.jpg'),
        lg: require('../assets/images/projects/RogueTravel/rogue4.jpg')
      },
      {
        preview: require('../assets/images/projects/RogueTravel/rogue5_preview.jpg'),
        sm: require('../assets/images/projects/RogueTravel/rogue5.jpg'),
        md: require('../assets/images/projects/RogueTravel/rogue5.jpg'),
        lg: require('../assets/images/projects/RogueTravel/rogue5.jpg')
      },
      {
        preview: require('../assets/images/projects/RogueTravel/rogue6_preview.jpg'),
        sm: require('../assets/images/projects/RogueTravel/rogue6.jpg'),
        md: require('../assets/images/projects/RogueTravel/rogue6.jpg'),
        lg: require('../assets/images/projects/RogueTravel/rogue6.jpg')
      }
    ],
    StarShipIT: [
      {
        preview: require('../assets/images/projects/StarShipIT/starshipit1_preview.jpg'),
        sm: require('../assets/images/projects/StarShipIT/starshipit1.jpg'),
        md: require('../assets/images/projects/StarShipIT/starshipit1.jpg'),
        lg: require('../assets/images/projects/StarShipIT/starshipit1.jpg')
      },
      {
        preview: require('../assets/images/projects/StarShipIT/starshipit2_preview.jpg'),
        sm: require('../assets/images/projects/StarShipIT/starshipit2.jpg'),
        md: require('../assets/images/projects/StarShipIT/starshipit2.jpg'),
        lg: require('../assets/images/projects/StarShipIT/starshipit2.jpg')
      },
      {
        preview: require('../assets/images/projects/StarShipIT/starshipit3_preview.jpg'),
        sm: require('../assets/images/projects/StarShipIT/starshipit3.jpg'),
        md: require('../assets/images/projects/StarShipIT/starshipit3.jpg'),
        lg: require('../assets/images/projects/StarShipIT/starshipit3.jpg')
      },
      {
        preview: require('../assets/images/projects/StarShipIT/starshipit4_preview.jpg'),
        sm: require('../assets/images/projects/StarShipIT/starshipit4.jpg'),
        md: require('../assets/images/projects/StarShipIT/starshipit4.jpg'),
        lg: require('../assets/images/projects/StarShipIT/starshipit4.jpg')
      },
      {
        preview: require('../assets/images/projects/StarShipIT/starshipit5_preview.jpg'),
        sm: require('../assets/images/projects/StarShipIT/starshipit5.jpg'),
        md: require('../assets/images/projects/StarShipIT/starshipit5.jpg'),
        lg: require('../assets/images/projects/StarShipIT/starshipit5.jpg')
      }
    ],
    WolfAndFox: [
      {
        preview: require('../assets/images/projects/WolfAndFox/wolffox1_preview.jpg'),
        sm: require('../assets/images/projects/WolfAndFox/wolffox1.jpg'),
        md: require('../assets/images/projects/WolfAndFox/wolffox1.jpg'),
        lg: require('../assets/images/projects/WolfAndFox/wolffox1.jpg')
      },
      {
        preview: require('../assets/images/projects/WolfAndFox/wolffox2_preview.jpg'),
        sm: require('../assets/images/projects/WolfAndFox/wolffox2.jpg'),
        md: require('../assets/images/projects/WolfAndFox/wolffox2.jpg'),
        lg: require('../assets/images/projects/WolfAndFox/wolffox2.jpg')
      },
      {
        preview: require('../assets/images/projects/WolfAndFox/wolffox3_preview.jpg'),
        sm: require('../assets/images/projects/WolfAndFox/wolffox3.jpg'),
        md: require('../assets/images/projects/WolfAndFox/wolffox3.jpg'),
        lg: require('../assets/images/projects/WolfAndFox/wolffox3.jpg')
      },
      {
        preview: require('../assets/images/projects/WolfAndFox/wolffox4_preview.jpg'),
        sm: require('../assets/images/projects/WolfAndFox/wolffox4.jpg'),
        md: require('../assets/images/projects/WolfAndFox/wolffox4.jpg'),
        lg: require('../assets/images/projects/WolfAndFox/wolffox4.jpg')
      },
      {
        preview: require('../assets/images/projects/WolfAndFox/wolffox5_preview.jpg'),
        sm: require('../assets/images/projects/WolfAndFox/wolffox5.jpg'),
        md: require('../assets/images/projects/WolfAndFox/wolffox5.jpg'),
        lg: require('../assets/images/projects/WolfAndFox/wolffox5.jpg')
      },
      {
        preview: require('../assets/images/projects/WolfAndFox/wolffox6_preview.jpg'),
        sm: require('../assets/images/projects/WolfAndFox/wolffox6.jpg'),
        md: require('../assets/images/projects/WolfAndFox/wolffox6.jpg'),
        lg: require('../assets/images/projects/WolfAndFox/wolffox6.jpg')
      },
      {
        preview: require('../assets/images/projects/WolfAndFox/wolffox7_preview.jpg'),
        sm: require('../assets/images/projects/WolfAndFox/wolffox7.jpg'),
        md: require('../assets/images/projects/WolfAndFox/wolffox7.jpg'),
        lg: require('../assets/images/projects/WolfAndFox/wolffox7.jpg')
      }
    ],
    GoodnestBookingProcess: [
      {
        preview: require('../assets/images/projects/GoodnestBookingProcess/goodnest1_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestBookingProcess/goodnest1.jpg'),
        md: require('../assets/images/projects/GoodnestBookingProcess/goodnest1.jpg'),
        lg: require('../assets/images/projects/GoodnestBookingProcess/goodnest1.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestBookingProcess/goodnest2_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestBookingProcess/goodnest2.jpg'),
        md: require('../assets/images/projects/GoodnestBookingProcess/goodnest2.jpg'),
        lg: require('../assets/images/projects/GoodnestBookingProcess/goodnest2.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestBookingProcess/goodnest3_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestBookingProcess/goodnest3.jpg'),
        md: require('../assets/images/projects/GoodnestBookingProcess/goodnest3.jpg'),
        lg: require('../assets/images/projects/GoodnestBookingProcess/goodnest3.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestBookingProcess/goodnest4_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestBookingProcess/goodnest4.jpg'),
        md: require('../assets/images/projects/GoodnestBookingProcess/goodnest4.jpg'),
        lg: require('../assets/images/projects/GoodnestBookingProcess/goodnest4.jpg')
      }
    ],
    QuarryTrucks: [
      {
        preview: require('../assets/images/projects/QuarryTrucks/quarrytruck1_preview.jpg'),
        sm: require('../assets/images/projects/QuarryTrucks/quarrytruck1.jpg'),
        md: require('../assets/images/projects/QuarryTrucks/quarrytruck1.jpg'),
        lg: require('../assets/images/projects/QuarryTrucks/quarrytruck1.jpg')
      },
      {
        preview: require('../assets/images/projects/QuarryTrucks/quarrytruck2_preview.jpg'),
        sm: require('../assets/images/projects/QuarryTrucks/quarrytruck2.jpg'),
        md: require('../assets/images/projects/QuarryTrucks/quarrytruck2.jpg'),
        lg: require('../assets/images/projects/QuarryTrucks/quarrytruck2.jpg')
      },
      {
        preview: require('../assets/images/projects/QuarryTrucks/quarrytruck3_preview.jpg'),
        sm: require('../assets/images/projects/QuarryTrucks/quarrytruck3.jpg'),
        md: require('../assets/images/projects/QuarryTrucks/quarrytruck3.jpg'),
        lg: require('../assets/images/projects/QuarryTrucks/quarrytruck3.jpg')
      },
      {
        preview: require('../assets/images/projects/QuarryTrucks/quarrytruck4_preview.jpg'),
        sm: require('../assets/images/projects/QuarryTrucks/quarrytruck4.jpg'),
        md: require('../assets/images/projects/QuarryTrucks/quarrytruck4.jpg'),
        lg: require('../assets/images/projects/QuarryTrucks/quarrytruck4.jpg')
      }
    ],
    GoodnestJobPage: [
      {
        preview: require('../assets/images/projects/GoodnestJobPage/goodnest_job1_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestJobPage/goodnest_job1.jpg'),
        md: require('../assets/images/projects/GoodnestJobPage/goodnest_job1.jpg'),
        lg: require('../assets/images/projects/GoodnestJobPage/goodnest_job1.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestJobPage/goodnest_job2_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestJobPage/goodnest_job2.jpg'),
        md: require('../assets/images/projects/GoodnestJobPage/goodnest_job2.jpg'),
        lg: require('../assets/images/projects/GoodnestJobPage/goodnest_job2.jpg')
      }
    ],
    LazyAz: [
      {
        preview: require('../assets/images/projects/LazyAz/lazyaz_app1_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/lazyaz_app1.jpg'),
        md: require('../assets/images/projects/LazyAz/lazyaz_app1.jpg'),
        lg: require('../assets/images/projects/LazyAz/lazyaz_app1.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/lazyaz_app2_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/lazyaz_app2.jpg'),
        md: require('../assets/images/projects/LazyAz/lazyaz_app2.jpg'),
        lg: require('../assets/images/projects/LazyAz/lazyaz_app2.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_android_1_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_android_1.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_android_1.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_android_1.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_android_2_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_android_2.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_android_2.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_android_2.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/lazyaz_app3_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/lazyaz_app3.jpg'),
        md: require('../assets/images/projects/LazyAz/lazyaz_app3.jpg'),
        lg: require('../assets/images/projects/LazyAz/lazyaz_app3.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/lazyaz_app4_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/lazyaz_app4.jpg'),
        md: require('../assets/images/projects/LazyAz/lazyaz_app4.jpg'),
        lg: require('../assets/images/projects/LazyAz/lazyaz_app4.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/lazyaz_app5_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/lazyaz_app5.jpg'),
        md: require('../assets/images/projects/LazyAz/lazyaz_app5.jpg'),
        lg: require('../assets/images/projects/LazyAz/lazyaz_app5.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/lazyaz_app6_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/lazyaz_app6.jpg'),
        md: require('../assets/images/projects/LazyAz/lazyaz_app6.jpg'),
        lg: require('../assets/images/projects/LazyAz/lazyaz_app6.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_web1_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_web1.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_web1.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_web1.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_web2_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_web2.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_web2.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_web2.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_web3_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_web3.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_web3.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_web3.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_web4_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_web4.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_web4.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_web4.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_web5_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_web5.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_web5.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_web5.jpg')
      },
      {
        preview: require('../assets/images/projects/LazyAz/LazyAz_web6_preview.jpg'),
        sm: require('../assets/images/projects/LazyAz/LazyAz_web6.jpg'),
        md: require('../assets/images/projects/LazyAz/LazyAz_web6.jpg'),
        lg: require('../assets/images/projects/LazyAz/LazyAz_web6.jpg')
      }
    ],
    OpenSeas: [
      {
        preview: require('../assets/images/projects/OpenSeas/OpenSeas_web_1_preview.jpg'),
        sm: require('../assets/images/projects/OpenSeas/OpenSeas_web_1.jpg'),
        md: require('../assets/images/projects/OpenSeas/OpenSeas_web_1.jpg'),
        lg: require('../assets/images/projects/OpenSeas/OpenSeas_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/OpenSeas/OpenSeas_web_2_preview.jpg'),
        sm: require('../assets/images/projects/OpenSeas/OpenSeas_web_2.jpg'),
        md: require('../assets/images/projects/OpenSeas/OpenSeas_web_2.jpg'),
        lg: require('../assets/images/projects/OpenSeas/OpenSeas_web_2.jpg')
      },
      {
        preview: require('../assets/images/projects/OpenSeas/OpenSeas_web_3_preview.jpg'),
        sm: require('../assets/images/projects/OpenSeas/OpenSeas_web_3.jpg'),
        md: require('../assets/images/projects/OpenSeas/OpenSeas_web_3.jpg'),
        lg: require('../assets/images/projects/OpenSeas/OpenSeas_web_3.jpg')
      },
      {
        preview: require('../assets/images/projects/OpenSeas/OpenSeas_web_4_preview.jpg'),
        sm: require('../assets/images/projects/OpenSeas/OpenSeas_web_4.jpg'),
        md: require('../assets/images/projects/OpenSeas/OpenSeas_web_4.jpg'),
        lg: require('../assets/images/projects/OpenSeas/OpenSeas_web_4.jpg')
      },
      {
        preview: require('../assets/images/projects/OpenSeas/OpenSeas_web_5_preview.jpg'),
        sm: require('../assets/images/projects/OpenSeas/OpenSeas_web_5.jpg'),
        md: require('../assets/images/projects/OpenSeas/OpenSeas_web_5.jpg'),
        lg: require('../assets/images/projects/OpenSeas/OpenSeas_web_5.jpg')
      },
      {
        preview: require('../assets/images/projects/OpenSeas/OpenSeas_web_6_preview.jpg'),
        sm: require('../assets/images/projects/OpenSeas/OpenSeas_web_6.jpg'),
        md: require('../assets/images/projects/OpenSeas/OpenSeas_web_6.jpg'),
        lg: require('../assets/images/projects/OpenSeas/OpenSeas_web_6.jpg')
      }
    ],
    GoodnestProfessional: [
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app1_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app1.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app1.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app1.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app2_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app2.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app2.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app2.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app3_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app3.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app3.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app3.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app4_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app4.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app4.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app4.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app5_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app5.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app5.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app5.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app6_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app6.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app6.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app6.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app7_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app7.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app7.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app7.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app8_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app8.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app8.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app8.jpg')
      },
      {
        preview: require('../assets/images/projects/GoodnestProfessional/goodnest_app9_preview.jpg'),
        sm: require('../assets/images/projects/GoodnestProfessional/goodnest_app9.jpg'),
        md: require('../assets/images/projects/GoodnestProfessional/goodnest_app9.jpg'),
        lg: require('../assets/images/projects/GoodnestProfessional/goodnest_app9.jpg')
      }
    ],
    SharedSpace: [
      {
        preview: require('../assets/images/projects/SharedSpace/ss1_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss1.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss1.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss1.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss2_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss2.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss2.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss2.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss3_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss3.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss3.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss3.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss4_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss4.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss4.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss4.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss5_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss5.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss5.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss5.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss6_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss6.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss6.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss6.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss7_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss7.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss7.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss7.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss8_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss8.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss8.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss8.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss9_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss9.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss9.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss9.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss10_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss10.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss10.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss10.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss11_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss11.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss11.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss11.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss12_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss12.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss12.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss12.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss13_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss13.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss13.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss13.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss14_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss14.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss14.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss14.jpg')
      },
      {
        preview: require('../assets/images/projects/SharedSpace/ss15_preview.jpg'),
        sm: require('../assets/images/projects/SharedSpace/ss15.jpg'),
        md: require('../assets/images/projects/SharedSpace/ss15.jpg'),
        lg: require('../assets/images/projects/SharedSpace/ss15.jpg')
      }
    ],
    Saffron12: [
      {
        preview: require('../assets/images/projects/12Saffron/saffron1_preview.jpg'),
        sm: require('../assets/images/projects/12Saffron/saffron1.jpg'),
        md: require('../assets/images/projects/12Saffron/saffron1.jpg'),
        lg: require('../assets/images/projects/12Saffron/saffron1.jpg')
      },
      {
        preview: require('../assets/images/projects/12Saffron/saffron2_preview.jpg'),
        sm: require('../assets/images/projects/12Saffron/saffron2.jpg'),
        md: require('../assets/images/projects/12Saffron/saffron2.jpg'),
        lg: require('../assets/images/projects/12Saffron/saffron2.jpg')
      },
      {
        preview: require('../assets/images/projects/12Saffron/saffron3_preview.jpg'),
        sm: require('../assets/images/projects/12Saffron/saffron3.jpg'),
        md: require('../assets/images/projects/12Saffron/saffron3.jpg'),
        lg: require('../assets/images/projects/12Saffron/saffron3.jpg')
      }
    ],
    Grabaseat: [
      {
        preview: require('../assets/images/projects/Grabaseat/grabaseat1_preview.jpg'),
        sm: require('../assets/images/projects/Grabaseat/grabaseat1.jpg'),
        md: require('../assets/images/projects/Grabaseat/grabaseat1.jpg'),
        lg: require('../assets/images/projects/Grabaseat/grabaseat1.jpg')
      },
      {
        preview: require('../assets/images/projects/Grabaseat/grabaseat2_preview.jpg'),
        sm: require('../assets/images/projects/Grabaseat/grabaseat2.jpg'),
        md: require('../assets/images/projects/Grabaseat/grabaseat2.jpg'),
        lg: require('../assets/images/projects/Grabaseat/grabaseat2.jpg')
      },
      {
        preview: require('../assets/images/projects/Grabaseat/grabaseat3_preview.jpg'),
        sm: require('../assets/images/projects/Grabaseat/grabaseat3.jpg'),
        md: require('../assets/images/projects/Grabaseat/grabaseat3.jpg'),
        lg: require('../assets/images/projects/Grabaseat/grabaseat3.jpg')
      },
      {
        preview: require('../assets/images/projects/Grabaseat/grabaseat4_preview.jpg'),
        sm: require('../assets/images/projects/Grabaseat/grabaseat4.jpg'),
        md: require('../assets/images/projects/Grabaseat/grabaseat4.jpg'),
        lg: require('../assets/images/projects/Grabaseat/grabaseat4.jpg')
      },
      {
        preview: require('../assets/images/projects/Grabaseat/grabaseat5_preview.jpg'),
        sm: require('../assets/images/projects/Grabaseat/grabaseat5.jpg'),
        md: require('../assets/images/projects/Grabaseat/grabaseat5.jpg'),
        lg: require('../assets/images/projects/Grabaseat/grabaseat5.jpg')
      },
      {
        preview: require('../assets/images/projects/Grabaseat/grabaseat6_preview.jpg'),
        sm: require('../assets/images/projects/Grabaseat/grabaseat6.jpg'),
        md: require('../assets/images/projects/Grabaseat/grabaseat6.jpg'),
        lg: require('../assets/images/projects/Grabaseat/grabaseat6.jpg')
      }
    ],
    Hoist: [
      {
        preview: require('../assets/images/projects/Hoist/Hoist_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/Hoist/Hoist_ios_1.jpg'),
        md: require('../assets/images/projects/Hoist/Hoist_ios_1.jpg'),
        lg: require('../assets/images/projects/Hoist/Hoist_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Hoist/Hoist_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/Hoist/Hoist_ios_2.jpg'),
        md: require('../assets/images/projects/Hoist/Hoist_ios_2.jpg'),
        lg: require('../assets/images/projects/Hoist/Hoist_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Hoist/Hoist_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/Hoist/Hoist_ios_3.jpg'),
        md: require('../assets/images/projects/Hoist/Hoist_ios_3.jpg'),
        lg: require('../assets/images/projects/Hoist/Hoist_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Hoist/Hoist_ios_4_preview.jpg'),
        sm: require('../assets/images/projects/Hoist/Hoist_ios_4.jpg'),
        md: require('../assets/images/projects/Hoist/Hoist_ios_4.jpg'),
        lg: require('../assets/images/projects/Hoist/Hoist_ios_4.jpg')
      },
      {
        preview: require('../assets/images/projects/Hoist/Hoist_ios_5_preview.jpg'),
        sm: require('../assets/images/projects/Hoist/Hoist_ios_5.jpg'),
        md: require('../assets/images/projects/Hoist/Hoist_ios_5.jpg'),
        lg: require('../assets/images/projects/Hoist/Hoist_ios_5.jpg')
      },
      {
        preview: require('../assets/images/projects/Hoist/Hoist_ios_6_preview.jpg'),
        sm: require('../assets/images/projects/Hoist/Hoist_ios_6.jpg'),
        md: require('../assets/images/projects/Hoist/Hoist_ios_6.jpg'),
        lg: require('../assets/images/projects/Hoist/Hoist_ios_6.jpg')
      }
    ],
    Zoomy: [
      {
        preview: require('../assets/images/projects/Zoomy/zoomy1_preview.jpg'),
        sm: require('../assets/images/projects/Zoomy/zoomy1.jpg'),
        md: require('../assets/images/projects/Zoomy/zoomy1.jpg'),
        lg: require('../assets/images/projects/Zoomy/zoomy1.jpg')
      },
      {
        preview: require('../assets/images/projects/Zoomy/zoomy2_preview.jpg'),
        sm: require('../assets/images/projects/Zoomy/zoomy2.jpg'),
        md: require('../assets/images/projects/Zoomy/zoomy2.jpg'),
        lg: require('../assets/images/projects/Zoomy/zoomy2.jpg')
      },
      {
        preview: require('../assets/images/projects/Zoomy/zoomy3_preview.jpg'),
        sm: require('../assets/images/projects/Zoomy/zoomy3.jpg'),
        md: require('../assets/images/projects/Zoomy/zoomy3.jpg'),
        lg: require('../assets/images/projects/Zoomy/zoomy3.jpg')
      },
      {
        preview: require('../assets/images/projects/Zoomy/zoomy4_preview.jpg'),
        sm: require('../assets/images/projects/Zoomy/zoomy4.jpg'),
        md: require('../assets/images/projects/Zoomy/zoomy4.jpg'),
        lg: require('../assets/images/projects/Zoomy/zoomy4.jpg')
      },
      {
        preview: require('../assets/images/projects/Zoomy/zoomy5_preview.jpg'),
        sm: require('../assets/images/projects/Zoomy/zoomy5.jpg'),
        md: require('../assets/images/projects/Zoomy/zoomy5.jpg'),
        lg: require('../assets/images/projects/Zoomy/zoomy5.jpg')
      },
      {
        preview: require('../assets/images/projects/Zoomy/zoomy6_preview.jpg'),
        sm: require('../assets/images/projects/Zoomy/zoomy6.jpg'),
        md: require('../assets/images/projects/Zoomy/zoomy6.jpg'),
        lg: require('../assets/images/projects/Zoomy/zoomy6.jpg')
      },
      {
        preview: require('../assets/images/projects/Zoomy/zoomy7_preview.jpg'),
        sm: require('../assets/images/projects/Zoomy/zoomy7.jpg'),
        md: require('../assets/images/projects/Zoomy/zoomy7.jpg'),
        lg: require('../assets/images/projects/Zoomy/zoomy7.jpg')
      }
    ],
    Goodnest: [
      {
        preview: require('../assets/images/projects/Goodnest/Goodnest_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/Goodnest/Goodnest_ios_1.jpg'),
        md: require('../assets/images/projects/Goodnest/Goodnest_ios_1.jpg'),
        lg: require('../assets/images/projects/Goodnest/Goodnest_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Goodnest/Goodnest_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/Goodnest/Goodnest_ios_2.jpg'),
        md: require('../assets/images/projects/Goodnest/Goodnest_ios_2.jpg'),
        lg: require('../assets/images/projects/Goodnest/Goodnest_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Goodnest/Goodnest_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/Goodnest/Goodnest_ios_3.jpg'),
        md: require('../assets/images/projects/Goodnest/Goodnest_ios_3.jpg'),
        lg: require('../assets/images/projects/Goodnest/Goodnest_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Goodnest/Goodnest_android_1_preview.jpg'),
        sm: require('../assets/images/projects/Goodnest/Goodnest_android_1.jpg'),
        md: require('../assets/images/projects/Goodnest/Goodnest_android_1.jpg'),
        lg: require('../assets/images/projects/Goodnest/Goodnest_android_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Goodnest/Goodnest_android_2_preview.jpg'),
        sm: require('../assets/images/projects/Goodnest/Goodnest_android_2.jpg'),
        md: require('../assets/images/projects/Goodnest/Goodnest_android_2.jpg'),
        lg: require('../assets/images/projects/Goodnest/Goodnest_android_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Goodnest/Goodnest_android_3_preview.jpg'),
        sm: require('../assets/images/projects/Goodnest/Goodnest_android_3.jpg'),
        md: require('../assets/images/projects/Goodnest/Goodnest_android_3.jpg'),
        lg: require('../assets/images/projects/Goodnest/Goodnest_android_3.jpg')
      }
    ],
    Bubbly: [
      {
        preview: require('../assets/images/projects/Bubbly/Bubbly_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/Bubbly/Bubbly_ios_1.jpg'),
        md: require('../assets/images/projects/Bubbly/Bubbly_ios_1.jpg'),
        lg: require('../assets/images/projects/Bubbly/Bubbly_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Bubbly/Bubbly_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/Bubbly/Bubbly_ios_2.jpg'),
        md: require('../assets/images/projects/Bubbly/Bubbly_ios_2.jpg'),
        lg: require('../assets/images/projects/Bubbly/Bubbly_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Bubbly/Bubbly_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/Bubbly/Bubbly_ios_3.jpg'),
        md: require('../assets/images/projects/Bubbly/Bubbly_ios_3.jpg'),
        lg: require('../assets/images/projects/Bubbly/Bubbly_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Bubbly/Bubbly_ios_4_preview.jpg'),
        sm: require('../assets/images/projects/Bubbly/Bubbly_ios_4.jpg'),
        md: require('../assets/images/projects/Bubbly/Bubbly_ios_4.jpg'),
        lg: require('../assets/images/projects/Bubbly/Bubbly_ios_4.jpg')
      },
      {
        preview: require('../assets/images/projects/Bubbly/Bubbly_ios_5_preview.jpg'),
        sm: require('../assets/images/projects/Bubbly/Bubbly_ios_5.jpg'),
        md: require('../assets/images/projects/Bubbly/Bubbly_ios_5.jpg'),
        lg: require('../assets/images/projects/Bubbly/Bubbly_ios_5.jpg')
      },
      {
        preview: require('../assets/images/projects/Bubbly/Bubbly_ios_6_preview.jpg'),
        sm: require('../assets/images/projects/Bubbly/Bubbly_ios_6.jpg'),
        md: require('../assets/images/projects/Bubbly/Bubbly_ios_6.jpg'),
        lg: require('../assets/images/projects/Bubbly/Bubbly_ios_6.jpg')
      }
    ],
    SuperShuttle: [
      {
        preview: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_1.jpg'),
        md: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_1.jpg'),
        lg: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_2.jpg'),
        md: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_2.jpg'),
        lg: require('../assets/images/projects/SuperShuttle/SuperShuttle_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_1_preview.jpg'),
        sm: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_1.jpg'),
        md: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_1.jpg'),
        lg: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_1.jpg')
      },
      {
        preview: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_2_preview.jpg'),
        sm: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_2.jpg'),
        md: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_2.jpg'),
        lg: require('../assets/images/projects/SuperShuttle/SuperShuttle_android_2.jpg')
      },
      {
        preview: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_1_preview.jpg'),
        sm: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_1.jpg'),
        md: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_1.jpg'),
        lg: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_2_preview.jpg'),
        sm: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_2.jpg'),
        md: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_2.jpg'),
        lg: require('../assets/images/projects/SuperShuttle/SuperShuttle_web_2.jpg')
      }
    ],
    WhipAround: [
      {
        preview: require('../assets/images/projects/WhipAround/WhipAround_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/WhipAround/WhipAround_ios_1.jpg'),
        md: require('../assets/images/projects/WhipAround/WhipAround_ios_1.jpg'),
        lg: require('../assets/images/projects/WhipAround/WhipAround_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/WhipAround/WhipAround_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/WhipAround/WhipAround_ios_2.jpg'),
        md: require('../assets/images/projects/WhipAround/WhipAround_ios_2.jpg'),
        lg: require('../assets/images/projects/WhipAround/WhipAround_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/WhipAround/WhipAround_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/WhipAround/WhipAround_ios_3.jpg'),
        md: require('../assets/images/projects/WhipAround/WhipAround_ios_3.jpg'),
        lg: require('../assets/images/projects/WhipAround/WhipAround_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/WhipAround/WhipAround_android_1_preview.jpg'),
        sm: require('../assets/images/projects/WhipAround/WhipAround_android_1.jpg'),
        md: require('../assets/images/projects/WhipAround/WhipAround_android_1.jpg'),
        lg: require('../assets/images/projects/WhipAround/WhipAround_android_1.jpg')
      },
      {
        preview: require('../assets/images/projects/WhipAround/WhipAround_android_2_preview.jpg'),
        sm: require('../assets/images/projects/WhipAround/WhipAround_android_2.jpg'),
        md: require('../assets/images/projects/WhipAround/WhipAround_android_2.jpg'),
        lg: require('../assets/images/projects/WhipAround/WhipAround_android_2.jpg')
      },
      {
        preview: require('../assets/images/projects/WhipAround/WhipAround_android_3_preview.jpg'),
        sm: require('../assets/images/projects/WhipAround/WhipAround_android_3.jpg'),
        md: require('../assets/images/projects/WhipAround/WhipAround_android_3.jpg'),
        lg: require('../assets/images/projects/WhipAround/WhipAround_android_3.jpg')
      }
    ],
    Brightly: [
      {
        preview: require('../assets/images/projects/Brightly/Brightly_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/Brightly/Brightly_ios_1.jpg'),
        md: require('../assets/images/projects/Brightly/Brightly_ios_1.jpg'),
        lg: require('../assets/images/projects/Brightly/Brightly_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Brightly/Brightly_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/Brightly/Brightly_ios_2.jpg'),
        md: require('../assets/images/projects/Brightly/Brightly_ios_2.jpg'),
        lg: require('../assets/images/projects/Brightly/Brightly_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Brightly/Brightly_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/Brightly/Brightly_ios_3.jpg'),
        md: require('../assets/images/projects/Brightly/Brightly_ios_3.jpg'),
        lg: require('../assets/images/projects/Brightly/Brightly_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Brightly/Brightly_ios_4_preview.jpg'),
        sm: require('../assets/images/projects/Brightly/Brightly_ios_4.jpg'),
        md: require('../assets/images/projects/Brightly/Brightly_ios_4.jpg'),
        lg: require('../assets/images/projects/Brightly/Brightly_ios_4.jpg')
      },
      {
        preview: require('../assets/images/projects/Brightly/Brightly_ios_5_preview.jpg'),
        sm: require('../assets/images/projects/Brightly/Brightly_ios_5.jpg'),
        md: require('../assets/images/projects/Brightly/Brightly_ios_5.jpg'),
        lg: require('../assets/images/projects/Brightly/Brightly_ios_5.jpg')
      }
    ],
    RaisingChildren: [
      {
        preview: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_1.jpg'),
        md: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_1.jpg'),
        lg: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_2.jpg'),
        md: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_2.jpg'),
        lg: require('../assets/images/projects/RaisingChildren/RaisingChildren_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_1_preview.jpg'),
        sm: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_1.jpg'),
        md: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_1.jpg'),
        lg: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_1.jpg')
      },
      {
        preview: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_2_preview.jpg'),
        sm: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_2.jpg'),
        md: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_2.jpg'),
        lg: require('../assets/images/projects/RaisingChildren/RaisingChildren_android_2.jpg')
      },
      {
        preview: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_1_preview.jpg'),
        sm: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_1.jpg'),
        md: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_1.jpg'),
        lg: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_2_preview.jpg'),
        sm: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_2.jpg'),
        md: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_2.jpg'),
        lg: require('../assets/images/projects/RaisingChildren/RaisingChildren_web_2.jpg')
      }
    ],
    Offerbook: [
      {
        preview: require('../assets/images/projects/Offerbook/OfferBook_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/Offerbook/OfferBook_ios_1.jpg'),
        md: require('../assets/images/projects/Offerbook/OfferBook_ios_1.jpg'),
        lg: require('../assets/images/projects/Offerbook/OfferBook_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Offerbook/OfferBook_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/Offerbook/OfferBook_ios_2.jpg'),
        md: require('../assets/images/projects/Offerbook/OfferBook_ios_2.jpg'),
        lg: require('../assets/images/projects/Offerbook/OfferBook_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Offerbook/OfferBook_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/Offerbook/OfferBook_ios_3.jpg'),
        md: require('../assets/images/projects/Offerbook/OfferBook_ios_3.jpg'),
        lg: require('../assets/images/projects/Offerbook/OfferBook_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Offerbook/OfferBook_ios_4_preview.jpg'),
        sm: require('../assets/images/projects/Offerbook/OfferBook_ios_4.jpg'),
        md: require('../assets/images/projects/Offerbook/OfferBook_ios_4.jpg'),
        lg: require('../assets/images/projects/Offerbook/OfferBook_ios_4.jpg')
      },
      {
        preview: require('../assets/images/projects/Offerbook/OfferBook_ios_5_preview.jpg'),
        sm: require('../assets/images/projects/Offerbook/OfferBook_ios_5.jpg'),
        md: require('../assets/images/projects/Offerbook/OfferBook_ios_5.jpg'),
        lg: require('../assets/images/projects/Offerbook/OfferBook_ios_5.jpg')
      },
      {
        preview: require('../assets/images/projects/Offerbook/OfferBook_ios_6_preview.jpg'),
        sm: require('../assets/images/projects/Offerbook/OfferBook_ios_6.jpg'),
        md: require('../assets/images/projects/Offerbook/OfferBook_ios_6.jpg'),
        lg: require('../assets/images/projects/Offerbook/OfferBook_ios_6.jpg')
      }
    ],
    Dayly: [
      {
        preview: require('../assets/images/projects/Dayly/Dayly_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/Dayly/Dayly_ios_1.jpg'),
        md: require('../assets/images/projects/Dayly/Dayly_ios_1.jpg'),
        lg: require('../assets/images/projects/Dayly/Dayly_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/Dayly/Dayly_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/Dayly/Dayly_ios_2.jpg'),
        md: require('../assets/images/projects/Dayly/Dayly_ios_2.jpg'),
        lg: require('../assets/images/projects/Dayly/Dayly_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/Dayly/Dayly_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/Dayly/Dayly_ios_3.jpg'),
        md: require('../assets/images/projects/Dayly/Dayly_ios_3.jpg'),
        lg: require('../assets/images/projects/Dayly/Dayly_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/Dayly/Dayly_ios_4_preview.jpg'),
        sm: require('../assets/images/projects/Dayly/Dayly_ios_4.jpg'),
        md: require('../assets/images/projects/Dayly/Dayly_ios_4.jpg'),
        lg: require('../assets/images/projects/Dayly/Dayly_ios_4.jpg')
      },
      {
        preview: require('../assets/images/projects/Dayly/Dayly_ios_5_preview.jpg'),
        sm: require('../assets/images/projects/Dayly/Dayly_ios_5.jpg'),
        md: require('../assets/images/projects/Dayly/Dayly_ios_5.jpg'),
        lg: require('../assets/images/projects/Dayly/Dayly_ios_5.jpg')
      },
      {
        preview: require('../assets/images/projects/Dayly/Dayly_ios_6_preview.jpg'),
        sm: require('../assets/images/projects/Dayly/Dayly_ios_6.jpg'),
        md: require('../assets/images/projects/Dayly/Dayly_ios_6.jpg'),
        lg: require('../assets/images/projects/Dayly/Dayly_ios_6.jpg')
      }
    ],
    MVNZ: [
      {
        preview: require('../assets/images/projects/MVNZ/MVNZ_web_1_preview.jpg'),
        sm: require('../assets/images/projects/MVNZ/MVNZ_web_1.jpg'),
        md: require('../assets/images/projects/MVNZ/MVNZ_web_1.jpg'),
        lg: require('../assets/images/projects/MVNZ/MVNZ_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/MVNZ/MVNZ_web_2_preview.jpg'),
        sm: require('../assets/images/projects/MVNZ/MVNZ_web_2.jpg'),
        md: require('../assets/images/projects/MVNZ/MVNZ_web_2.jpg'),
        lg: require('../assets/images/projects/MVNZ/MVNZ_web_2.jpg')
      },
      {
        preview: require('../assets/images/projects/MVNZ/MVNZ_web_3_preview.jpg'),
        sm: require('../assets/images/projects/MVNZ/MVNZ_web_3.jpg'),
        md: require('../assets/images/projects/MVNZ/MVNZ_web_3.jpg'),
        lg: require('../assets/images/projects/MVNZ/MVNZ_web_3.jpg')
      },
      {
        preview: require('../assets/images/projects/MVNZ/MVNZ_web_4_preview.jpg'),
        sm: require('../assets/images/projects/MVNZ/MVNZ_web_4.jpg'),
        md: require('../assets/images/projects/MVNZ/MVNZ_web_4.jpg'),
        lg: require('../assets/images/projects/MVNZ/MVNZ_web_4.jpg')
      },
      {
        preview: require('../assets/images/projects/MVNZ/MVNZ_web_5_preview.jpg'),
        sm: require('../assets/images/projects/MVNZ/MVNZ_web_5.jpg'),
        md: require('../assets/images/projects/MVNZ/MVNZ_web_5.jpg'),
        lg: require('../assets/images/projects/MVNZ/MVNZ_web_5.jpg')
      },
      {
        preview: require('../assets/images/projects/MVNZ/MVNZ_web_6_preview.jpg'),
        sm: require('../assets/images/projects/MVNZ/MVNZ_web_6.jpg'),
        md: require('../assets/images/projects/MVNZ/MVNZ_web_6.jpg'),
        lg: require('../assets/images/projects/MVNZ/MVNZ_web_6.jpg')
      }
    ],
    FeedTime: [
      {
        preview: require('../assets/images/projects/FeedTime/FeedTime_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/FeedTime/FeedTime_ios_1.jpg'),
        md: require('../assets/images/projects/FeedTime/FeedTime_ios_1.jpg'),
        lg: require('../assets/images/projects/FeedTime/FeedTime_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/FeedTime/FeedTime_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/FeedTime/FeedTime_ios_2.jpg'),
        md: require('../assets/images/projects/FeedTime/FeedTime_ios_2.jpg'),
        lg: require('../assets/images/projects/FeedTime/FeedTime_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/FeedTime/FeedTime_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/FeedTime/FeedTime_ios_3.jpg'),
        md: require('../assets/images/projects/FeedTime/FeedTime_ios_3.jpg'),
        lg: require('../assets/images/projects/FeedTime/FeedTime_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/FeedTime/FeedTime_web_1_preview.jpg'),
        sm: require('../assets/images/projects/FeedTime/FeedTime_web_1.jpg'),
        md: require('../assets/images/projects/FeedTime/FeedTime_web_1.jpg'),
        lg: require('../assets/images/projects/FeedTime/FeedTime_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/FeedTime/FeedTime_web_2_preview.jpg'),
        sm: require('../assets/images/projects/FeedTime/FeedTime_web_2.jpg'),
        md: require('../assets/images/projects/FeedTime/FeedTime_web_2.jpg'),
        lg: require('../assets/images/projects/FeedTime/FeedTime_web_2.jpg')
      },
      {
        preview: require('../assets/images/projects/FeedTime/FeedTime_web_3_preview.jpg'),
        sm: require('../assets/images/projects/FeedTime/FeedTime_web_3.jpg'),
        md: require('../assets/images/projects/FeedTime/FeedTime_web_3.jpg'),
        lg: require('../assets/images/projects/FeedTime/FeedTime_web_3.jpg')
      }
    ],
    EasyWords: [
      {
        preview: require('../assets/images/projects/EasyWords/EasyWords_ios_1_preview.jpg'),
        sm: require('../assets/images/projects/EasyWords/EasyWords_ios_1.jpg'),
        md: require('../assets/images/projects/EasyWords/EasyWords_ios_1.jpg'),
        lg: require('../assets/images/projects/EasyWords/EasyWords_ios_1.jpg')
      },
      {
        preview: require('../assets/images/projects/EasyWords/EasyWords_ios_2_preview.jpg'),
        sm: require('../assets/images/projects/EasyWords/EasyWords_ios_2.jpg'),
        md: require('../assets/images/projects/EasyWords/EasyWords_ios_2.jpg'),
        lg: require('../assets/images/projects/EasyWords/EasyWords_ios_2.jpg')
      },
      {
        preview: require('../assets/images/projects/EasyWords/EasyWords_ios_3_preview.jpg'),
        sm: require('../assets/images/projects/EasyWords/EasyWords_ios_3.jpg'),
        md: require('../assets/images/projects/EasyWords/EasyWords_ios_3.jpg'),
        lg: require('../assets/images/projects/EasyWords/EasyWords_ios_3.jpg')
      },
      {
        preview: require('../assets/images/projects/EasyWords/EasyWords_web_1_preview.jpg'),
        sm: require('../assets/images/projects/EasyWords/EasyWords_web_1.jpg'),
        md: require('../assets/images/projects/EasyWords/EasyWords_web_1.jpg'),
        lg: require('../assets/images/projects/EasyWords/EasyWords_web_1.jpg')
      },
      {
        preview: require('../assets/images/projects/EasyWords/EasyWords_web_2_preview.jpg'),
        sm: require('../assets/images/projects/EasyWords/EasyWords_web_2.jpg'),
        md: require('../assets/images/projects/EasyWords/EasyWords_web_2.jpg'),
        lg: require('../assets/images/projects/EasyWords/EasyWords_web_2.jpg')
      },
      {
        preview: require('../assets/images/projects/EasyWords/EasyWords_web_3_preview.jpg'),
        sm: require('../assets/images/projects/EasyWords/EasyWords_web_3.jpg'),
        md: require('../assets/images/projects/EasyWords/EasyWords_web_3.jpg'),
        lg: require('../assets/images/projects/EasyWords/EasyWords_web_3.jpg')
      }
    ],
    MoovingHouse: [
      {
        preview: require('../assets/images/projects/MoovingHouse/MovingApp_android_1_preview.jpg'),
        sm: require('../assets/images/projects/MoovingHouse/MovingApp_android_1.jpg'),
        md: require('../assets/images/projects/MoovingHouse/MovingApp_android_1.jpg'),
        lg: require('../assets/images/projects/MoovingHouse/MovingApp_android_1.jpg')
      },
      {
        preview: require('../assets/images/projects/MoovingHouse/MovingApp_android_2_preview.jpg'),
        sm: require('../assets/images/projects/MoovingHouse/MovingApp_android_2.jpg'),
        md: require('../assets/images/projects/MoovingHouse/MovingApp_android_2.jpg'),
        lg: require('../assets/images/projects/MoovingHouse/MovingApp_android_2.jpg')
      },
      {
        preview: require('../assets/images/projects/MoovingHouse/MovingApp_android_3_preview.jpg'),
        sm: require('../assets/images/projects/MoovingHouse/MovingApp_android_3.jpg'),
        md: require('../assets/images/projects/MoovingHouse/MovingApp_android_3.jpg'),
        lg: require('../assets/images/projects/MoovingHouse/MovingApp_android_3.jpg')
      },
      {
        preview: require('../assets/images/projects/MoovingHouse/MovingApp_android_4_preview.jpg'),
        sm: require('../assets/images/projects/MoovingHouse/MovingApp_android_4.jpg'),
        md: require('../assets/images/projects/MoovingHouse/MovingApp_android_4.jpg'),
        lg: require('../assets/images/projects/MoovingHouse/MovingApp_android_4.jpg')
      },
      {
        preview: require('../assets/images/projects/MoovingHouse/MovingApp_android_5_preview.jpg'),
        sm: require('../assets/images/projects/MoovingHouse/MovingApp_android_5.jpg'),
        md: require('../assets/images/projects/MoovingHouse/MovingApp_android_5.jpg'),
        lg: require('../assets/images/projects/MoovingHouse/MovingApp_android_5.jpg')
      },
      {
        preview: require('../assets/images/projects/MoovingHouse/MovingApp_android_6_preview.jpg'),
        sm: require('../assets/images/projects/MoovingHouse/MovingApp_android_6.jpg'),
        md: require('../assets/images/projects/MoovingHouse/MovingApp_android_6.jpg'),
        lg: require('../assets/images/projects/MoovingHouse/MovingApp_android_6.jpg')
      }
    ]
  },
  clients: [
    {
      name: 'Present.me',
      logos: {
        sm: require('../assets/images/clients/presentme.png'),
        md: require('../assets/images/clients/presentme@2x.png'),
        lg: require('../assets/images/clients/presentme@2x.png')
      }
    },
    {
      name: 'Milk Orthodontics',
      logos: {
        sm: require('../assets/images/clients/milk.png'),
        md: require('../assets/images/clients/milk@2x.png'),
        lg: require('../assets/images/clients/milk@2x.png')
      }
    },
    {
      name: 'Joyable',
      logos: {
        sm: require('../assets/images/clients/joyable.png'),
        md: require('../assets/images/clients/joyable@2x.png'),
        lg: require('../assets/images/clients/joyable@2x.png')
      }
    },
    {
      name: 'Foodprint',
      logos: {
        sm: require('../assets/images/clients/foodprint.png'),
        md: require('../assets/images/clients/foodprint@2x.png'),
        lg: require('../assets/images/clients/foodprint@2x.png')
      }
    },
    {
      name: 'Strongher',
      logos: {
        sm: require('../assets/images/clients/strongher.png'),
        md: require('../assets/images/clients/strongher@2x.png'),
        lg: require('../assets/images/clients/strongher@2x.png')
      }
    },
    {
      name: 'Travolab',
      logos: {
        sm: require('../assets/images/clients/travolab.png'),
        md: require('../assets/images/clients/travolab@2x.png'),
        lg: require('../assets/images/clients/travolab@2x.png')
      }
    },
    {
      name: 'StarShipIT',
      logos: {
        sm: require('../assets/images/clients/starshipit.png'),
        md: require('../assets/images/clients/starshipit@2x.png'),
        lg: require('../assets/images/clients/starshipit@2x.png')
      }
    },
    {
      name: 'Wolf and Fox',
      logos: {
        sm: require('../assets/images/clients/wolffox.png'),
        md: require('../assets/images/clients/wolffox@2x.png'),
        lg: require('../assets/images/clients/wolffox@2x.png')
      }
    },
    {
      name: 'Air New Zealand',
      logos: {
        sm: require('../assets/images/clients/airNZ.png'),
        md: require('../assets/images/clients/airNZ@2x.png'),
        lg: require('../assets/images/clients/airNZ@2x.png')
      }
    },
    {
      name: 'Grabaseat',
      logos: {
        sm: require('../assets/images/clients/grabaseat.png'),
        md: require('../assets/images/clients/grabaseat@2x.png'),
        lg: require('../assets/images/clients/grabaseat@2x.png')
      }
    },
    {
      name: 'Goodnest',
      logos: {
        sm: require('../assets/images/clients/goodnest.png'),
        md: require('../assets/images/clients/goodnest@2x.png'),
        lg: require('../assets/images/clients/goodnest@2x.png')
      }
    },
    {
      name: 'LazyAz',
      logos: {
        sm: require('../assets/images/clients/lazyaz.png'),
        md: require('../assets/images/clients/lazyaz@2x.png'),
        lg: require('../assets/images/clients/lazyaz@2x.png')
      }
    },
    {
      name: 'Hoist',
      logos: {
        sm: require('../assets/images/clients/hoist.png'),
        md: require('../assets/images/clients/hoist@2x.png'),
        lg: require('../assets/images/clients/hoist@2x.png')
      }
    },
    {
      name: 'Whip Around',
      logos: {
        sm: require('../assets/images/clients/whiparound.png'),
        md: require('../assets/images/clients/whiparound@2x.png'),
        lg: require('../assets/images/clients/whiparound@2x.png')
      }
    },
    {
      name: 'Zoomy',
      logos: {
        sm: require('../assets/images/clients/zoomy.png'),
        md: require('../assets/images/clients/zoomy@2x.png'),
        lg: require('../assets/images/clients/zoomy@2x.png')
      }
    },
    {
      name: 'Palmers',
      logos: {
        sm: require('../assets/images/clients/palmers.png'),
        md: require('../assets/images/clients/palmers@2x.png'),
        lg: require('../assets/images/clients/palmers@2x.png')
      }
    },
    {
      name: 'Brandknew',
      logos: {
        sm: require('../assets/images/clients/brandknew.png'),
        md: require('../assets/images/clients/brandknew@2x.png'),
        lg: require('../assets/images/clients/brandknew@2x.png')
      }
    },
    {
      name: 'Bubbly',
      logos: {
        sm: require('../assets/images/clients/bubbly.png'),
        md: require('../assets/images/clients/bubbly@2x.png'),
        lg: require('../assets/images/clients/bubbly@2x.png')
      }
    },
    {
      name: 'Hays',
      logos: {
        sm: require('../assets/images/clients/hays.png'),
        md: require('../assets/images/clients/hays@2x.png'),
        lg: require('../assets/images/clients/hays@2x.png')
      }
    },
    {
      name: 'Clearpoint',
      logos: {
        sm: require('../assets/images/clients/clearpoint.png'),
        md: require('../assets/images/clients/clearpoint@2x.png'),
        lg: require('../assets/images/clients/clearpoint@2x.png')
      }
    },
    {
      name: 'Raising Children',
      logos: {
        sm: require('../assets/images/clients/raisingchildren.png'),
        md: require('../assets/images/clients/raisingchildren@2x.png'),
        lg: require('../assets/images/clients/raisingchildren@2x.png')
      }
    },
    {
      name: 'D-co',
      logos: {
        sm: require('../assets/images/clients/d&co.png'),
        md: require('../assets/images/clients/d&co@2x.png'),
        lg: require('../assets/images/clients/d&co@2x.png')
      }
    },
    {
      name: 'Satellite media',
      logos: {
        sm: require('../assets/images/clients/satellite.png'),
        md: require('../assets/images/clients/satellite@2x.png'),
        lg: require('../assets/images/clients/satellite@2x.png')
      }
    },
    {
      name: 'Userhouse',
      logos: {
        sm: require('../assets/images/clients/userhouse.png'),
        md: require('../assets/images/clients/userhouse@2x.png'),
        lg: require('../assets/images/clients/userhouse@2x.png')
      }
    },
    {
      name: 'Silverplate',
      logos: {
        sm: require('../assets/images/clients/silverplate.png'),
        md: require('../assets/images/clients/silverplate@2x.png'),
        lg: require('../assets/images/clients/silverplate@2x.png')
      }
    },
    {
      name: 'Brightly',
      logos: {
        sm: require('../assets/images/clients/brightly.png'),
        md: require('../assets/images/clients/brightly@2x.png'),
        lg: require('../assets/images/clients/brightly@2x.png')
      }
    }
  ],
  team: {
    igor: {
      sm: require('../assets/images/team/members/igor@2x.png'),
      md: require('../assets/images/team/members/igor@2x.png'),
      lg: require('../assets/images/team/members/igor@3x.png')
    },
    saha: {
      sm: require('../assets/images/team/members/saha@2x.png'),
      md: require('../assets/images/team/members/saha@2x.png'),
      lg: require('../assets/images/team/members/saha@3x.png')
    },
    lena: {
      sm: require('../assets/images/team/members/lena@2x.png'),
      md: require('../assets/images/team/members/lena@2x.png'),
      lg: require('../assets/images/team/members/lena@3x.png')
    },
    michael: {
      sm: require('../assets/images/team/members/michael@2x.png'),
      md: require('../assets/images/team/members/michael@2x.png'),
      lg: require('../assets/images/team/members/michael@3x.png')
    },
    tanja: {
      sm: require('../assets/images/team/members/tanja@2x.png'),
      md: require('../assets/images/team/members/tanja@2x.png'),
      lg: require('../assets/images/team/members/tanja@3x.png')
    },
    andrew: {
      sm: require('../assets/images/team/members/andrew@2x.png'),
      md: require('../assets/images/team/members/andrew@2x.png'),
      lg: require('../assets/images/team/members/andrew@3x.png')
    },
    elva: {
      sm: require('../assets/images/team/members/elva@2x.png'),
      md: require('../assets/images/team/members/elva@2x.png'),
      lg: require('../assets/images/team/members/elva@3x.png')
    },
    new_colleague: {
      sm: require('../assets/images/team/members/new_colleague@2x.png'),
      md: require('../assets/images/team/members/new_colleague@2x.png'),
      lg: require('../assets/images/team/members/new_colleague@3x.png')
    }
  },
  technologies_banner: require('../assets/images/technologies/technologies_banner.jpg'),

  testimonials: {
    bill_chalmers: require('../assets/images/clients/testimonials/bill_chalmers.jpg'),
    gabriel_long: require('../assets/images/clients/testimonials/gabriel_long.jpg'),
    matt_knight: require('../assets/images/clients/testimonials/matt_knight.jpg'),
    nick_macavoy: require('../assets/images/clients/testimonials/nick_macavoy.jpg'),
    toss_grumley: require('../assets/images/clients/testimonials/toss_grumley.jpg'),
    hiten_parbhu: require('../assets/images/clients/testimonials/no-photo.jpg'),
    abi_bennett: require('../assets/images/clients/testimonials/no-photo.jpg'),
    michal_garvey: require('../assets/images/clients/testimonials/michal_garvey.jpg'),
    rochelle_sheldon: require('../assets/images/clients/testimonials/rochelle_sheldon.jpg'),
    shaz_macavoy: require('../assets/images/clients/testimonials/shaz_macavoy.jpg')
  },
  services: {
    stage1: [
      {
        preview: require('../assets/images/services/1/preview/1.1_preview.png'),
        sm: require('../assets/images/services/1/1.1.png'),
        md: require('../assets/images/services/1/1.1.png'),
        lg: require('../assets/images/services/1/1.1.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.2_preview.png'),
        sm: require('../assets/images/services/1/1.2.png'),
        md: require('../assets/images/services/1/1.2.png'),
        lg: require('../assets/images/services/1/1.2.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.3_preview.png'),
        sm: require('../assets/images/services/1/1.3.png'),
        md: require('../assets/images/services/1/1.3.png'),
        lg: require('../assets/images/services/1/1.3.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.4_preview.png'),
        sm: require('../assets/images/services/1/1.4.png'),
        md: require('../assets/images/services/1/1.4.png'),
        lg: require('../assets/images/services/1/1.4.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.5_preview.png'),
        sm: require('../assets/images/services/1/1.5.png'),
        md: require('../assets/images/services/1/1.5.png'),
        lg: require('../assets/images/services/1/1.5.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.6_preview.png'),
        sm: require('../assets/images/services/1/1.6.png'),
        md: require('../assets/images/services/1/1.6.png'),
        lg: require('../assets/images/services/1/1.6.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.7_preview.png'),
        sm: require('../assets/images/services/1/1.7.png'),
        md: require('../assets/images/services/1/1.7.png'),
        lg: require('../assets/images/services/1/1.7.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.8_preview.png'),
        sm: require('../assets/images/services/1/1.8.png'),
        md: require('../assets/images/services/1/1.8.png'),
        lg: require('../assets/images/services/1/1.8.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.9_preview.png'),
        sm: require('../assets/images/services/1/1.9.png'),
        md: require('../assets/images/services/1/1.9.png'),
        lg: require('../assets/images/services/1/1.9.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.10_preview.png'),
        sm: require('../assets/images/services/1/1.10.png'),
        md: require('../assets/images/services/1/1.10.png'),
        lg: require('../assets/images/services/1/1.10.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.11_preview.png'),
        sm: require('../assets/images/services/1/1.11.png'),
        md: require('../assets/images/services/1/1.11.png'),
        lg: require('../assets/images/services/1/1.11.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.12_preview.png'),
        sm: require('../assets/images/services/1/1.12.png'),
        md: require('../assets/images/services/1/1.12.png'),
        lg: require('../assets/images/services/1/1.12.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.13_preview.png'),
        sm: require('../assets/images/services/1/1.13.png'),
        md: require('../assets/images/services/1/1.13.png'),
        lg: require('../assets/images/services/1/1.13.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.14_preview.png'),
        sm: require('../assets/images/services/1/1.14.png'),
        md: require('../assets/images/services/1/1.14.png'),
        lg: require('../assets/images/services/1/1.14.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.15_preview.png'),
        sm: require('../assets/images/services/1/1.15.png'),
        md: require('../assets/images/services/1/1.15.png'),
        lg: require('../assets/images/services/1/1.15.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.16_preview.png'),
        sm: require('../assets/images/services/1/1.16.png'),
        md: require('../assets/images/services/1/1.16.png'),
        lg: require('../assets/images/services/1/1.16.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.17_preview.png'),
        sm: require('../assets/images/services/1/1.17.png'),
        md: require('../assets/images/services/1/1.17.png'),
        lg: require('../assets/images/services/1/1.17.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.18_preview.png'),
        sm: require('../assets/images/services/1/1.18.png'),
        md: require('../assets/images/services/1/1.18.png'),
        lg: require('../assets/images/services/1/1.18.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.19_preview.png'),
        sm: require('../assets/images/services/1/1.19.png'),
        md: require('../assets/images/services/1/1.19.png'),
        lg: require('../assets/images/services/1/1.19.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.20_preview.png'),
        sm: require('../assets/images/services/1/1.20.png'),
        md: require('../assets/images/services/1/1.20.png'),
        lg: require('../assets/images/services/1/1.20.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.21_preview.png'),
        sm: require('../assets/images/services/1/1.21.png'),
        md: require('../assets/images/services/1/1.21.png'),
        lg: require('../assets/images/services/1/1.21.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.22_preview.png'),
        sm: require('../assets/images/services/1/1.22.png'),
        md: require('../assets/images/services/1/1.22.png'),
        lg: require('../assets/images/services/1/1.22.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.23_preview.png'),
        sm: require('../assets/images/services/1/1.23.png'),
        md: require('../assets/images/services/1/1.23.png'),
        lg: require('../assets/images/services/1/1.23.png')
      },
      {
        preview: require('../assets/images/services/1/preview/1.24_preview.png'),
        sm: require('../assets/images/services/1/1.24.png'),
        md: require('../assets/images/services/1/1.24.png'),
        lg: require('../assets/images/services/1/1.24.png')
      }
    ],
    stage2: [
      {
        preview: require('../assets/images/services/2/preview/2.1_preview.png'),
        sm: require('../assets/images/services/2/2.1.png'),
        md: require('../assets/images/services/2/2.1.png'),
        lg: require('../assets/images/services/2/2.1.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.2_preview.png'),
        sm: require('../assets/images/services/2/2.2.png'),
        md: require('../assets/images/services/2/2.2.png'),
        lg: require('../assets/images/services/2/2.2.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.3_preview.png'),
        sm: require('../assets/images/services/2/2.3.png'),
        md: require('../assets/images/services/2/2.3.png'),
        lg: require('../assets/images/services/2/2.3.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.4_preview.png'),
        sm: require('../assets/images/services/2/2.4.png'),
        md: require('../assets/images/services/2/2.4.png'),
        lg: require('../assets/images/services/2/2.4.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.5_preview.png'),
        sm: require('../assets/images/services/2/2.5.png'),
        md: require('../assets/images/services/2/2.5.png'),
        lg: require('../assets/images/services/2/2.5.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.6_preview.png'),
        sm: require('../assets/images/services/2/2.6.png'),
        md: require('../assets/images/services/2/2.6.png'),
        lg: require('../assets/images/services/2/2.6.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.7_preview.png'),
        sm: require('../assets/images/services/2/2.7.png'),
        md: require('../assets/images/services/2/2.7.png'),
        lg: require('../assets/images/services/2/2.7.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.8_preview.png'),
        sm: require('../assets/images/services/2/2.8.png'),
        md: require('../assets/images/services/2/2.8.png'),
        lg: require('../assets/images/services/2/2.8.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.9_preview.png'),
        sm: require('../assets/images/services/2/2.9.png'),
        md: require('../assets/images/services/2/2.9.png'),
        lg: require('../assets/images/services/2/2.9.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.10_preview.png'),
        sm: require('../assets/images/services/2/2.10.png'),
        md: require('../assets/images/services/2/2.10.png'),
        lg: require('../assets/images/services/2/2.10.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.11_preview.png'),
        sm: require('../assets/images/services/2/2.11.png'),
        md: require('../assets/images/services/2/2.11.png'),
        lg: require('../assets/images/services/2/2.11.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.12_preview.png'),
        sm: require('../assets/images/services/2/2.12.png'),
        md: require('../assets/images/services/2/2.12.png'),
        lg: require('../assets/images/services/2/2.12.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.13_preview.png'),
        sm: require('../assets/images/services/2/2.13.png'),
        md: require('../assets/images/services/2/2.13.png'),
        lg: require('../assets/images/services/2/2.13.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.14_preview.png'),
        sm: require('../assets/images/services/2/2.14.png'),
        md: require('../assets/images/services/2/2.14.png'),
        lg: require('../assets/images/services/2/2.14.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.15_preview.png'),
        sm: require('../assets/images/services/2/2.15.png'),
        md: require('../assets/images/services/2/2.15.png'),
        lg: require('../assets/images/services/2/2.15.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.16_preview.png'),
        sm: require('../assets/images/services/2/2.16.png'),
        md: require('../assets/images/services/2/2.16.png'),
        lg: require('../assets/images/services/2/2.16.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.17_preview.png'),
        sm: require('../assets/images/services/2/2.17.png'),
        md: require('../assets/images/services/2/2.17.png'),
        lg: require('../assets/images/services/2/2.17.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.18_preview.png'),
        sm: require('../assets/images/services/2/2.18.png'),
        md: require('../assets/images/services/2/2.18.png'),
        lg: require('../assets/images/services/2/2.18.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.19_preview.png'),
        sm: require('../assets/images/services/2/2.19.png'),
        md: require('../assets/images/services/2/2.19.png'),
        lg: require('../assets/images/services/2/2.19.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.20_preview.png'),
        sm: require('../assets/images/services/2/2.20.png'),
        md: require('../assets/images/services/2/2.20.png'),
        lg: require('../assets/images/services/2/2.20.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.21_preview.png'),
        sm: require('../assets/images/services/2/2.21.png'),
        md: require('../assets/images/services/2/2.21.png'),
        lg: require('../assets/images/services/2/2.21.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.22_preview.png'),
        sm: require('../assets/images/services/2/2.22.png'),
        md: require('../assets/images/services/2/2.22.png'),
        lg: require('../assets/images/services/2/2.22.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.23_preview.png'),
        sm: require('../assets/images/services/2/2.23.png'),
        md: require('../assets/images/services/2/2.23.png'),
        lg: require('../assets/images/services/2/2.23.png')
      },
      {
        preview: require('../assets/images/services/2/preview/2.24_preview.png'),
        sm: require('../assets/images/services/2/2.24.png'),
        md: require('../assets/images/services/2/2.24.png'),
        lg: require('../assets/images/services/2/2.24.png')
      }
    ],
    stage3: [
      {
        preview: require('../assets/images/services/3/preview/3.1_preview.png'),
        sm: require('../assets/images/services/3/3.1.png'),
        md: require('../assets/images/services/3/3.1.png'),
        lg: require('../assets/images/services/3/3.1.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.2_preview.png'),
        sm: require('../assets/images/services/3/3.2.png'),
        md: require('../assets/images/services/3/3.2.png'),
        lg: require('../assets/images/services/3/3.2.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.3_preview.png'),
        sm: require('../assets/images/services/3/3.3.png'),
        md: require('../assets/images/services/3/3.3.png'),
        lg: require('../assets/images/services/3/3.3.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.4_preview.png'),
        sm: require('../assets/images/services/3/3.4.png'),
        md: require('../assets/images/services/3/3.4.png'),
        lg: require('../assets/images/services/3/3.4.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.5_preview.png'),
        sm: require('../assets/images/services/3/3.5.png'),
        md: require('../assets/images/services/3/3.5.png'),
        lg: require('../assets/images/services/3/3.5.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.6_preview.png'),
        sm: require('../assets/images/services/3/3.6.png'),
        md: require('../assets/images/services/3/3.6.png'),
        lg: require('../assets/images/services/3/3.6.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.7_preview.png'),
        sm: require('../assets/images/services/3/3.7.png'),
        md: require('../assets/images/services/3/3.7.png'),
        lg: require('../assets/images/services/3/3.7.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.8_preview.png'),
        sm: require('../assets/images/services/3/3.8.png'),
        md: require('../assets/images/services/3/3.8.png'),
        lg: require('../assets/images/services/3/3.8.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.9_preview.png'),
        sm: require('../assets/images/services/3/3.9.png'),
        md: require('../assets/images/services/3/3.9.png'),
        lg: require('../assets/images/services/3/3.9.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.10_preview.png'),
        sm: require('../assets/images/services/3/3.10.png'),
        md: require('../assets/images/services/3/3.10.png'),
        lg: require('../assets/images/services/3/3.10.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.11_preview.png'),
        sm: require('../assets/images/services/3/3.11.png'),
        md: require('../assets/images/services/3/3.11.png'),
        lg: require('../assets/images/services/3/3.11.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.12_preview.png'),
        sm: require('../assets/images/services/3/3.12.png'),
        md: require('../assets/images/services/3/3.12.png'),
        lg: require('../assets/images/services/3/3.12.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.13_preview.png'),
        sm: require('../assets/images/services/3/3.13.png'),
        md: require('../assets/images/services/3/3.13.png'),
        lg: require('../assets/images/services/3/3.13.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.14_preview.png'),
        sm: require('../assets/images/services/3/3.14.png'),
        md: require('../assets/images/services/3/3.14.png'),
        lg: require('../assets/images/services/3/3.14.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.15_preview.png'),
        sm: require('../assets/images/services/3/3.15.png'),
        md: require('../assets/images/services/3/3.15.png'),
        lg: require('../assets/images/services/3/3.15.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.16_preview.png'),
        sm: require('../assets/images/services/3/3.16.png'),
        md: require('../assets/images/services/3/3.16.png'),
        lg: require('../assets/images/services/3/3.16.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.17_preview.png'),
        sm: require('../assets/images/services/3/3.17.png'),
        md: require('../assets/images/services/3/3.17.png'),
        lg: require('../assets/images/services/3/3.17.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.18_preview.png'),
        sm: require('../assets/images/services/3/3.18.png'),
        md: require('../assets/images/services/3/3.18.png'),
        lg: require('../assets/images/services/3/3.18.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.19_preview.png'),
        sm: require('../assets/images/services/3/3.19.png'),
        md: require('../assets/images/services/3/3.19.png'),
        lg: require('../assets/images/services/3/3.19.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.20_preview.png'),
        sm: require('../assets/images/services/3/3.20.png'),
        md: require('../assets/images/services/3/3.20.png'),
        lg: require('../assets/images/services/3/3.20.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.21_preview.png'),
        sm: require('../assets/images/services/3/3.21.png'),
        md: require('../assets/images/services/3/3.21.png'),
        lg: require('../assets/images/services/3/3.21.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.22_preview.png'),
        sm: require('../assets/images/services/3/3.22.png'),
        md: require('../assets/images/services/3/3.22.png'),
        lg: require('../assets/images/services/3/3.22.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.23_preview.png'),
        sm: require('../assets/images/services/3/3.23.png'),
        md: require('../assets/images/services/3/3.23.png'),
        lg: require('../assets/images/services/3/3.23.png')
      },
      {
        preview: require('../assets/images/services/3/preview/3.24_preview.png'),
        sm: require('../assets/images/services/3/3.24.png'),
        md: require('../assets/images/services/3/3.24.png'),
        lg: require('../assets/images/services/3/3.24.png')
      }
    ],
    stage4: [
      {
        preview: require('../assets/images/services/4/preview/4.1_preview.png'),
        sm: require('../assets/images/services/4/4.1.png'),
        md: require('../assets/images/services/4/4.1.png'),
        lg: require('../assets/images/services/4/4.1.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.2_preview.png'),
        sm: require('../assets/images/services/4/4.2.png'),
        md: require('../assets/images/services/4/4.2.png'),
        lg: require('../assets/images/services/4/4.2.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.3_preview.png'),
        sm: require('../assets/images/services/4/4.3.png'),
        md: require('../assets/images/services/4/4.3.png'),
        lg: require('../assets/images/services/4/4.3.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.4_preview.png'),
        sm: require('../assets/images/services/4/4.4.png'),
        md: require('../assets/images/services/4/4.4.png'),
        lg: require('../assets/images/services/4/4.4.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.5_preview.png'),
        sm: require('../assets/images/services/4/4.5.png'),
        md: require('../assets/images/services/4/4.5.png'),
        lg: require('../assets/images/services/4/4.5.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.6_preview.png'),
        sm: require('../assets/images/services/4/4.6.png'),
        md: require('../assets/images/services/4/4.6.png'),
        lg: require('../assets/images/services/4/4.6.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.7_preview.png'),
        sm: require('../assets/images/services/4/4.7.png'),
        md: require('../assets/images/services/4/4.7.png'),
        lg: require('../assets/images/services/4/4.7.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.8_preview.png'),
        sm: require('../assets/images/services/4/4.8.png'),
        md: require('../assets/images/services/4/4.8.png'),
        lg: require('../assets/images/services/4/4.8.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.9_preview.png'),
        sm: require('../assets/images/services/4/4.9.png'),
        md: require('../assets/images/services/4/4.9.png'),
        lg: require('../assets/images/services/4/4.9.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.10_preview.png'),
        sm: require('../assets/images/services/4/4.10.png'),
        md: require('../assets/images/services/4/4.10.png'),
        lg: require('../assets/images/services/4/4.10.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.11_preview.png'),
        sm: require('../assets/images/services/4/4.11.png'),
        md: require('../assets/images/services/4/4.11.png'),
        lg: require('../assets/images/services/4/4.11.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.12_preview.png'),
        sm: require('../assets/images/services/4/4.12.png'),
        md: require('../assets/images/services/4/4.12.png'),
        lg: require('../assets/images/services/4/4.12.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.13_preview.png'),
        sm: require('../assets/images/services/4/4.13.png'),
        md: require('../assets/images/services/4/4.13.png'),
        lg: require('../assets/images/services/4/4.13.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.14_preview.png'),
        sm: require('../assets/images/services/4/4.14.png'),
        md: require('../assets/images/services/4/4.14.png'),
        lg: require('../assets/images/services/4/4.14.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.15_preview.png'),
        sm: require('../assets/images/services/4/4.15.png'),
        md: require('../assets/images/services/4/4.15.png'),
        lg: require('../assets/images/services/4/4.15.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.16_preview.png'),
        sm: require('../assets/images/services/4/4.16.png'),
        md: require('../assets/images/services/4/4.16.png'),
        lg: require('../assets/images/services/4/4.16.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.17_preview.png'),
        sm: require('../assets/images/services/4/4.17.png'),
        md: require('../assets/images/services/4/4.17.png'),
        lg: require('../assets/images/services/4/4.17.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.18_preview.png'),
        sm: require('../assets/images/services/4/4.18.png'),
        md: require('../assets/images/services/4/4.18.png'),
        lg: require('../assets/images/services/4/4.18.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.19_preview.png'),
        sm: require('../assets/images/services/4/4.19.png'),
        md: require('../assets/images/services/4/4.19.png'),
        lg: require('../assets/images/services/4/4.19.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.20_preview.png'),
        sm: require('../assets/images/services/4/4.20.png'),
        md: require('../assets/images/services/4/4.20.png'),
        lg: require('../assets/images/services/4/4.20.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.21_preview.png'),
        sm: require('../assets/images/services/4/4.21.png'),
        md: require('../assets/images/services/4/4.21.png'),
        lg: require('../assets/images/services/4/4.21.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.22_preview.png'),
        sm: require('../assets/images/services/4/4.22.png'),
        md: require('../assets/images/services/4/4.22.png'),
        lg: require('../assets/images/services/4/4.22.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.23_preview.png'),
        sm: require('../assets/images/services/4/4.23.png'),
        md: require('../assets/images/services/4/4.23.png'),
        lg: require('../assets/images/services/4/4.23.png')
      },
      {
        preview: require('../assets/images/services/4/preview/4.24_preview.png'),
        sm: require('../assets/images/services/4/4.24.png'),
        md: require('../assets/images/services/4/4.24.png'),
        lg: require('../assets/images/services/4/4.24.png')
      }
    ],
    stage5: [
      {
        preview: require('../assets/images/services/5/preview/5.1_preview.png'),
        sm: require('../assets/images/services/5/5.1.png'),
        md: require('../assets/images/services/5/5.1.png'),
        lg: require('../assets/images/services/5/5.1.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.2_preview.png'),
        sm: require('../assets/images/services/5/5.2.png'),
        md: require('../assets/images/services/5/5.2.png'),
        lg: require('../assets/images/services/5/5.2.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.3_preview.png'),
        sm: require('../assets/images/services/5/5.3.png'),
        md: require('../assets/images/services/5/5.3.png'),
        lg: require('../assets/images/services/5/5.3.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.4_preview.png'),
        sm: require('../assets/images/services/5/5.4.png'),
        md: require('../assets/images/services/5/5.4.png'),
        lg: require('../assets/images/services/5/5.4.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.5_preview.png'),
        sm: require('../assets/images/services/5/5.5.png'),
        md: require('../assets/images/services/5/5.5.png'),
        lg: require('../assets/images/services/5/5.5.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.6_preview.png'),
        sm: require('../assets/images/services/5/5.6.png'),
        md: require('../assets/images/services/5/5.6.png'),
        lg: require('../assets/images/services/5/5.6.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.7_preview.png'),
        sm: require('../assets/images/services/5/5.7.png'),
        md: require('../assets/images/services/5/5.7.png'),
        lg: require('../assets/images/services/5/5.7.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.8_preview.png'),
        sm: require('../assets/images/services/5/5.8.png'),
        md: require('../assets/images/services/5/5.8.png'),
        lg: require('../assets/images/services/5/5.8.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.9_preview.png'),
        sm: require('../assets/images/services/5/5.9.png'),
        md: require('../assets/images/services/5/5.9.png'),
        lg: require('../assets/images/services/5/5.9.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.10_preview.png'),
        sm: require('../assets/images/services/5/5.10.png'),
        md: require('../assets/images/services/5/5.10.png'),
        lg: require('../assets/images/services/5/5.10.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.11_preview.png'),
        sm: require('../assets/images/services/5/5.11.png'),
        md: require('../assets/images/services/5/5.11.png'),
        lg: require('../assets/images/services/5/5.11.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.12_preview.png'),
        sm: require('../assets/images/services/5/5.12.png'),
        md: require('../assets/images/services/5/5.12.png'),
        lg: require('../assets/images/services/5/5.12.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.13_preview.png'),
        sm: require('../assets/images/services/5/5.13.png'),
        md: require('../assets/images/services/5/5.13.png'),
        lg: require('../assets/images/services/5/5.13.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.14_preview.png'),
        sm: require('../assets/images/services/5/5.14.png'),
        md: require('../assets/images/services/5/5.14.png'),
        lg: require('../assets/images/services/5/5.14.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.15_preview.png'),
        sm: require('../assets/images/services/5/5.15.png'),
        md: require('../assets/images/services/5/5.15.png'),
        lg: require('../assets/images/services/5/5.15.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.16_preview.png'),
        sm: require('../assets/images/services/5/5.16.png'),
        md: require('../assets/images/services/5/5.16.png'),
        lg: require('../assets/images/services/5/5.16.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.17_preview.png'),
        sm: require('../assets/images/services/5/5.17.png'),
        md: require('../assets/images/services/5/5.17.png'),
        lg: require('../assets/images/services/5/5.17.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.18_preview.png'),
        sm: require('../assets/images/services/5/5.18.png'),
        md: require('../assets/images/services/5/5.18.png'),
        lg: require('../assets/images/services/5/5.18.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.19_preview.png'),
        sm: require('../assets/images/services/5/5.19.png'),
        md: require('../assets/images/services/5/5.19.png'),
        lg: require('../assets/images/services/5/5.19.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.20_preview.png'),
        sm: require('../assets/images/services/5/5.20.png'),
        md: require('../assets/images/services/5/5.20.png'),
        lg: require('../assets/images/services/5/5.20.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.21_preview.png'),
        sm: require('../assets/images/services/5/5.21.png'),
        md: require('../assets/images/services/5/5.21.png'),
        lg: require('../assets/images/services/5/5.21.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.22_preview.png'),
        sm: require('../assets/images/services/5/5.22.png'),
        md: require('../assets/images/services/5/5.22.png'),
        lg: require('../assets/images/services/5/5.22.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.23_preview.png'),
        sm: require('../assets/images/services/5/5.23.png'),
        md: require('../assets/images/services/5/5.23.png'),
        lg: require('../assets/images/services/5/5.23.png')
      },
      {
        preview: require('../assets/images/services/5/preview/5.24_preview.png'),
        sm: require('../assets/images/services/5/5.24.png'),
        md: require('../assets/images/services/5/5.24.png'),
        lg: require('../assets/images/services/5/5.24.png')
      }
    ],
    stage6: [
      {
        preview: require('../assets/images/services/6/preview/6.1_preview.png'),
        sm: require('../assets/images/services/6/6.1.png'),
        md: require('../assets/images/services/6/6.1.png'),
        lg: require('../assets/images/services/6/6.1.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.2_preview.png'),
        sm: require('../assets/images/services/6/6.2.png'),
        md: require('../assets/images/services/6/6.2.png'),
        lg: require('../assets/images/services/6/6.2.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.3_preview.png'),
        sm: require('../assets/images/services/6/6.3.png'),
        md: require('../assets/images/services/6/6.3.png'),
        lg: require('../assets/images/services/6/6.3.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.4_preview.png'),
        sm: require('../assets/images/services/6/6.4.png'),
        md: require('../assets/images/services/6/6.4.png'),
        lg: require('../assets/images/services/6/6.4.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.5_preview.png'),
        sm: require('../assets/images/services/6/6.5.png'),
        md: require('../assets/images/services/6/6.5.png'),
        lg: require('../assets/images/services/6/6.5.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.6_preview.png'),
        sm: require('../assets/images/services/6/6.6.png'),
        md: require('../assets/images/services/6/6.6.png'),
        lg: require('../assets/images/services/6/6.6.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.7_preview.png'),
        sm: require('../assets/images/services/6/6.7.png'),
        md: require('../assets/images/services/6/6.7.png'),
        lg: require('../assets/images/services/6/6.7.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.8_preview.png'),
        sm: require('../assets/images/services/6/6.8.png'),
        md: require('../assets/images/services/6/6.8.png'),
        lg: require('../assets/images/services/6/6.8.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.9_preview.png'),
        sm: require('../assets/images/services/6/6.9.png'),
        md: require('../assets/images/services/6/6.9.png'),
        lg: require('../assets/images/services/6/6.9.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.10_preview.png'),
        sm: require('../assets/images/services/6/6.10.png'),
        md: require('../assets/images/services/6/6.10.png'),
        lg: require('../assets/images/services/6/6.10.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.11_preview.png'),
        sm: require('../assets/images/services/6/6.11.png'),
        md: require('../assets/images/services/6/6.11.png'),
        lg: require('../assets/images/services/6/6.11.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.12_preview.png'),
        sm: require('../assets/images/services/6/6.12.png'),
        md: require('../assets/images/services/6/6.12.png'),
        lg: require('../assets/images/services/6/6.12.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.13_preview.png'),
        sm: require('../assets/images/services/6/6.13.png'),
        md: require('../assets/images/services/6/6.13.png'),
        lg: require('../assets/images/services/6/6.13.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.14_preview.png'),
        sm: require('../assets/images/services/6/6.14.png'),
        md: require('../assets/images/services/6/6.14.png'),
        lg: require('../assets/images/services/6/6.14.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.15_preview.png'),
        sm: require('../assets/images/services/6/6.15.png'),
        md: require('../assets/images/services/6/6.15.png'),
        lg: require('../assets/images/services/6/6.15.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.16_preview.png'),
        sm: require('../assets/images/services/6/6.16.png'),
        md: require('../assets/images/services/6/6.16.png'),
        lg: require('../assets/images/services/6/6.16.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.17_preview.png'),
        sm: require('../assets/images/services/6/6.17.png'),
        md: require('../assets/images/services/6/6.17.png'),
        lg: require('../assets/images/services/6/6.17.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.18_preview.png'),
        sm: require('../assets/images/services/6/6.18.png'),
        md: require('../assets/images/services/6/6.18.png'),
        lg: require('../assets/images/services/6/6.18.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.19_preview.png'),
        sm: require('../assets/images/services/6/6.19.png'),
        md: require('../assets/images/services/6/6.19.png'),
        lg: require('../assets/images/services/6/6.19.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.20_preview.png'),
        sm: require('../assets/images/services/6/6.20.png'),
        md: require('../assets/images/services/6/6.20.png'),
        lg: require('../assets/images/services/6/6.20.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.21_preview.png'),
        sm: require('../assets/images/services/6/6.21.png'),
        md: require('../assets/images/services/6/6.21.png'),
        lg: require('../assets/images/services/6/6.21.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.22_preview.png'),
        sm: require('../assets/images/services/6/6.22.png'),
        md: require('../assets/images/services/6/6.22.png'),
        lg: require('../assets/images/services/6/6.22.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.23_preview.png'),
        sm: require('../assets/images/services/6/6.23.png'),
        md: require('../assets/images/services/6/6.23.png'),
        lg: require('../assets/images/services/6/6.23.png')
      },
      {
        preview: require('../assets/images/services/6/preview/6.24_preview.png'),
        sm: require('../assets/images/services/6/6.24.png'),
        md: require('../assets/images/services/6/6.24.png'),
        lg: require('../assets/images/services/6/6.24.png')
      }
    ],
    stage7: [
      {
        preview: require('../assets/images/services/7/preview/7.1_preview.png'),
        sm: require('../assets/images/services/7/7.1.png'),
        md: require('../assets/images/services/7/7.1.png'),
        lg: require('../assets/images/services/7/7.1.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.2_preview.png'),
        sm: require('../assets/images/services/7/7.2.png'),
        md: require('../assets/images/services/7/7.2.png'),
        lg: require('../assets/images/services/7/7.2.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.3_preview.png'),
        sm: require('../assets/images/services/7/7.3.png'),
        md: require('../assets/images/services/7/7.3.png'),
        lg: require('../assets/images/services/7/7.3.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.4_preview.png'),
        sm: require('../assets/images/services/7/7.4.png'),
        md: require('../assets/images/services/7/7.4.png'),
        lg: require('../assets/images/services/7/7.4.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.5_preview.png'),
        sm: require('../assets/images/services/7/7.5.png'),
        md: require('../assets/images/services/7/7.5.png'),
        lg: require('../assets/images/services/7/7.5.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.6_preview.png'),
        sm: require('../assets/images/services/7/7.6.png'),
        md: require('../assets/images/services/7/7.6.png'),
        lg: require('../assets/images/services/7/7.6.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.7_preview.png'),
        sm: require('../assets/images/services/7/7.7.png'),
        md: require('../assets/images/services/7/7.7.png'),
        lg: require('../assets/images/services/7/7.7.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.8_preview.png'),
        sm: require('../assets/images/services/7/7.8.png'),
        md: require('../assets/images/services/7/7.8.png'),
        lg: require('../assets/images/services/7/7.8.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.9_preview.png'),
        sm: require('../assets/images/services/7/7.9.png'),
        md: require('../assets/images/services/7/7.9.png'),
        lg: require('../assets/images/services/7/7.9.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.10_preview.png'),
        sm: require('../assets/images/services/7/7.10.png'),
        md: require('../assets/images/services/7/7.10.png'),
        lg: require('../assets/images/services/7/7.10.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.11_preview.png'),
        sm: require('../assets/images/services/7/7.11.png'),
        md: require('../assets/images/services/7/7.11.png'),
        lg: require('../assets/images/services/7/7.11.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.12_preview.png'),
        sm: require('../assets/images/services/7/7.12.png'),
        md: require('../assets/images/services/7/7.12.png'),
        lg: require('../assets/images/services/7/7.12.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.13_preview.png'),
        sm: require('../assets/images/services/7/7.13.png'),
        md: require('../assets/images/services/7/7.13.png'),
        lg: require('../assets/images/services/7/7.13.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.14_preview.png'),
        sm: require('../assets/images/services/7/7.14.png'),
        md: require('../assets/images/services/7/7.14.png'),
        lg: require('../assets/images/services/7/7.14.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.15_preview.png'),
        sm: require('../assets/images/services/7/7.15.png'),
        md: require('../assets/images/services/7/7.15.png'),
        lg: require('../assets/images/services/7/7.15.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.16_preview.png'),
        sm: require('../assets/images/services/7/7.16.png'),
        md: require('../assets/images/services/7/7.16.png'),
        lg: require('../assets/images/services/7/7.16.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.17_preview.png'),
        sm: require('../assets/images/services/7/7.17.png'),
        md: require('../assets/images/services/7/7.17.png'),
        lg: require('../assets/images/services/7/7.17.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.18_preview.png'),
        sm: require('../assets/images/services/7/7.18.png'),
        md: require('../assets/images/services/7/7.18.png'),
        lg: require('../assets/images/services/7/7.18.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.19_preview.png'),
        sm: require('../assets/images/services/7/7.19.png'),
        md: require('../assets/images/services/7/7.19.png'),
        lg: require('../assets/images/services/7/7.19.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.20_preview.png'),
        sm: require('../assets/images/services/7/7.20.png'),
        md: require('../assets/images/services/7/7.20.png'),
        lg: require('../assets/images/services/7/7.20.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.21_preview.png'),
        sm: require('../assets/images/services/7/7.21.png'),
        md: require('../assets/images/services/7/7.21.png'),
        lg: require('../assets/images/services/7/7.21.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.22_preview.png'),
        sm: require('../assets/images/services/7/7.22.png'),
        md: require('../assets/images/services/7/7.22.png'),
        lg: require('../assets/images/services/7/7.22.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.23_preview.png'),
        sm: require('../assets/images/services/7/7.23.png'),
        md: require('../assets/images/services/7/7.23.png'),
        lg: require('../assets/images/services/7/7.23.png')
      },
      {
        preview: require('../assets/images/services/7/preview/7.24_preview.png'),
        sm: require('../assets/images/services/7/7.24.png'),
        md: require('../assets/images/services/7/7.24.png'),
        lg: require('../assets/images/services/7/7.24.png')
      }
    ]
  },
  process: {
    stage1: {
      sm: require('../assets/images/process/research_01.png'),
      md: require('../assets/images/process/research_01@2x.png'),
      lg: require('../assets/images/process/research_01@3x.png')
    },
    stage2: {
      sm: require('../assets/images/process/wireframing_02.png'),
      md: require('../assets/images/process/wireframing_02@2x.png'),
      lg: require('../assets/images/process/wireframing_02@3x.png')
    },
    stage3: {
      sm: require('../assets/images/process/design_03.png'),
      md: require('../assets/images/process/design_03@2x.png'),
      lg: require('../assets/images/process/design_03@3x.png')
    },
    stage4: {
      sm: require('../assets/images/process/development_04.png'),
      md: require('../assets/images/process/development_04@2x.png'),
      lg: require('../assets/images/process/development_04@3x.png')
    },
    stage5: {
      sm: require('../assets/images/process/testing_05.png'),
      md: require('../assets/images/process/testing_05@2x.png'),
      lg: require('../assets/images/process/testing_05@3x.png')
    },
    stage6: {
      sm: require('../assets/images/process/release_06.png'),
      md: require('../assets/images/process/release_06@2x.png'),
      lg: require('../assets/images/process/release_06@3x.png')
    },
    stage7: {
      sm: require('../assets/images/process/support_07.png'),
      md: require('../assets/images/process/support_07@2x.png'),
      lg: require('../assets/images/process/support_07@3x.png')
    }
  },
  agency: {
    bg: {
      preview: require('../assets/images/agency/agency_banner_preview.jpg'),
      lg: require('../assets/images/agency/agency_banner-lg.jpg')
    },
    nzherald: {
      sm: require('../assets/images/agency/herald-logo.jpg'),
      md: require('../assets/images/agency/herald-logo@2x.jpg'),
      lg: require('../assets/images/agency/herald-logo@3x.jpg')
    },
    nzbusiness: {
      sm: require('../assets/images/agency/nzbusiness.jpg'),
      md: require('../assets/images/agency/nzbusiness@2x.jpg'),
      lg: require('../assets/images/agency/nzbusiness@3x.jpg')
    },
    stuff: {
      sm: require('../assets/images/agency/stuff-logo.jpg'),
      md: require('../assets/images/agency/stuff-logo@2x.jpg'),
      lg: require('../assets/images/agency/stuff-logo@3x.jpg')
    }
  },
  office: {
    bg: {
      sm: require('../assets/images/office/full-house-sm.png'),
      md: require('../assets/images/office/full-house-md.png'),
      lg: require('../assets/images/office/full-house-lg.png')
    },
    images: [
      {
        sm: require('../assets/images/office/office_1.jpg'),
        md: require('../assets/images/office/office_1@2x.jpg'),
        lg: require('../assets/images/office/office_1@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_2.jpg'),
        md: require('../assets/images/office/office_2@2x.jpg'),
        lg: require('../assets/images/office/office_2@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_3.jpg'),
        md: require('../assets/images/office/office_3@2x.jpg'),
        lg: require('../assets/images/office/office_3@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_4.jpg'),
        md: require('../assets/images/office/office_4@2x.jpg'),
        lg: require('../assets/images/office/office_4@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_6.jpg'),
        md: require('../assets/images/office/office_6@2x.jpg'),
        lg: require('../assets/images/office/office_6@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_5.jpg'),
        md: require('../assets/images/office/office_5@2x.jpg'),
        lg: require('../assets/images/office/office_5@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_7.jpg'),
        md: require('../assets/images/office/office_7@2x.jpg'),
        lg: require('../assets/images/office/office_7@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_8.jpg'),
        md: require('../assets/images/office/office_8@2x.jpg'),
        lg: require('../assets/images/office/office_8@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_11.jpg'),
        md: require('../assets/images/office/office_11@2x.jpg'),
        lg: require('../assets/images/office/office_11@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_10@2x.jpg'),
        md: require('../assets/images/office/office_10@2x.jpg'),
        lg: require('../assets/images/office/office_10@2x.jpg')
      },
      {
        sm: require('../assets/images/office/office_9.jpg'),
        md: require('../assets/images/office/office_9@2x.jpg'),
        lg: require('../assets/images/office/office_9@2x.jpg')
      }
    ]
  },
  packages: {
    covers: {
      development: {
        ios: require('../assets/images/packages/cover/package_develop_ios_app.svg'),
        android: require('../assets/images/packages/cover/package_develop_android_app.svg'),
        mobile: require('../assets/images/packages/cover/package_develop_mobile_app.svg'),
        web: require('../assets/images/packages/cover/package_develop_web_app.svg'),
        backend: require('../assets/images/packages/cover/package_develop_backend.svg'),
        turn_key: require('../assets/images/packages/cover/package_develop_turn_key.svg')
      },
      design: {
        brandbook: require('../assets/images/packages/cover/package_design_brandbook.svg'),
        logo: require('../assets/images/packages/cover/package_design_logo.svg'),
        ui: require('../assets/images/packages/cover/package_design_ui_ux.svg')
      },
      care: {
        ten_hours: require('../assets/images/packages/cover/package_care_10_hours.svg'),
        twenty_hours: require('../assets/images/packages/cover/package_care_20_hours.svg'),
        forty_hours: require('../assets/images/packages/cover/package_care_40_hours.svg')
      }
    },
    bgSmall: {
      ios: require('../assets/images/packages/bg/ios_small_banner.jpg'),
      android: require('../assets/images/packages/bg/android_small_banner.jpg'),
      mobile: require('../assets/images/packages/bg/mobile_small_banner.jpg'),
      web: require('../assets/images/packages/bg/web_small_banner.jpg'),
      backend: require('../assets/images/packages/bg/backend_small_banner.jpg'),
      turn_key: require('../assets/images/packages/bg/turnkey_small_banner.jpg'),
      brandbook: require('../assets/images/packages/bg/brand_small_banner.jpg'),
      logo: require('../assets/images/packages/bg/logo_small_banner.jpg'),
      ui: require('../assets/images/packages/bg/uxui_small_banner.jpg'),
      ten_hours: require('../assets/images/packages/bg/support10_small_banner.jpg'),
      twenty_hours: require('../assets/images/packages/bg/support20_small_banner.jpg'),
      forty_hours: require('../assets/images/packages/bg/support40_small_banner.jpg')
    },
    bgLarge: {
      ios: require('../assets/images/packages/bg/ios_large_banner.jpg'),
      android: require('../assets/images/packages/bg/android_large_banner.jpg'),
      mobile: require('../assets/images/packages/bg/mobile_large_banner.jpg'),
      web: require('../assets/images/packages/bg/web_large_banner.jpg'),
      backend: require('../assets/images/packages/bg/backend_large_banner.jpg'),
      turn_key: require('../assets/images/packages/bg/turnkey_large_banner.jpg'),
      brandbook: require('../assets/images/packages/bg/brand_large_banner.jpg'),
      logo: require('../assets/images/packages/bg/logo_large_banner.jpg'),
      ui: require('../assets/images/packages/bg/uxui_large_banner.jpg'),
      ten_hours: require('../assets/images/packages/bg/support10_large_banner.jpg'),
      twenty_hours: require('../assets/images/packages/bg/support20_large_banner.jpg'),
      forty_hours: require('../assets/images/packages/bg/support40_large_banner.jpg')
    },
    service: {
      development: {
        ios: require('../assets/images/packages/service/package_dev_ios_plain.svg'),
        android: require('../assets/images/packages/service/package_dev_android_plain.svg'),
        mobile: require('../assets/images/packages/service/package_dev_mobile_plain.svg'),
        web: require('../assets/images/packages/service/package_dev_web_plain.svg'),
        backend: require('../assets/images/packages/service/package_dev_backend_plain.svg'),
        turn_key: require('../assets/images/packages/service/package_dev_turn_key_plain.svg')
      },
      design: {
        brandbook: require('../assets/images/packages/service/package_design_brand_plain.svg'),
        logo: require('../assets/images/packages/service/package_design_logo_plain.svg'),
        ui: require('../assets/images/packages/service/package_design_ui_plain.svg')
      },
      care: {
        ten_hours: require('../assets/images/packages/service/package_support_10.svg'),
        twenty_hours: require('../assets/images/packages/service/package_support_20.svg'),
        forty_hours: require('../assets/images/packages/service/package_support_40.svg')
      }
    },
    designExamples: {
      ios: {
        bubbly: {
          ux: require('../assets/images/packages/designExamples/I_bubbly_ux.png'),
          ui: require('../assets/images/packages/designExamples/I_bubbly_ui.png')
        },
        goodnest: {
          ux: require('../assets/images/packages/designExamples/I_goodnest_ux.png'),
          ui: require('../assets/images/packages/designExamples/I_goodnest_ui.png')
        },
        lazyAz: {
          ux: require('../assets/images/packages/designExamples/I_lazy_ux.png'),
          ui: require('../assets/images/packages/designExamples/I_lazy_ui.png')
        },
        ss: {
          ux: require('../assets/images/packages/designExamples/I_ss_ux.png'),
          ui: require('../assets/images/packages/designExamples/I_ss_ui.png')
        },
        wa: {
          ux: require('../assets/images/packages/designExamples/I_wa_ux.png'),
          ui: require('../assets/images/packages/designExamples/I_wa_ui.png')
        }
      },
      android: {
        bubbly: {
          ux: require('../assets/images/packages/designExamples/A_bubbly_ux.png'),
          ui: require('../assets/images/packages/designExamples/A_bubbly_ui.png')
        },
        goodnest: {
          ux: require('../assets/images/packages/designExamples/A_goodnest_ux.png'),
          ui: require('../assets/images/packages/designExamples/A_goodnest_ui.png')
        },
        lazyAz: {
          ux: require('../assets/images/packages/designExamples/A_lazy_ux.png'),
          ui: require('../assets/images/packages/designExamples/A_lazy_ui.png')
        },
        ss: {
          ux: require('../assets/images/packages/designExamples/A_ss_ux.png'),
          ui: require('../assets/images/packages/designExamples/A_ss_ui.png')
        },
        wa: {
          ux: require('../assets/images/packages/designExamples/A_wa_ux.png'),
          ui: require('../assets/images/packages/designExamples/A_wa_ui.png')
        }
      },
      web: {
        lazyAz: {
          ux: require('../assets/images/packages/designExamples/web_lazy-ux@2x.png'),
          ui: require('../assets/images/packages/designExamples/web_lazy-ui@2x.png')
        },
        ss: {
          ux: require('../assets/images/packages/designExamples/web_shuttle-ux@2x.png'),
          ui: require('../assets/images/packages/designExamples/web_shuttle-ui@2x.png')
        },
        shared: {
          ux: require('../assets/images/packages/designExamples/web_shared-ux@2x.png'),
          ui: require('../assets/images/packages/designExamples/web_shared-ui@2x.png')
        }
      }
    }
  },
  techIcons: {
    swift: require('../assets/images/techIcons/swift.png'),
    amazon: require('../assets/images/techIcons/amazon.png'),
    kotlin: require('../assets/images/techIcons/kotlin.svg'),
    laravel: require('../assets/images/techIcons/laravel.png'),
    objectiveC: require('../assets/images/techIcons/objectiveC.svg'),
    postgresql: require('../assets/images/techIcons/postgresql.svg'),
    react: require('../assets/images/techIcons/react.png'),
    java: require('../assets/images/techIcons/java.svg')
  },
  mapPlaceholder: require('../assets/images/office/map_placeholder.png')
}
