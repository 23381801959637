import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ScrollAnimation from '../../_common/ScrollAnimation'
import ID from '../../../utils/uniqueId'
import './style.css'

SupportPlans.propTypes = {
  supportPlans: PropTypes.shape({
    rate: PropTypes.string,
    plans: PropTypes.arrayOf(PropTypes.shape({
      hours: PropTypes.string,
      platformsCount: PropTypes.string,
      platforms: PropTypes.string,
      discountRate: PropTypes.number
    }))
  })
}

function SupportPlans (props) {
  const { supportPlans } = props
  const { rate, plans } = supportPlans
  return (
    <div className='support-plans'>
      <ScrollAnimation effect='fadeInBigBottom'>
        <h3 className='examples__title'>Compare</h3>
      </ScrollAnimation>
      <ScrollAnimation effect='fadeInBottom'>
        {plans.map(({hours, platformsCount, platforms, discountRate}) => (
          <div className={cn('support-plans__row', { selected: rate === hours })} key={ID()}>
            <div className='col-block plan'>
              <div className='col-block__content'>
                <div className='plan-count'>{hours}</div>
                <div className='plan-title'>hours</div>
                <div className='plan-text'>
                  {`monthly
              ‘7 glyphs care’
              support package`}
                </div>
              </div>
            </div>
            <div className='col-block platforms'>
              <div className='col-block__content'>
                <div className='plan-count'>{platformsCount}</div>
                <div className='plan-title'>{platformsCount === '1' ? 'platform' : 'platforms'}</div>
                <div className='plan-text'>{platforms}</div>
              </div>
            </div>
            <div className='col-block discounts'>
              <div className='col-block__content'>
                <div className='plan-count'>{`${discountRate}%`}</div>
                <div className='plan-title'>discount</div>
                <div className='plan-text'>{`discount for other tasks an hourly rate of ${discountRate}%`}</div>
              </div>
            </div>
          </div>
        ))}
      </ScrollAnimation>
    </div>
  )
}

export default SupportPlans
