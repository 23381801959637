import React from 'react'
import ScrollAnimation from '../../../_common/ScrollAnimation'
import './style.css'

const hours = [
  { id: 1, count: 10 },
  { id: 2, count: 20, isPopular: true },
  { id: 3, count: 40 }
]

function Hours () {
  return (
    <div className='hours'>
      <ul className='hours__list'>
        {hours.map(hour => (
          <li key={hour.id} className='hour'>
            <ScrollAnimation className='hour__block' effect='fadeInBottom' delay={150 * (hour.id - 1)}>
              {hour.isPopular && <div className='hour__label label'>Popular</div>}
              <div>
                <div className='hour__count'>{hour.count}</div>
                <div className='hour__title'>hours</div>
                <div className='hour__text'>monthly support</div>
              </div>
              <div className='hour__subtext'>7 glyphs Care</div>
            </ScrollAnimation>
          </li>
        ))}
      </ul>
    </div>

  )
}

export default Hours
