import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import tagPath from '../../../utils/tagPath'
import './style.css'

NavigationSubLink.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.shape({})
}

function NavigationSubLink (props) {
  const { path, name, location } = props
  const pathName = window.location.pathname
  return (
    <li className='navigation-sub-link'>
      <NavLink
        className={cn('navigation-sub-link__href focus f ai-c jc-sb',
          { 'navigation-sub-link__href_active': pathName === tagPath(path) || pathName.includes(path) })}
        to={tagPath(path)}
        location={location}
      >
        {name}
        <div className='point point_navigation point_closest_sub-link' />
      </NavLink>
    </li>
  )
}

export default NavigationSubLink
