import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const View = ({ children, className }) => <p className={cn('View', className)}>{children}</p>

View.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default View
