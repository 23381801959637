import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import LogoIcon from '../../_icons/LogoIcon'
import { toggleBodylessSidebar, toggleLogolessSidebar } from '../../../redux/reducer'
import ContactLink from '../ContactLink/ContactLink'
import CONTENT from '../../../content/emptyPageContent'
import { PHONE, EMAIL, ADDRESS_SHORT } from '../../../constants/links'
import './style.css'

class EmptyRoute extends Component {
  static propTypes = {
    toggleSidebarVisibility: PropTypes.func,
    toggleLogoVisibility: PropTypes.func
  }

  componentDidMount () {
    const { toggleSidebarVisibility, toggleLogoVisibility } = this.props
    toggleSidebarVisibility(false)
    toggleLogoVisibility(false)
  }

  componentWillUnmount () {
    const { toggleSidebarVisibility, toggleLogoVisibility } = this.props
    toggleSidebarVisibility(true)
    toggleLogoVisibility(true)
  }

  render () {
    const {
      title, subtitle, text, textAside
    } = CONTENT
    const Logo = () => (
      <Link to='/' className='sidebar__logo'>
        <LogoIcon darkTheme className='logo-icon' />
        <span className='sidebar__company'>7 glyphs</span>
      </Link>
    )
    return (
      <section className='er-banner'>
        <div className='er-banner__logo-group'>
          <Logo />
          <p className='logo__text'>{textAside}</p>
        </div>
        <main className='img-block'>
          <div className='img-block__bg' />
          <div className='er-banner__content'>
            <h1 className='content-title'>{title}</h1>
            <h2 className='content-subtitle'>{subtitle}</h2>
            <p className='content-text'>{text}</p>
          </div>
        </main>
        <aside className='col-aside'>
          <div className='col-aside__text'>
            <ContactLink type='email' value={EMAIL} />
            <span className='separator' />
            <ContactLink type='telephone' value={PHONE} />
            <span className='separator' />
            <ContactLink type='address' value={ADDRESS_SHORT} />
          </div>
        </aside>
      </section>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  toggleSidebarVisibility: val => dispatch(toggleBodylessSidebar(val)),
  toggleLogoVisibility: val => dispatch(toggleLogolessSidebar(val))
})

export default connect(null, mapDispatchToProps)(EmptyRoute)
