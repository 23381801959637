/* eslint-disable max-len */
import React from 'react'

const FileDeleteIcon = props => (
  <svg width={8} height={8} viewBox='0 0 8 8' {...props}>
    <title>41358C38-D13A-41E6-84EA-86777AD768D3</title>
    <path
      d='M1.396 1l-.353.354.178.175 2.447 2.45-2.625 2.625.353.353 2.625-2.625 2.448 2.45.177.175L7 6.604l-.176-.178-2.449-2.447L7 1.354 6.646 1 4.021 3.625 1.572 1.178z'
      fill='#000'
      fillRule='nonzero'
      stroke='#131313'
      opacity={0.3}
    />
  </svg>
)

export default FileDeleteIcon
