/* eslint-disable max-len */
import React from 'react'

const TrendsIcon = props => (
  <svg width={26} height={27} viewBox='0 0 26 27' {...props}>
    <path
      fill='#FFF'
      d='M26 .86l-7.21 6.015-6.087-2.031-6 6.5L0 9.109V24c0 1.648 1.352 3 3 3h20c1.648 0 3-1.352 3-3V.86zm-2 4.28v9.594l-5.055 4.032L13 14.796 6.695 19H2v-7.11l5.297 1.766 6-6.5 5.914 1.969L24 5.141zM13 17.204l6.055 4.04L24 17.296V24c0 .563-.438 1-1 1H3c-.563 0-1-.438-1-1v-3h5.305L13 17.203z'
    />
  </svg>
)

export default TrendsIcon
