import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Waypoint from 'react-waypoint'
import ScrollAnimation from '../../_common/ScrollAnimation'
import Footer from '../../_common/Footer/Footer'
import ReasonsSlider from '../ReasonsSlider/ReasonsSlider'
import Banner from '../PackageBanner/PackageBanner'
import PackServices from '../PackServices/PackServices'
import Testimonials from '../Testimonials/Testimonials'
import PACKS from '../../../content/packagesContent'
import Messenger from '../../_common/Messenger/Messenger'
import { toggleBodylessSidebar, toggleLogolessSidebar } from '../../../redux/reducer'
import './style.css'

class Package extends Component {
  static propTypes = {
    toggleSidebarVisibility: PropTypes.func,
    toggleLogoVisibility: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        category: PropTypes.string,
        package: PropTypes.string
      })
    })
  }

  state = {
    shouldReasonsSliderStart: false
  }

  componentWillUnmount () {
    const { toggleSidebarVisibility, toggleLogoVisibility } = this.props
    toggleSidebarVisibility(true)
    toggleLogoVisibility(true)
    this.setState({ shouldReasonsSliderStart: false })
  }

  handleNavPosition = (previousPosition, currentPosition) => {
    const { toggleSidebarVisibility } = this.props
    toggleSidebarVisibility(false)
    if (((previousPosition === 'below' || previousPosition === 'undefined') && currentPosition === 'inside')) {
      toggleSidebarVisibility(false)
    }
    if (previousPosition === 'inside' && currentPosition === 'below') {
      toggleSidebarVisibility(true)
    }
    if (currentPosition === 'inside' || currentPosition === 'above') {
      toggleSidebarVisibility(false)
    }
    this.setState({ shouldReasonsSliderStart: true })
  }

  handleLogoPosition = (previousPosition, currentPosition) => {
    const { toggleLogoVisibility } = this.props
    if ((previousPosition === 'inside' || previousPosition === 'undefined') && currentPosition === 'above') {
      toggleLogoVisibility(false)
    }
    if (currentPosition === 'above') {
      toggleLogoVisibility(false)
    }
    if (previousPosition === 'above' && currentPosition === 'inside') {
      toggleLogoVisibility(true)
    }
  }

  render () {
    const { shouldReasonsSliderStart } = this.state
    const { match } = this.props
    const { params } = match
    const { category, package: packName } = params
    const pack = PACKS[category].find(item => item.linkTo === packName)

    return (
      <div className='package-item'>
        <h1 className='title package-item__title'>
          <Link to='/approach/packages'>Packages /</Link> {pack.linkName}
        </h1>
        <Banner {...pack} />
        <Waypoint
          topOffset={200}
          onEnter={({ previousPosition, currentPosition }) => (this.handleLogoPosition(previousPosition, currentPosition))}
          onLeave={({ previousPosition, currentPosition }) => (this.handleLogoPosition(previousPosition, currentPosition))}
        >
          <div className='package-item__description'>
            <div className='description__text'>
              <ScrollAnimation effect='fadeInBottom'>
                <p>{pack.description}</p>
              </ScrollAnimation>
            </div>
            <ReasonsSlider shouldStartAnimation={shouldReasonsSliderStart} reasons={pack.reasons} />
          </div>
        </Waypoint>
        <Waypoint
          onEnter={({ previousPosition, currentPosition }) => (this.handleNavPosition(previousPosition, currentPosition))}
          onLeave={({ previousPosition, currentPosition }) => (this.handleNavPosition(previousPosition, currentPosition))}
        >
          <div className='package-item__services-wrapper'>
            <PackServices {...pack} category={category} />
            <Testimonials />
            <ScrollAnimation className='package-item__brief-us' effect='fadeIn'>
              <span>&mdash; Got an idea?&nbsp;</span>
              <Link to='/get-in-touch/brief'>Brief us!</Link>
            </ScrollAnimation>
          </div>
        </Waypoint>
        <Footer />
        <Messenger />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  toggleSidebarVisibility: val => dispatch(toggleBodylessSidebar(val)),
  toggleLogoVisibility: val => dispatch(toggleLogolessSidebar(val))
})

export default connect(null, mapDispatchToProps)(Package)
