/* eslint-disable max-len */
import React from 'react'

const AppUpgradeIcon = props => (
  <svg width={26} height={26} {...props} viewBox='0 0 26 26'>
    <path
      fill='#FFF'
      d='M13 0C5.836 0 0 5.836 0 13s5.836 13 13 13 13-5.836 13-13S20.164 0 13 0zm-1 2.047V4h2V2.047c1.281.117 2.492.46 3.61.969l-.977 1.687 1.734 1 .977-1.687a11.15 11.15 0 0 1 2.64 2.64l-1.695.977 1 1.734 1.688-.976c.515 1.117.859 2.328.968 3.609H22v2h1.945a10.785 10.785 0 0 1-.968 3.61l-1.688-.977-1 1.734 1.695.977a11.151 11.151 0 0 1-2.64 2.64l-.977-1.695-1.734 1 .976 1.688a10.788 10.788 0 0 1-3.609.968V22h-2v1.945a10.785 10.785 0 0 1-3.61-.968l.977-1.688-1.734-1-.977 1.695a11.15 11.15 0 0 1-2.64-2.64l1.687-.977-1-1.734-1.687.976A11.167 11.167 0 0 1 2.046 14H4v-2H2.047c.117-1.281.46-2.492.969-3.61l1.687.977 1-1.734-1.687-.977a11.15 11.15 0 0 1 2.64-2.64l.977 1.687 1.734-1-.976-1.687A11.167 11.167 0 0 1 12 2.046zM13 7l-5 5h4v6h2v-6h4l-5-5z'
    />
  </svg>
)

export default AppUpgradeIcon
