import { URLS } from '../constants/urls'

export default function tagPath (string) {
  const base = string.split('/').slice(1)
  const innerRoutes = URLS[base[0]] && URLS[base[0]].routes && URLS[base[0]].routes
  const matchedRoute = innerRoutes && innerRoutes.find(route => (base[1] === 'projects'
    ? true
    : Object.values(route)[0].name.toLowerCase() === base[1]))
  const firstInRoute = (matchedRoute && Object.keys(matchedRoute)[0] === 'projects' && Object.values(matchedRoute)[0].routes[0][0].slug) ||
    (matchedRoute && Object.values(matchedRoute)[0].routes && Object.values(matchedRoute)[0].routes[0].slug)
  return (firstInRoute && `${string}/${firstInRoute}`) || string
}
