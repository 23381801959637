/* eslint-disable max-len */
import React from 'react'

const BestPracticesIcon = props => (
  <svg width={25} height={25} viewBox='0 0 25 25' {...props}>
    <path
      fill='#FFF'
      d='M10.156.857a.924.924 0 0 0-.87.929V3.41c0 2.234-.61 3.866-1.103 4.875H0V25h19.848c1.328 0 2.496-.907 2.786-2.198 0-.022.007-.05.007-.073l1.495-11.15-.022.087c.384-1.734-1.001-3.38-2.779-3.38h-6.478v-3.25c0-2.075-1.277-3.323-2.437-3.787-1.161-.472-2.264-.392-2.264-.392zm.987 2.002c.247.037.305-.007.58.102.697.29 1.277.667 1.277 2.075v4.178c0 .515.414.929.929.929h7.406c.668 0 1.096.53.972 1.117l-.021.08-1.473 11.07c-.094.414-.479.733-.965.733H9.286V10.179c.42-.646 1.857-3.12 1.857-6.768v-.552zm-9.286 7.284H7.43v13H1.857v-13zm2.786 9.286a.926.926 0 0 0-.929.928c0 .515.414.929.929.929a.926.926 0 0 0 .928-.929.926.926 0 0 0-.928-.928z'
    />
  </svg>
)

export default BestPracticesIcon
