import AppUpgradeIcon from './AppUpgradeIcon'
import ArrowDownIcon from './ArrowDownIcon'
import AstericksIcon from './AstericksIcon'
import AutomationIcon from './AutomationIcon'
import BestPracticesIcon from './BestPracticesIcon'
import BrandIcon from './BrandIcon'
import BriefUsIcon from './BriefUsIcon'
import CheckIcon from './CheckIcon/CheckIcon'
import CloudIcon from './CloudIcon'
import CodeUpdateIcon from './CodeUpdateIcon'
import DeploymentIcon from './DeploymentIcon'
import DropDownIcon from './DropDownIcon'
import FileDeleteIcon from './FileDeleteIcon'
import FileIcon from './FileIcon'
import InfrastructureIcon from './InfrastructureIcon'
import IntegrationIcon from './IntegrationIcon'
import LogoIcon from './LogoIcon'
import MonitoringIcon from './MonitoringIcon'
import OvalIcon from './OvalIcon'
import PieChartIcon from './PieChartIcon'
import PresentationIcon from './PresentationIcon'
import SecurityIcon from './SecurityIcon'
import StarImprovementsIcon from './StarImprovementsIcon'
import TimerIcon from './TimerIcon'
import TrendsIcon from './TrendsIcon'
import UIRefinementsIcon from './UIRefinementsIcon'
import UsersGroupIcon from './UsersGroupIcon'
import UXAnalyticsIcon from './UXAnalyticsIcon'
import UXTestingIcon from './UXTestingIcon'
import VisitUsIcon from './VisitUsIcon'

export {
  AppUpgradeIcon,
  ArrowDownIcon,
  AstericksIcon,
  AutomationIcon,
  BestPracticesIcon,
  BrandIcon,
  BriefUsIcon,
  CheckIcon,
  CloudIcon,
  CodeUpdateIcon,
  DeploymentIcon,
  DropDownIcon,
  FileDeleteIcon,
  FileIcon,
  InfrastructureIcon,
  IntegrationIcon,
  LogoIcon,
  MonitoringIcon,
  OvalIcon,
  PieChartIcon,
  PresentationIcon,
  SecurityIcon,
  StarImprovementsIcon,
  TimerIcon,
  TrendsIcon,
  UIRefinementsIcon,
  UsersGroupIcon,
  UXAnalyticsIcon,
  UXTestingIcon,
  VisitUsIcon
}
