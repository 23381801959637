/* eslint-disable max-len */
import React from 'react'

const DeploymentIcon = props => (
  <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
    <path
      fill='#FFF'
      d='M3 0a1 1 0 0 0-1 1v13H0v2h19c1.67 0 3 1.33 3 3s-1.33 3-3 3H0v2h19c2.36 0 4.07-1.79 4.586-4H24v-1c0-2.406-1.727-4.43-4-4.898V1a1 1 0 0 0-1-1H3zm1 2h14v12H4V2zm5 2a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2H9zM3 18a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'
    />
  </svg>
)

export default DeploymentIcon
