import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ScrollAnimation from '../ScrollAnimation'
import MuiButton from '../MuiButton/MuiButton'
import FOOTER from '../../../content/footerContent'
import { TABLET_VIEW } from '../../../constants/dimentions'
import { H2 } from '../../typography'
import { LogoIcon } from '../../_icons'
import './style.css'

function LogoWrapper () {
  return (
    <div className='footer__logo-wrapper'>
      <Link to='/' className='sidebar__logo footer__logo focus focus_full'>
        <LogoIcon className='logo-icon' />
        <H2 className='sidebar__company'>7 glyphs</H2>
      </Link>
    </div>
  )
}

FooterLinksColumn.propTypes = {
  name: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({}))
}

function FooterLinksColumn (props) {
  const { name: colName, links } = props
  return (
    <div className='footer__menu-col'>
      <div className='footer__menu-col-header'>{colName}</div>
      <div className='footer__menu-col-content'>
        {
          links.map(({ name: linkName, linkTo, type }) => (
            type && type === 'external'
              ? <a href={linkTo} rel='noopener noreferrer' target='_blank' className='footer__menu-col-link focus focus_full' key={linkName}>{linkName}</a>
              : <Link to={linkTo} className='footer__menu-col-link focus focus_full' key={linkName}>{linkName}</Link>
          ))
        }
      </div>
    </div>
  )
}

function BriefUsBtn () {
  return (
    <Link to='/get-in-touch/brief' className='description-btn-link focus focus_full'>
      <MuiButton kind='black' className='btn-link' tabIndex={-1}>Brief us</MuiButton>
    </Link>
  )
}

Footer.propTypes = {
  screenWidth: PropTypes.number
}

function Footer (props) {
  const { screenWidth } = props
  const { footerLinks, contacts, copyrightInfo } = FOOTER
  return (
    <div className='footer-container'>
      <footer className='footer'>
        <ScrollAnimation effect='fadeInLeft'>
          <LogoWrapper />
        </ScrollAnimation>
        <div className='footer-content'>
          {
            footerLinks.map((col, index) => ((screenWidth && screenWidth <= TABLET_VIEW && col.name.toLowerCase() === 'home')
              ? null
              : (
                <ScrollAnimation key={col.name} effect='fadeInBottom' duration={1000 + (index * 150)}>
                  <FooterLinksColumn {...col} />
                </ScrollAnimation>
              )))
          }
        </div>
        <BriefUsBtn />
        <ScrollAnimation className='footer__menu-col address-col' effect='fadeInRight'>
          {
            screenWidth > TABLET_VIEW &&
            (
              <div className='footer__menu-col-header'>
                <a className='focus focus_full' itemProp='telephone' href={`tel:${contacts.phone.replace(/\s/g, '')}`}>
                  {contacts.phone}
                </a>
              </div>
            )}
          <div className='footer__menu-col-content'>
            <a className='focus focus_full' itemProp='email' href={`mailto:${contacts.email}`}>
              {contacts.email}
            </a>
            <div>{contacts.address}</div>
            <div className='social-links'>
              {
                contacts.socials.map(({ icon, alt, linkTo }) => (
                  <a className='focus focus_full' href={linkTo} target='_blank' rel='noopener noreferrer' key={alt}><img src={icon} alt={alt} /></a>
                ))
              }
            </div>
          </div>
        </ScrollAnimation>
        <div className='footer__copyright-info'>
          {copyrightInfo}
        </div>
      </footer>
      <div className='address-col__print'>
        <div className='footer__menu-col-header'>
          <a className='focus focus_full' itemProp='telephone' href={`tel:${contacts.phone.replace(/\s/g, '')}`}>
            {contacts.phone}
          </a>
        </div>
        <div className='footer__menu-col-content'>
          <a className='focus focus_full' itemProp='email' href={`mailto:${contacts.email}`}>
            {contacts.email}
          </a>
          <div>{contacts.address}</div>
        </div>
        <div className='footer__copyright-info'>
          {copyrightInfo}
        </div>
      </div>
    </div>
  )
}

export default Footer
