import React from 'react'
import {
  CodeUpdateIcon, MonitoringIcon, BestPracticesIcon, SecurityIcon, UIRefinementsIcon, UXTestingIcon,
  UXAnalyticsIcon, AppUpgradeIcon, PieChartIcon, TrendsIcon, StarImprovementsIcon, IntegrationIcon,
  DeploymentIcon, PresentationIcon, BrandIcon, UsersGroupIcon, AutomationIcon, InfrastructureIcon, CloudIcon, AstericksIcon
} from '../components/_icons'
import { NBRSP } from '../constants/textFormat'
import IMAGES from '../utils/imagesProvider'

const features = {
  competitor_analysis: {
    title: 'Competitors\nanalysis', icon: <PieChartIcon />
  },
  trends: {
    title: 'Trends', icon: <TrendsIcon />
  },
  improvements: {
    title: 'Features &\n improvements', icon: <StarImprovementsIcon />
  },
  integration: {
    title: 'Integration', icon: <IntegrationIcon />
  },
  deployment: {
    title: 'Quick\ndeployment ', icon: <DeploymentIcon />
  },
  semantic_cloud: {
    title: 'Semantic\ncloud', icon: <CloudIcon />
  },
  target_audience: {
    title: 'Target\naudience', icon: <UsersGroupIcon />
  },
  monitoring: {
    title: `Critical${NBRSP}issues\nmonitoring`, icon: <MonitoringIcon />
  },
  code_update: {
    title: 'Code\nupdates', icon: <CodeUpdateIcon />
  },
  brand: {
    title: 'Brand\nsymbol', icon: <AstericksIcon />
  },
  best_practices: {
    title: `Following\nbest${NBRSP}practices`, icon: <BestPracticesIcon />
  },
  security: {
    title: 'Security\npatches', icon: <SecurityIcon />
  },
  design_refinements: {
    title: 'UI design refinements', icon: <UIRefinementsIcon />
  },
  ux_testing: {
    title: 'UX\ntesting', icon: <UXTestingIcon />
  },
  ux_analytics: {
    title: `UX${NBRSP}analytics\n&${NBRSP}research`, icon: <UXAnalyticsIcon />
  },
  content_upgrade: {
    title: `App${NBRSP}content\nupgrade`, icon: <AppUpgradeIcon />
  },
  automation: {
    title: 'Automation', icon: <AutomationIcon />
  },
  infrastructure: {
    title: 'Scalable\ninfrastructure', icon: <InfrastructureIcon />
  },
  digital_presents: {
    title: 'Digital\npresents', icon: <PresentationIcon />
  },
  brand_development: {
    title: 'Brand\ndevelopment', icon: <BrandIcon />
  },
  userbase: {
    title: 'Userbase\nincrease', icon: <UsersGroupIcon />
  }
}

const technologies = {
  swift: {
    id: 'swift', name: 'Swift', icon: IMAGES.techIcons.swift, label: 'trend', linkTo: 'swift.org'
  },
  objectiveC: {
    id: 'objectiveC', name: 'Objective-C', icon: IMAGES.techIcons.objectiveC, linkTo: 'developer.apple.com'
  },
  react: {
    id: 'ReactJS', name: 'React JS', icon: IMAGES.techIcons.react, label: 'trend', linkTo: 'reactjs.org'
  },
  reactNative: {
    id: 'reactNative', name: 'React Native', icon: IMAGES.techIcons.react, linkTo: 'reactnative.com'
  },
  java: {
    id: 'java', name: 'Java', icon: IMAGES.techIcons.java, linkTo: 'java.com'
  },
  kotlin: {
    id: 'kotlin', name: 'Kotlin', icon: IMAGES.techIcons.kotlin, label: 'trend', linkTo: 'kotlinlang.org'
  },
  laravel: {
    id: 'laravel', name: 'Laravel framework', icon: IMAGES.techIcons.laravel, label: 'trend', linkTo: 'laravel.com'
  },
  postgresql: {
    id: 'postgresql', name: 'PostgreSQL', icon: IMAGES.techIcons.postgresql, linkTo: 'postgresql.org'
  },
  amazon: {
    id: 'aws', name: 'Amazon Web Services', icon: IMAGES.techIcons.amazon, linkTo: 'aws.amazon.com'
  }
}

const designExamples = {
  ios: [
    {
      ui: IMAGES.packages.designExamples.ios.bubbly.ui,
      ux: IMAGES.packages.designExamples.ios.bubbly.ux
    },
    {
      ui: IMAGES.packages.designExamples.ios.goodnest.ui,
      ux: IMAGES.packages.designExamples.ios.goodnest.ux
    },
    {
      ui: IMAGES.packages.designExamples.ios.lazyAz.ui,
      ux: IMAGES.packages.designExamples.ios.lazyAz.ux
    },
    {
      ui: IMAGES.packages.designExamples.ios.ss.ui,
      ux: IMAGES.packages.designExamples.ios.ss.ux
    },
    {
      ui: IMAGES.packages.designExamples.ios.wa.ui,
      ux: IMAGES.packages.designExamples.ios.wa.ux
    }
  ],
  android: [
    {
      ui: IMAGES.packages.designExamples.android.bubbly.ui,
      ux: IMAGES.packages.designExamples.android.bubbly.ux
    },
    {
      ui: IMAGES.packages.designExamples.android.goodnest.ui,
      ux: IMAGES.packages.designExamples.android.goodnest.ux
    },
    {
      ui: IMAGES.packages.designExamples.android.lazyAz.ui,
      ux: IMAGES.packages.designExamples.android.lazyAz.ux
    },
    {
      ui: IMAGES.packages.designExamples.android.ss.ui,
      ux: IMAGES.packages.designExamples.android.ss.ux
    },
    {
      ui: IMAGES.packages.designExamples.android.wa.ui,
      ux: IMAGES.packages.designExamples.android.wa.ux
    }
  ],
  web: [
    {
      ui: IMAGES.packages.designExamples.web.lazyAz.ui,
      ux: IMAGES.packages.designExamples.web.lazyAz.ux
    },
    {
      ui: IMAGES.packages.designExamples.web.ss.ui,
      ux: IMAGES.packages.designExamples.web.ss.ux
    },
    {
      ui: IMAGES.packages.designExamples.web.shared.ui,
      ux: IMAGES.packages.designExamples.web.shared.ux
    }
  ]
}

const supportPlans = [
  {
    hours: '10',
    platformsCount: '1',
    platforms: 'iOS application\n' +
      'Android application\n' +
      'or web application',
    discountRate: 5
  },
  {
    hours: '20',
    platformsCount: '2-3',
    platforms: 'two mobile applications or\n' +
      'web applications',
    discountRate: 10
  },
  {
    hours: '40+',
    platformsCount: '3+',
    platforms: 'iOS application\n' +
      'Android application\n' +
      'and web application',
    discountRate: 10
  }
]

export const SUBSERVICES = {
  branding: {
    id: 'sub_service_branding',
    title: 'Branding',
    text: 'By creating a unique UI for your existing brand book or a new product, we aim to refresh your business’s identity. ' +
      'Through this, customers can easily distinguish and remember your business.',
    time: {
      val: '2-4',
      name: 'weeks'
    }
  },
  dev_app_mobile: {
    id: 'sub_service_dev_app_mobile',
    title: 'Mobile Application Development',
    text: 'We use premier tools and the best practices to develop pixel-perfect UI for your mobile application, making sure ' +
      'that the designs are well-supported, usable, and scalable – exactly how you envisioned the design to be without any misalignment.',
    time: {
      val: '4-16',
      name: 'weeks'
    },
    stackItems: [technologies.java, technologies.kotlin, technologies.reactNative, technologies.swift, technologies.objectiveC]
  },
  naming: {
    id: 'sub_service_naming',
    title: 'Naming',
    text: 'Finding a unique name for your business is extremely important. We will help you find a one-of-a-kind name that is not ' +
      'trademarked and has available domain names to register.',
    time: {
      val: '2-4',
      name: 'weeks'
    }
  },
  api_development: {
    id: 'sub_service_api_development',
    title: 'API Development',
    text: 'To ensure that the data is always secure and available, and the solution is scalable for the mobile application, ' +
      'we follow the best practices in API development.',
    time: {
      val: '6-10',
      name: 'weeks'
    },
    stackItems: [technologies.laravel]
  },
  cloud_setup: {
    id: 'sub_service_cloud_setup',
    title: 'Cloud setup and continuous deployment',
    text: 'We use different cloud computing platforms (AWS, Google Cloud, and Azure) and continuous deployment to obtain early feedback from users.',
    time: {
      val: '1-5',
      name: 'days'
    },
    keyItems: [features.deployment, features.automation, features.infrastructure]
  },
  cloud_setup_turn_key: {
    id: 'sub_service_cloud_setup',
    title: 'Cloud setup and continuous deployment',
    text: 'We use different cloud computing platforms (AWS, Google Cloud, and Azure) and continuous deployment to obtain early feedback from users.',
    time: {
      val: '1-5',
      name: 'days'
    },
    keyItems: [features.deployment, features.automation, features.infrastructure]
  },
  marketing_strategy: {
    id: 'sub_service_marketing',
    title: 'Digital marketing strategy and market execution',
    text: 'We employ the most effective digital marketing strategies to promote your business through your digital products. ' +
      'We monitor your target market views so we can devise a strategy on how to gain new customers and keep your existing ones satisfied.',
    time: {
      option: 'optional'
    },
    keyItems: [features.digital_presents, features.brand_development, features.userbase]
  }
}

export const SERVICES = {
  competitor_analysis_and_research_dev: {
    id: 'service_competitor_analysis_dev',
    title: 'Competitors analysis and Research',
    text: 'We understand how your existing and potential customers perceive your competitors, including their strengths and weaknesses. ' +
      'From here, we develop effective strategies in your target market.',
    time: {
      val: '1-2',
      name: 'weeks'
    },
    keyItems: [features.competitor_analysis, features.trends, features.improvements, features.integration]
  },
  product_analysis_ui: {
    id: 'service_product_analysis_ui',
    title: 'Your product analysis',
    text: 'We deeply analyze your product and test it using focus groups. This helps us diagnose and correct usability ' +
      'errors and complexities that the users stumble upon while using the product.',
    time: {
      val: '2',
      name: 'weeks'
    },
    keyItems: [features.competitor_analysis, features.trends, features.improvements, features.integration]
  },
  competitor_analysis_and_research_ui: {
    id: 'service_competitor_analysis_ui',
    title: 'Competitors analysis and Research',
    text: 'We understand how your existing and potential customers perceive your competitors, including their strengths and weaknesses. ' +
      'From here, we develop effective strategies in your target market.',
    time: {
      val: '1-3',
      name: 'weeks'
    }
  },
  competitor_analysis_and_research_brand: {
    id: 'service_competitor_analysis_ui',
    title: 'Competitors analysis and Research',
    text: 'We understand how your existing and potential customers perceive your competitors, including their strengths and weaknesses. ' +
      'From here, we develop effective strategies in your target market.',
    time: {
      val: '1-2',
      name: 'weeks'
    },
    keyItems: [features.competitor_analysis, features.semantic_cloud, features.target_audience, features.brand]
  },
  brandmark: {
    id: 'service_brandmark',
    title: 'Brandmark',
    text: 'Demonstrating the basics of your logo is part of our service so you can understand how to use it better. ' +
      'We will show you how it\'s structured, how it looks on different backgrounds, and the idea behind the glyphs.',
    time: {
      val: '1-2',
      name: 'weeks'
    }
  },
  logo_in_life: {
    id: 'service_logo_in_life',
    title: 'Logo in life',
    text: 'We make sure to incorporate your logo designs in real life by displaying it on accessories, merchandise, digital space, and other media.',
    time: {
      val: '1',
      name: 'week'
    }
  },
  brand_in_life: {
    id: 'service_brand_in_life',
    title: 'Brand in life',
    text: 'We make sure to incorporate your logo and brand designs in real life by displaying it on accessories, merchandise, digital space, and other media.',
    time: {
      val: '1-2',
      name: 'weeks'
    }
  },
  identity: {
    id: 'service_identity',
    title: 'Identity',
    text: 'We gather creative inspiration and design main colors, fonts, and mood boards.',
    time: {
      val: '1',
      name: 'week'
    }
  },
  competitor_analysis_and_research_logo: {
    id: 'service_product_analysis_logo',
    title: 'Competitors analysis and Research',
    text: 'We understand how your existing and potential customers perceive your competitors, including their strengths and weaknesses. ' +
      'From here, we develop effective strategies in your target market.',
    time: {
      val: '1-2',
      name: 'weeks'
    },
    keyItems: [features.competitor_analysis, features.semantic_cloud, features.target_audience, features.brand]
  },
  ux_design_ios: {
    id: 'service_ux_design_ios',
    title: 'User Experience (UX) Design',
    text: 'A good iOS application allows the users to meet their needs in the simplest and ' +
      'smoothest process. We make sure that the UX is excellent so your customers have a fuss-free experience with your application.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    designExamples: {
      presentationType: 'ios',
      slides: designExamples.ios
    }
  },
  ux_design_android: {
    id: 'service_ux_design_android',
    title: 'User Experience (UX) Design',
    text: 'A good Android application allows the users to meet their needs in the simplest and ' +
      'smoothest process. We make sure that the UX is excellent so your customers have a fuss-free experience with your application.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    designExamples: {
      presentationType: 'android',
      slides: designExamples.android
    }
  },
  ux_design_mobile: {
    id: 'service_ux_design_mobile',
    title: 'User Experience (UX) Design',
    text: 'A good mobile application allows the users to meet their needs in the simplest and ' +
      'smoothest process. We make sure that the UX is excellent so your customers have a fuss-free experience with your application.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    designExamples: {
      presentationType: 'ios',
      slides: designExamples.ios
    }
  },
  ux_design_web: {
    id: 'service_ux_design_web',
    title: 'User Experience (UX) Design',
    text: 'A good web application allows the users to meet their needs in the simplest and ' +
      'smoothest process. We make sure that the UX is excellent so your customers have a fuss-free experience with your application.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    designExamples: {
      presentationType: 'web',
      slides: designExamples.web
    }
  },
  ux_design_turn_key: {
    id: 'service_ux_design_turn_key',
    title: 'User Experience (UX) Design',
    text: 'A good digital product allows the users to meet their needs in the simplest and smoothest process. ' +
      'We make sure that the UX is excellent so your customers have a fuss-free experience with your application.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    designExamples: {
      presentationType: 'ios',
      slides: designExamples.ios
    }
  },
  ux_design_ui: {
    id: 'service_ux_design_ui',
    title: 'User Experience (UX) Design',
    text: 'We analyze the outcome of our product analysis and competitor research, then propose solutions to ensure the ' +
      'most efficient UX. Our expertise is geared towards defining the best UX for your target audience.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    designExamples: {
      presentationType: 'ios',
      slides: designExamples.ios
    }
  },
  ui_design: {
    id: 'service_ux_design',
    title: 'User Interface (UI) Design',
    text: 'Our goal is to design beautiful, simple, and intuitive UI that reflect the vision of your business.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.branding]
  },
  ui_design_turn_key: {
    id: 'service_ux_design',
    title: 'User Interface (UI) Design',
    text: 'Our goal is to design beautiful, simple, and intuitive UI that reflect the vision of your business.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.branding, SUBSERVICES.naming]
  },
  ui_design_ui: {
    id: 'service_ux_design',
    title: 'User Interface (UI) Design',
    text: 'Our goal is to design beautiful, simple, and intuitive UI that reflect the vision of your business.',
    time: {
      val: '3-6',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.branding, SUBSERVICES.dev_app_mobile]
  },
  app_dev_ios: {
    id: 'service_app_dev_ios',
    title: 'iOS Application Development',
    text: 'We use premier tools and the best practices to develop pixel-perfect UI for your iOS application, making sure ' +
      'that the designs are well-supported, usable, and scalable – exactly how you envisioned the design to be without any misalignment.',
    stackItems: [technologies.swift, technologies.objectiveC, technologies.reactNative],
    time: {
      val: '8-16',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.api_development]
  },
  app_dev_android: {
    id: 'service_app_dev_android',
    title: 'Android Application Development',
    text: 'We use premier tools and the best practices to develop pixel-perfect UI for your Android application, making sure ' +
      'that the designs are well-supported, usable, and scalable – exactly how you envisioned the design to be without any misalignment.',
    stackItems: [technologies.java, technologies.kotlin, technologies.reactNative],
    time: {
      val: '8-16',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.api_development]
  },
  app_dev_mobile: {
    id: 'service_app_dev_mobile',
    title: 'iOS & Android Application Development',
    text: 'We use premier tools and the best practices to develop pixel-perfect UI for your mobile application, making sure ' +
      'that the designs are well-supported, usable, and scalable – exactly how you envisioned the design to be without any misalignment.',
    stackItems: [technologies.java, technologies.kotlin, technologies.reactNative, technologies.swift, technologies.objectiveC],
    time: {
      val: '8-16',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.api_development]
  },
  app_dev_web: {
    id: 'service_app_dev_web',
    title: 'Web Application Development',
    text: 'We use premier tools and the best practices to develop pixel-perfect UI for your web application, making sure ' +
      'that the designs are well-supported, usable, and scalable – exactly how you envisioned the design to be without any misalignment.',
    stackItems: [technologies.react],
    time: {
      val: '8-16',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.api_development]
  },
  app_dev_backend: {
    id: 'service_app_dev_backend',
    title: 'API Development',
    text: 'To ensure that the data is always secure, scalable, timely, and available for the web application, we follow the best practices in API development.',
    stackItems: [technologies.postgresql, technologies.laravel, technologies.amazon],
    time: {
      val: '6-10',
      name: 'weeks'
    }
  },
  app_dev_turn_key: {
    id: 'service_app_dev_turn_key',
    title: 'iOS & Android & Web Application Development',
    text: 'We use premier tools and the best practices for each platform. We understand its differences to develop a native ' +
      'version and consider user behavior for those platforms.',
    stackItems: [technologies.java, technologies.kotlin, technologies.reactNative, technologies.swift, technologies.objectiveC, technologies.react],
    time: {
      val: '8-16',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.api_development]
  },
  testing_and_automation_ios: {
    id: 'service_testing_ios',
    title: 'Testing and Automation',
    text: 'Testing the application for functionality is crucial before deployment to ensure it passes quality and validation tests. ' +
      'We write unit tests, run integration testing, and perform revisions and debugging to guarantee a successful iOS application.',
    time: {
      val: '4-12',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.cloud_setup]
  },
  testing_and_automation_android: {
    id: 'service_testing_android',
    title: 'Testing and Automation',
    text: 'Testing the application is crucial during deployment to ensure Android application passes quality and validation tests. ' +
      'We write unit tests, run integration testing, and UI testing to guarantee a successful Android application. ' +
      'We automate as much tests as we can to make sure the new code runs smoothly without any interruptions.',
    time: {
      val: '4-12',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.cloud_setup]
  },
  testing_and_automation_mobile: {
    id: 'service_testing_mobile',
    title: 'Testing and Automation',
    text: 'Testing the application is crucial during deployment to ensure mobile application passes quality and validation ' +
      'tests. We write unit tests, run integration testing, and UI testing to guarantee a successful mobile application. ' +
      'We automate as much tests as we can to make sure the new code runs smoothly without any interruptions.',
    time: {
      val: '4-12',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.cloud_setup]
  },
  testing_and_automation_web: {
    id: 'service_testing_web',
    title: 'Testing and Automation',
    text: 'Testing the application is crucial during deployment to ensure mobile application passes quality and validation ' +
      'tests. We write unit tests, run integration testing, and UI testing to guarantee a successful web application. ' +
      'We automate as much tests as we can to make sure the new code runs smoothly without any interruptions.',
    time: {
      val: '4-8',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.cloud_setup]
  },
  testing_and_automation_backend: {
    id: 'service_testing_backend',
    title: 'Testing and Automation',
    text: 'Testing the application is crucial during deployment to ensure mobile application passes quality and validation ' +
      'tests. We write unit tests, run integration testing, and UI testing to guarantee a successful web application. ' +
      'We automate as much tests as we can to make sure the new code runs smoothly without any interruptions.',
    time: {
      val: '2-4',
      name: 'weeks'
    }
  },
  testing_and_automation_turn_key: {
    id: 'service_testing_turn_key',
    title: 'Testing and Automation',
    text: 'Testing the application is crucial during deployment to ensure mobile application passes quality and validation ' +
      'tests. We write unit tests, run integration testing, and UI testing to guarantee a successful web application. ' +
      'We automate as much tests as we can to make sure the new code runs smoothly without any interruptions.',
    time: {
      val: '4-16',
      name: 'weeks'
    },
    subServices: [SUBSERVICES.cloud_setup_turn_key]
  },
  usability_testing: {
    id: 'service_usability_testing',
    title: 'Usability testing',
    text: 'We firmly believe there’s no single solution which is right for every business. That\'s why we don\'t just ' +
      'focus on one platform, we work with a wide range of different solutions across the Martech spectrum. ',
    time: {
      val: '2-4',
      name: 'weeks'
    }
  },
  deployment_ios: {
    id: 'service_deployment_ios',
    title: 'Deployment to the Apple AppStore',
    text: 'After analyzing, designing, building, and testing, we will submit your iOS application to the App Store so it ' +
      'becomes available to your existing and potential customers.',
    time: {
      val: '1',
      name: 'day'
    },
    subServices: [SUBSERVICES.marketing_strategy]
  },
  deployment_android: {
    id: 'service_deployment_android',
    title: 'Deployment to the Google PlayStore',
    text: 'After analyzing, designing, building, and testing, we will submit your Android application to the Google PlayStore so it ' +
      'becomes available to your existing and potential customers.',
    time: {
      val: '1',
      name: 'day'
    },
    subServices: [SUBSERVICES.marketing_strategy]
  },
  deployment_mobile: {
    id: 'service_deployment_mobile',
    title: 'Deployment to the Apple AppStore & Google PlayStore',
    text: 'After analyzing, designing, building, and testing, we will submit your mobile application to the Apple AppStore ' +
      'so it becomes available to your existing and potential customers.',
    time: {
      val: '1',
      name: 'day'
    },
    subServices: [SUBSERVICES.marketing_strategy]
  },
  deployment_web: {
    id: 'service_deployment_web',
    title: 'Continuous deployment',
    text: 'To make sure that the software is consistently reliable with each version that we release, we use continuous deployment during production.',
    time: {
      val: '1',
      name: 'day'
    },
    subServices: [SUBSERVICES.marketing_strategy]
  },
  deployment_turn_key: {
    id: 'service_deployment_turn_key',
    title: 'Deployment',
    text: 'After analyzing, designing, building, and testing, we will market your product so it becomes available to your existing and potential customers.',
    time: {
      val: '1-4',
      name: 'days'
    },
    subServices: [SUBSERVICES.marketing_strategy]
  },
  cloud_setup: {
    id: 'service_cloud_setup',
    title: 'Cloud setup and continuous deployment of the API',
    text: 'We use different cloud computing platforms (AWS, Google Cloud, and Azure) and continuous deployment to ensure ' +
      'that the build passes quality and validation tests.',
    time: {
      val: '1-5',
      name: 'days'
    },
    keyItems: [features.competitor_analysis, features.trends, features.improvements]

  },
  support: {
    id: 'service_support',
    title: '‘7 glyphs Care’ support',
    text: 'At 7 glyphs, we don’t just design and build – we also offer technical support for your iOS application. ' +
      'You can choose between 10, 20, or 40 hours of monthly support in addition to your iOS application development package.',
    time: {
      val: '4',
      name: 'weeks',
      option: 'free'
    }
  },
  dev_support: {
    id: 'service_dev_support',
    title: 'Development support',
    text: 'We update our code to comply with the best practices and latest standards. We install security updates and update ' +
      'dependencies. We also monitor and fix critical bugs.',
    keyItems: [features.monitoring, features.code_update, features.best_practices, features.security]
  },
  design_support: {
    id: 'service_design_support',
    title: 'Design support',
    text: 'We make sure that the new UI elements and changes are consistent with your design guidelines.',
    keyItems: [features.design_refinements, features.ux_testing, features.ux_analytics, features.content_upgrade]
  },
  extra_features: {
    id: 'service_extra_features',
    title: 'Extra features',
    text: 'We don\'t waste any precious time, so all unused hours will be used to develop new features.'
  },
  how_many_hours_10: {
    id: 'service_hours_10',
    title: 'How to understand how many hours you need?',
    text: 'At 7 glyphs, we don\'t just design and build – we also offer support packages for any product size.',
    supportPlans: {
      rate: '10',
      plans: supportPlans
    }
  },
  how_many_hours_20: {
    id: 'service_hours_20',
    title: 'How to understand how many hours you need?',
    text: 'At 7 glyphs, we don\'t just design and build – we also offer support packages for any product size.',
    supportPlans: {
      rate: '20',
      plans: supportPlans
    }
  },
  how_many_hours_40: {
    id: 'service_hours_40',
    title: 'How to understand how many hours you need?',
    text: 'At 7 glyphs, we don\'t just design and build – we also offer support packages for any product size.',
    supportPlans: {
      rate: '40+',
      plans: supportPlans
    }
  },
  discount_5: {
    id: 'service_discount',
    title: '5% discount',
    text: 'We offer a 5% discount on the hourly rate if support is availed for more than 12 months. This discount also applies to other development tasks.'
  },
  discount_10: {
    id: 'service_discount',
    title: '10% discount',
    text: 'We offer a 10% discount on the hourly rate if support is availed for more than 12 months. This discount also applies to other development tasks.'
  },
  discount_15: {
    id: 'service_discount',
    title: '10% discount',
    text: 'We offer a 10% discount on the hourly rate if support is availed for more than 12 months. This discount also applies to other development tasks.'
  }
}

// service list takes time in working (5-day) weeks
// 1 day is 0.2 WW
// if time value is non-periodic (2 weeks) set the same min and max values
// free support is not included in overall time counting - zero value
export const SERVICE_LIST = {
  analysis: { name: 'Analysis', time: { min: 2, max: 2 } },
  research: { name: 'Research', time: { min: 1, max: 3 } },
  ux: { name: 'UX Mockup', time: { min: 3, max: 3 } },
  ui: { name: 'UI Design', time: { min: 3, max: 6 } },
  ios_dev: { name: 'iOS development', time: { min: 3, max: 6 } },
  android_dev: { name: 'Android development', time: { min: 8, max: 16 } },
  web_dev: { name: 'Web development', time: { min: 8, max: 16 } },
  testing: { name: 'Testing & Automation', time: { min: 2, max: 12 } },
  support: { name: '4 weeks free support', time: { min: 0, max: 0 } },
  gp_deploy: { name: 'Deployment to Google Play', time: { min: 0.2, max: 0.2 } },
  appstore_deploy: { name: 'Deployment to AppStore', time: { min: 1, max: 2 } },
  deploy: { name: 'Deployment', time: { min: 8, max: 16 } },
  api: { name: 'API development', time: { min: 2, max: 10 } },
  cloud: { name: 'Cloud setup', time: { min: 2, max: 4 } },
  usability: { name: 'Usability testing', time: { min: 2, max: 4 } },
  branding: { name: 'Branding', time: { min: 2, max: 4 } },
  naming: { name: 'Naming', time: { min: 2, max: 4 } },
  dev_support: { name: 'Development support', time: { min: 0, max: 0 } },
  design_support: { name: 'Design support', time: { min: 0, max: 0 } },
  digital_strategy: { name: 'Digital marketing strategy', time: { min: 0, max: 0 } }
}

export const PACKAGE_SERVICES_LIST = [
  {
    id: 'ios_dev',
    name: 'iOS application development',
    services: [SERVICE_LIST.research, SERVICE_LIST.ux, SERVICE_LIST.ui, SERVICE_LIST.ios_dev,
      SERVICE_LIST.testing, SERVICE_LIST.appstore_deploy, SERVICE_LIST.support]
  },
  {
    id: 'android_dev',
    name: 'Android application development',
    services: [SERVICE_LIST.research, SERVICE_LIST.ux, SERVICE_LIST.ui, SERVICE_LIST.android_dev,
      SERVICE_LIST.testing, SERVICE_LIST.gp_deploy, SERVICE_LIST.support]
  },
  {
    id: 'mobile_dev',
    name: 'Mobile application development',
    services: [SERVICE_LIST.research, SERVICE_LIST.ux, SERVICE_LIST.ui, SERVICE_LIST.ios_dev, SERVICE_LIST.android_dev,
      SERVICE_LIST.testing, SERVICE_LIST.appstore_deploy, SERVICE_LIST.gp_deploy, SERVICE_LIST.support]
  },
  {
    id: 'web_dev',
    name: 'Web application development',
    services: [SERVICE_LIST.research, SERVICE_LIST.ux, SERVICE_LIST.ui, SERVICE_LIST.web_dev,
      SERVICE_LIST.testing, SERVICE_LIST.deploy, SERVICE_LIST.support]
  },
  {
    id: 'be_dev',
    name: 'Backend development & solutions',
    services: [SERVICE_LIST.api, SERVICE_LIST.testing, SERVICE_LIST.cloud, SERVICE_LIST.support]
  },
  {
    id: 'tk_dev',
    name: 'Turn-key project development',
    services: [SERVICE_LIST.research, SERVICE_LIST.ux, SERVICE_LIST.ui, SERVICE_LIST.ios_dev, SERVICE_LIST.android_dev,
      SERVICE_LIST.web_dev, SERVICE_LIST.testing, SERVICE_LIST.appstore_deploy, SERVICE_LIST.gp_deploy, SERVICE_LIST.deploy, SERVICE_LIST.support]
  },
  {
    id: 'ui_ux',
    name: 'UI/UX analysis review & testing',
    services: [SERVICE_LIST.analysis, SERVICE_LIST.research, SERVICE_LIST.ux, SERVICE_LIST.ui]
  },
  {
    id: 'logo',
    name: 'Logo Design',
    services: [SERVICE_LIST.research, SERVICE_LIST.branding]
  },
  {
    id: 'brand',
    name: 'Brandbook',
    services: [SERVICE_LIST.research, SERVICE_LIST.branding]
  },
  {
    id: 'support_10',
    name: '10 hours support',
    services: [SERVICE_LIST.design_support, SERVICE_LIST.dev_support]
  },
  {
    id: 'support_20',
    name: '20 hours support',
    services: [SERVICE_LIST.design_support, SERVICE_LIST.dev_support]
  },
  {
    id: 'support_40',
    name: '40+ hours support',
    services: [SERVICE_LIST.design_support, SERVICE_LIST.dev_support]
  }
]
