import React from 'react'

import './style.css'

function CheckIcon () {
  return (
    <svg className='check-icon' height='10' width='12' viewBox='0 0 18 15'>
      <g fill='none' fillRule='evenodd' id='Page-1' stroke='none' strokeWidth='1'>
        <g fill='#4a4a4a' transform='translate(-423.000000, -47.000000)'>
          <g id='check' transform='translate(423.000000, 47.500000)'>
            <path d='M6,10.2 L1.8,6 L0.4,7.4 L6,13 L18,1 L16.6,-0.4 L6,10.2 Z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CheckIcon
