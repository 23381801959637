/* eslint-disable max-len */
import React from 'react'

const UXTestingIcon = props => (
  <svg width={21} height={28} viewBox='0 0 21 28' {...props}>
    <path
      fill='#FFF'
      d='M10.5 0C9.441 0 8.466.275 7.69.792c-.43.286-.517.85-.77 1.308H3.153A3.172 3.172 0 0 0 0 5.254v18.884A3.178 3.178 0 0 0 3.162 27.3h14.68A3.176 3.176 0 0 0 21 24.142V5.246A3.162 3.162 0 0 0 17.854 2.1H14.08c-.253-.458-.34-1.022-.77-1.308C12.534.275 11.559 0 10.5 0zm0 2.1c.68 0 1.28.195 1.645.439.365.243.455.457.455.611V4.2h2.1v2.1H6.3V4.2h2.1V3.15c0-.154.09-.368.455-.611.365-.244.965-.439 1.645-.439zM3.154 4.2H4.2v4.2h12.6V4.2h1.054c.59 0 1.046.457 1.046 1.046v18.896c0 .596-.462 1.058-1.058 1.058H3.162A1.044 1.044 0 0 1 2.1 24.138V5.254c0-.594.46-1.054 1.054-1.054zm8.638 6.284l-1.444 1.521 3.27 3.101 3.19-3.109-1.464-1.505-1.747 1.702-1.805-1.71zM4.2 12.6v2.1h4.2v-2.1H4.2zm7.592 4.487l-1.444 1.526 3.27 3.1 3.19-3.112-1.464-1.502-1.747 1.703-1.805-1.715zM4.2 18.9V21h4.2v-2.1H4.2z'
    />
  </svg>
)

export default UXTestingIcon
