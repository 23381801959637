import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const H6 = ({ children, className }) => <h6 className={cn('H6', className)}>{children}</h6>

H6.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default H6
