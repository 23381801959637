export const LINKEDIN = 'https://www.linkedin.com/company/7glyphs'
export const FACEBOOK = 'https://www.facebook.com/7glyphs'
export const TWITTER = 'https://twitter.com/7glyphs'
export const INSTAGRAM = 'https://www.instagram.com/7glyphs'
export const PHONE = '0 800 459 747'
export const EMAIL = 'hello@7glyphs.com'
export const SHOWREEL = 'https://vimeo.com/549094160'
export const ADDRESS = '137 Buckley Avennue, Hobsonvile, Auckland, NZ'
export const ADDRESS_SHORT = '137 Buckley Avennue'
export const GOOGLE_MAPS_API_KEY = 'AIzaSyA4qGn7EcMIQJ_DovKsrjPmP2OwmzylCSg'
export const FB_APP_ID = '263902037430900'
export const FB_PAGE_ID = '385021818308158'
export const PRESENTATION = 'https://d1z9xzxaic82bx.cloudfront.net/share/company_presentation.pdf'
