import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { colors } from '../../../styles/muiTheme'

const commonStyles = {
  invertedButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  },
  fixedWidthButton: {
    width: 184
  }
}
const styles = {
  blackButton: {
    backgroundColor: colors.black,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.black,
      '@media (hover: none)': {
        backgroundColor: colors.black
      }
    }
  },
  turquoiseButton: {
    backgroundColor: colors.paleTurquoise,
    color: colors.greyishBrown,
    '&:hover': {
      backgroundColor: colors.paleTurquoise,
      '@media (hover: none)': {
        backgroundColor: colors.paleTurquoise
      }
    }
  },
  turquoiseThreeButton: {
    backgroundColor: colors.turquoiseThree,
    color: colors.greyishBrown,
    '&:hover': {
      backgroundColor: colors.paleTurquoise,
      '@media (hover: none)': {
        backgroundColor: colors.paleTurquoise
      }
    }
  },
  invertedLightButton: {
    ...commonStyles.invertedButton,
    backgroundColor: 'transparent',
    border: 'solid 1px white',
    color: 'white'
  },
  invertedDarkButton: {
    ...commonStyles.invertedButton,
    backgroundColor: 'transparent',
    border: `solid 1px ${colors.greyishBrown}`,
    color: colors.greyishBrown
  },
  squareButton: {
    margin: 0,
    borderRadius: 0,
    minHeight: 40,
    color: 'white',
    backgroundColor: colors.blackThree,
    '&:hover': {
      backgroundColor: colors.blackThree,
      '@media (hover: none)': {
        backgroundColor: colors.blackThree
      }
    }
  }
}

MuiButton.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}).isRequired,
  kind: PropTypes.string,
  isFixedWidth: PropTypes.bool
}

MuiButton.defaultProps = {
  kind: 'black',
  isFixedWidth: false
}

function MuiButton ({
  children, classes, kind = 'black', isFixedWidth = false, ...props
}) {
  const style = isFixedWidth ? commonStyles.fixedWidthButton : null
  return (
    <Button
      {...props}
      {...{ style }}
      className={classes[`${kind}Button`]}
    >
      {children}
    </Button>
  )
}

export default withStyles(styles)(MuiButton)
