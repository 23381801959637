import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'
import tagPath from '../../../utils/tagPath'
import { MOBILE_VIEW } from '../../../constants/dimentions'
import NavigationSubLink from '../NavigationSubLink/NavigationSubLink'
import './style.css'

class NavigationLink extends Component {
  static propTypes = {
    order: PropTypes.number,
    activeLinkId: PropTypes.number.isRequired,
    darkTheme: PropTypes.bool,
    path: PropTypes.string.isRequired,
    activeRoute: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({})),
    onActiveLinkIdChange: PropTypes.func.isRequired,
    screenWidth: PropTypes.number,
    location: PropTypes.shape({})
  }

  static defaultProps = {
    routes: null,
    darkTheme: false
  }

  handleLinkClick = (id) => {
    const { onActiveLinkIdChange } = this.props
    onActiveLinkIdChange(id)
  }

  render () {
    const {
      path, name, routes, id, activeLinkId, activeRoute, screenWidth, location
    } = this.props
    const Point = () => (
      <li className='point point_navigation point_closest_link' />
    )
    const points = routes
      ? [...Array(routes.length)].map(() => <Point key={Math.random()} />)
      : (<li className='point point_navigation point_closest_link' />)
    const navigationLink = routes && (screenWidth > MOBILE_VIEW)
      ? (
        <NavLink
          to={tagPath(Object.values(routes[0])[0].path)}
          className='navigation-link__href focus'
        >
          {name}
          <ul className='navigation-link__points'>{points}</ul>
        </NavLink>
      )
      : (
        <button type='button' className='navigation-link__href focus'>
          {name}
          <ul className='navigation-link__points'>{points}</ul>
        </button>
      )
    return (
      <li
        className='navigation-link'
        onClick={() => this.handleLinkClick(id)}
        role='presentation'
      >
        <div
          className={cn('navigation-link__block',
            { 'navigation-link__block_active': activeLinkId === id },
            { category_active: activeRoute && activeRoute.includes(path) }
          )}
          id='navigation-link__block'
        >
          {
            !routes
              ? (
                <NavLink
                  className={`navigation-link__href focus
                  ${activeLinkId === id ? 'navigation-link__href_active' : ''}`}
                  to={path}
                >
                  {name}
                  <ul className='navigation-link__points single'><Point /></ul>
                </NavLink>
              )
              : (
                <div>
                  {navigationLink}
                  <ul className='navigation-link__list set-internal'>
                    {routes.map(route => (
                      <NavigationSubLink key={Math.random()} {...route[Object.keys(route)]} location={location} />
                    ))}
                  </ul>
                </div>
              )
          }
        </div>
      </li>
    )
  }
}
export default withRouter(NavigationLink)
