/* eslint-disable max-len */
import React from 'react'

const TimerIcon = props => (
  <svg width={13} height={13} viewBox='0 0 13 13' {...props}>
    <path
      d='M6.5 0A6.51 6.51 0 0 0 0 6.5 6.51 6.51 0 0 0 6.5 13 6.51 6.51 0 0 0 13 6.5 6.51 6.51 0 0 0 6.5 0zm0 1C9.543 1 12 3.457 12 6.5S9.543 12 6.5 12A5.493 5.493 0 0 1 1 6.5C1 3.457 3.457 1 6.5 1zM6 2v5h3V6H7V2H6z'
      opacity={0.8}
    />
  </svg>
)

export default TimerIcon
