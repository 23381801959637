/* eslint-disable max-len */
import React from 'react'

const BriefUsIcon = props => (
  <svg width={17} height={20} viewBox='0 0 17 20' {...props}>
    <title>Brief us</title>
    <path
      d='M8.5 0a2.32 2.32 0 0 0-2.173 1.538H2.318A2.321 2.321 0 0 0 0 3.846v13.846A2.321 2.321 0 0 0 2.318 20h12.364A2.321 2.321 0 0 0 17 17.692V3.846a2.321 2.321 0 0 0-2.318-2.308h-4.009A2.32 2.32 0 0 0 8.5 0zm0 1.538c.435 0 .773.337.773.77v.769h1.545v1.538H6.182V3.077h1.545v-.77c0-.432.338-.769.773-.769zM2.318 3.077h2.318v3.077h7.728V3.077h2.318c.434 0 .773.336.773.77v13.845c0 .433-.339.77-.773.77H2.318a.761.761 0 0 1-.773-.77V3.846c0-.433.339-.77.773-.77zm.773 6.154v1.538h1.545V9.231H3.091zm3.09 0v1.538h7.728V9.231H6.182zm-3.09 4.615v1.539h1.545v-1.539H3.091zm3.09 0v1.539h7.728v-1.539H6.182z'
      fill='#6BABF6'
      fillRule='nonzero'
    />
  </svg>
)

export default BriefUsIcon
