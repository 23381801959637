/* eslint-disable max-len */
import IMAGES from '../utils/imagesProvider'
import ID from '../utils/uniqueId'
import { WEBLINK, APP_STORE, GOOGLE_PLAY } from '../constants/names'
import testimonials from './testimonials'

const projects = {
  21: [
    {
      name: 'MadFit',
      year: 2021,
      tags: ['iOS and Android application', 'website'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'android development', 'API development', 'web development', 'support'],
      description: 'MadFit is a revolutionary app to help users achieve their fitness goals anywhere. Fitness trainer Maddie Lymburner will guide users through unique workouts designed to get the results directly on the phone.',
      shortDescription: 'MadFit is a revolutionary app to help users achieve their fitness goals anywhere.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://apps.apple.com/app/madfit-workout-at-home/id1577283718'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.madfit'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://maddielymburner.co'
        }
      ],
      gallery: IMAGES.projects.MadFit,
      slug: 'madfit',
      testimonials: {},
      titleColor: '#3D3D3D'
    },
    {
      name: 'Road Ninja',
      year: 2021,
      tags: ['web service', 'iOS and Android application'],
      services: ['branding', 'user experience', 'design','iOS development', 'android development', 'API development', 'web development', 'support'],
      description: 'An online platform that allows companies to hire drivers for commercial purposes. Find a driver of any type of vehicles & trailers, for any type of job and period of time.',
      shortDescription: 'An online platform that allows companies to hire drivers for commercial purposes.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://apps.apple.com/nz/app/road-ninja/id1528913988'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.roadninja'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://roadninja.co.nz'
        }
      ],
      gallery: IMAGES.projects.RoadNinja,
      slug: 'roadninja',
      testimonials: {},
      titleColor: '#000000'
    }
  ],
  20: [
    {
      name: 'Just Hatched',
      year: 2020,
      tags: ['iOS application', 'website'],
      services: ['branding', 'user experience', 'design','iOS development', 'web development', 'support'],
      description: 'Which side did the baby feed on last? How long was the midday nap? What time did the little one wake up? What was the last weigh in? The life of a new mum is oh-so full of questions. We’ve created Just Hatched to help mums keep full track of grams and millilitres while they get to fully enjoy the precious time with their babies.',
      shortDescription: 'Breastfeed, pumping, bottle, solids, sleep, diaper tracking and training for one or multiple babies.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://apps.apple.com/app/just-hatched-baby-tracker/id1468233687'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://justhatched.app'
        }
      ],
      gallery: IMAGES.projects.JustHatched,
      slug: 'justhatched',
      testimonials: {},
      titleColor: '#000000'
    },
    {
      name: 'Present.me',
      year: 2020,
      tags: ['web service', 'iOS and Android application'],
      services: ['branding', 'user experience', 'design','iOS development', 'android development', 'API development', 'web development', 'support'],
      description: 'Present.me takes the applicant through a step-by-step guide to create the perfect summary of life and work experience. The result of the course is a beautifully designed personal profile page to showcase applicant\'s skills and experience to potential employers and companies. A unique part of the course is the creation of the video pitch using our Present.me Telepromter app.',
      shortDescription: 'Present.me is the next generation digital resume creation tool.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://apps.apple.com/app/vpm-teleprompter/id1525321416'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.vpm'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://present.me'
        }
      ],
      gallery: IMAGES.projects.PresentMe,
      slug: 'presentme',
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Ally',
      year: 2020,
      tags: ['iOS Application', 'website'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'support'],
      description: 'Ally – Collect and Backup is the iOS Amiibo collection app with the ability to add amiibos to the collection by simply scanning their NFC tags. The app works with n2elite tags so users can quickly restore their backups there.',
      shortDescription: 'Ally – Collect and Backup is the iOS Amiibo collection app.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://apps.apple.com/app/ally-collect-and-backup/id1517551768?ls=1'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://ally.ninja'
        }
      ],
      gallery: IMAGES.projects.Ally,
      slug: 'ally',
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Milk Orthodontics',
      year: 2020,
      tags: ['website'],
      services: ['user experience', 'design', 'web development'],
      description: 'Milk Orthodontics is a friendly and modern orthodontic clinic specialising in all types of braces and Invisalign located at Eastridge in Mission Bay, Auckland.',
      shortDescription: 'Milk Orthodontics is a friendly and modern orthodontic clinic specialising in all types of braces and Invisalign.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://www.milkorthodontics.co.nz'
        }
      ],
      gallery: IMAGES.projects.Milk,
      slug: 'milkorthodontics',
      testimonials: testimonials[9],
      titleColor: '#ffffff'
    }
  ],
  19: [
    {
      name: 'Joyable',
      year: 2019,
      tags: ['web service'],
      services: ['branding', 'user experience', 'design', 'API development', 'web development', 'support'],
      description: 'Joyable is the global hub for heartfelt friends and family funded collaborative gifting. Joyable elevates well-being for people and the planet by prioritising joy, celebration and connection.',
      shortDescription: 'Joyable is the global hub for heartfelt friends and family funded collaborative gifting.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://joyable.co'
        }
      ],
      gallery: IMAGES.projects.Joyable,
      slug: 'joyable',
      testimonials: testimonials[8],
      titleColor: '#ffffff'
    },
    {
      name: 'Foodprint',
      year: 2019,
      tags: ['iOS and Android application', 'website'],
      services: ['branding', 'user experience', 'design','iOS development', 'android development', 'API development', 'web development', 'support'],
      description: 'Foodprint connects users to eateries with surplus food available for purchase at 50% the normal price. Each day the eateries who have partnered with Foodprint upload surplus food items that will otherwise be thrown out. The food is still completely fine to eat and of the same quality as if you’d purchased directly from the store itself.',
      shortDescription: 'Foodprint helps you reduce food waste and enjoy delicious meals at a fraction of the price.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/nz/app/id1462596522?mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=com.foodprint'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://foodprint.app'
        }
      ],
      gallery: IMAGES.projects.Foodprint,
      slug: 'foodprint',
      testimonials: testimonials[7],
      titleColor: '#3D3D3D'
    },
    {
      name: 'StrongHer',
      year: 2019,
      tags: ['iOS and Android application', 'website'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'android development', 'API development', 'web development', 'support'],
      description: 'StrongHer is a fitness and nutrition tracking app for women that helps them plan their workout sessions in a systemized and disciplined manner while making sure that their nutritional requirements are taken care of.',
      shortDescription: 'StrongHer is laser-focused as a personal trainer customized to help users meet their fitness goals.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/app/strongher-woman/id1449792084?ls=1&mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.strongher'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://strongherapp.com'
        }
      ],
      gallery: IMAGES.projects.StrongHer,
      slug: 'strongher',
      testimonials: testimonials[6],
      titleColor: '#3D3D3D'
    },
    {
      name: 'Melissa Chalmers',
      year: 2019,
      tags: ['website'],
      services: ['branding', 'user experience', 'design', 'web development', 'support'],
      description: 'Melissa has a passion for helping people with their health and fitness goals. Through her mobile application StrongHer, she has reached thousands of people and inspired them to make better choices for a healthier lifestyle.',
      shortDescription: 'Melissa has a passion for helping people with their health and fitness goals.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://melissachalmers.app'
        }
      ],
      gallery: IMAGES.projects.MelissaChalmers,
      slug: 'melissachalmers',
      testimonials: testimonials[6],
      titleColor: '#ffffff'
    }
  ],
  18: [
    {
      name: 'Rogue Travel',
      year: 2018,
      tags: ['web service'],
      services: ['branding', 'user experience', 'design', 'API development', 'web development', 'support'],
      description: 'Rogue Travel is a web-based flight and hotel booking application. It is geared towards small and medium-sized ' +
        'businesses where users can conveniently book the logistics for their business trip. Despite the extremely complex task, ' +
        'we were able to solve it by creating a simple and easy-to-use UI.',
      shortDescription: 'Rogue Travel is a web-based flight and hotel booking application. It is geared towards small and medium-sized ' +
        'businesses where users can conveniently book the logistics for their business trip.',
      publicLinks: [],
      gallery: IMAGES.projects.RogueTravel,
      slug: 'rogue-travel',
      testimonials: testimonials[5],
      titleColor: '#3D3D3D'
    },
    {
      name: 'StarShipIT',
      year: 2018,
      tags: ['web service'],
      services: ['user experience', 'design', 'support'],
      description: 'StarShipIT, also known as ShipIT, is a nifty software that simplifies the process of delivering online and offline orders. ' +
        'Although the app is cloud-based, StarShipIT is headquartered in Australia and New Zealand, and has been specifically developed to service ' +
        'these markets. The task was to design a new UI for Orders and Dashboard.',
      shortDescription: 'StarShipIT, also known as ShipIT, is a nifty software that simplifies the process of delivering online and offline orders.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://app.starshipit.com'
        }
      ],
      slug: 'starshipit',
      gallery: IMAGES.projects.StarShipIT,
      testimonials: testimonials[2],
      titleColor: '#3D3D3D'
    },
    {
      name: 'Wolf and Fox',
      year: 2018,
      tags: ['website'],
      services: ['branding', 'user experience', 'design', 'API development', 'web development', 'support'],
      description: 'Wolf & Fox helps start-ups as well as established businesses that are looking for growth. ' +
        'The task was to design and develop a modern website which will describe what the company is about and the services it provides. ' +
        'We\'ve designed a stunning UI that represents the brand and justifies the quality of work it provides to their clients. ' +
        'The new website displays the upcoming workshops and allows clients to directly book a consultation.',
      shortDescription: 'Wolf & Fox helps start-ups as well as established businesses that are looking for growth.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://wolfandfox.co.nz'
        }
      ],
      slug: 'wolf-and-fox',
      gallery: IMAGES.projects.WolfAndFox,
      testimonials: testimonials[3],
      titleColor: '#ffffff'
    },
    {
      name: 'Goodnest Booking Process',
      year: 2018,
      tags: ['website'],
      services: ['user experience', 'design'],
      description: 'Goodnest is a handy booking platform that gives its users access to high-quality and local professionals. ' +
        'Our job was to completely rethink the job booking pages to make them more user-friendly. ' +
        'We have re-organised the booking process and job categories, then updated the UI. ' +
        'We also created a streamlined booking form which significantly decrease the time needed by the user to book a job.',
      shortDescription: 'Goodnest is a handy booking platform that gives its users access to high-quality and local professionals.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://www.goodnest.co.nz'
        }
      ],
      slug: 'goodnest-booking-process',
      gallery: IMAGES.projects.GoodnestBookingProcess,
      testimonials: testimonials[4],
      titleColor: '#ffffff'
    },
    {
      name: 'Quarry Trucks',
      year: 2018,
      tags: ['website'],
      services: ['user experience', 'design', 'web development'],
      description: 'Mindhive provides different tech solutions to various business models in New Zealand, such as laundry systems, ' +
        'railway projects, and silt control solutions. They recently developed a new ‘dashboard’ for New Zealand quarry. ' +
        'Due to this, the initial version has brought to light further requirements. Our task was to design and develop a customer ' +
        'dashboard which should be integrated with the existing backend. The dasboard was developed using React.',
      shortDescription: 'Mindhive provides different tech solutions to various business models in New Zealand, such as laundry systems, ' +
        'railway projects, and silt control solutions.',
      publicLinks: [],
      slug: 'quarry-trucks',
      gallery: IMAGES.projects.QuarryTrucks,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Goodnest Job Page',
      year: 2018,
      tags: ['website'],
      services: ['user experience', 'design'],
      description: 'Goodnest is an online marketplace that quickly links people who are seeking home services with local professionals. ' +
        'Our task was to make a page that allows any user, who may have never heard of Goodnest, to see details of a specific job and sign up ' +
        'immediately as a Professional. Users can be signed up and message the customer in a few minutes. ',
      shortDescription: 'Goodnest is an online marketplace that quickly links people who are seeking home services with local professionals.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://www.goodnest.co.nz'
        }
      ],
      slug: 'goodnest-job-page',
      gallery: IMAGES.projects.GoodnestJobPage,
      testimonials: testimonials[4],
      titleColor: '#ffffff'
    },
    {
      name: 'Open Seas',
      year: 2018,
      tags: ['website'],
      services: ['web development'],
      description: 'OpenSeas is an open-access online resource spearheaded by the New Zealand seafood industry. ' +
        'The purpose of OpenSeas is to provide businesses with a single, comprehensive source of information about the country\'s seafood industry. ' +
        'It contains information on environmental, social, and production regulations. We have developed a simple website using WordPress ' +
        'and deployed it to market in a very short period of time.',
      shortDescription: 'OpenSeas is an open-access online resource spearheaded by the New Zealand seafood industry.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://openseas.org.nz/'
        }
      ],
      slug: 'open-seas',
      gallery: IMAGES.projects.OpenSeas,
      testimonials: {},
      titleColor: '#3D3D3D'
    }
  ],
  17: [
    {
      name: 'LazyAz',
      year: 2017,
      tags: ['web service', 'iOS and Android application'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'android development', 'API development', 'web development', 'support'],
      description: 'LazyAz is an easy-to-use delivery service. ' +
        'Anything from food to fashion can be ordered and delivered in less than an hour. It lets users select a store and order an item ' +
        'in the store\'s database in a few quick steps. If a particular store isn\'t available in the system, ' +
        'users have the option to "Order something else" and type in the items that they want. The order can be tracked in real-time with this service.',
      shortDescription: 'LazyAz is an easy-to-use delivery service which can be accessed through Android, iOS and on the web.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/nz/app/lazyaz/id1050910395?mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=com.pikes.lazyaz'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://lazyaz.co.nz'
        }
      ],
      slug: 'lazyaz',
      gallery: IMAGES.projects.LazyAz,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Goodnest Professional',
      year: 2017,
      tags: ['iOS and Android application'],
      services: ['user experience', 'design'],
      description: 'Goodnest is an online marketplace that quickly links people who are seeking home services with local professionals. ' +
        'Our job was to turn the wireframes/existing-screens into a world-class design. Despite the constraint that the UX and flow ' +
        'has been decided on through hours and hours of contentious discussion, we were able to deliver the project to the client\'s satisfaction.',
      shortDescription: 'Goodnest is an online marketplace that quickly links people who are seeking home services with local professionals.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/nz/app/goodnest-professional/id1276898350?mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.goodnest.goodnestworker&hl=en'
        }
      ],
      slug: 'goodnest-professional',
      gallery: IMAGES.projects.GoodnestProfessional,
      testimonials: testimonials[4],
      titleColor: '#ffffff'
    },
    {
      name: 'Shared Space',
      year: 2017,
      tags: ['website'],
      services: ['branding', 'user experience', 'design'],
      description: 'Sharedspace is New Zealand’s largest shared space website. The task was to completely redesign the website ' +
        'and refresh the brand identity. In order to take the new website to the next level, we have refreshed the brand and designed a ' +
        'completely new UX and UI. This gave Sharedspace a more modern, clean, and intuitive look.',
      shortDescription: 'Sharedspace is New Zealand’s largest shared space website.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://www.sharedspace.co.nz'
        }
      ],
      slug: 'shared-space',
      gallery: IMAGES.projects.SharedSpace,
      testimonials: testimonials[1],
      titleColor: '#ffffff'
    },
    {
      name: '12 Saffron',
      year: 2017,
      tags: ['website'],
      services: ['branding', 'user experience', 'design', 'web development'],
      description: '12 Saffron is a beautiful and cozy house which was to be sold privately. Our job was to design and develop a website ' +
        'for the house in order to showcase it better and find potential buyers. The website was developed using React and hosted on AWS. ',
      shortDescription: '12 Saffron is a beautiful and cozy house which was to be sold privately.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://12saffron.co.nz'
        }
      ],
      slug: '12-saffron',
      gallery: IMAGES.projects.Saffron12,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Grabaseat',
      year: 2017,
      tags: ['iOS and Android application'],
      services: ['design', 'iOS development', 'android development', 'support'],
      description: 'Grabaseat gives users access to the cheapest domestic and international flights provided by Air New Zealand. ' +
        'Our task was to support iOS and Android applications, implement new features, and resolve issues that customers are reporting. ' +
        'We are currently working closely with Air New Zealand Grabaseat product team.',
      shortDescription: 'Grabaseat gives users access to the cheapest domestic and international flights provided by Air New Zealand.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/nz/app/grabaseat/id483277405?mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=com.airnewzealand.android.grabaseat&hl=en'
        }
      ],
      slug: 'grabaseat',
      gallery: IMAGES.projects.Grabaseat,
      testimonials: testimonials[0],
      titleColor: '#ffffff'
    }
  ],
  16: [
    {
      name: 'Hoist',
      year: 2016,
      tags: ['iOS and Android application'],
      services: ['user experience', 'design'],
      description: 'Hoist is a toolbox management app that allows users to manage, lend, and do an online inventory of their tools. ' +
        'This app is geared towards handymen, carpenters, plumbers, electricians, and more. Users can add a picture, list details, ' +
        'and have a universal reminder/scheduler for things like electrical inspections. Our task was to design User Experience and User Interface.',
      shortDescription: 'Hoist is a toolbox management app that allows users to manage, lend, and do an online inventory of their tools.',
      publicLinks: [],
      slug: 'hoist',
      gallery: IMAGES.projects.Hoist,
      testimonials: testimonials[4],
      titleColor: '#3D3D3D'
    },
    {
      name: 'Zoomy',
      year: 2016,
      tags: ['iOS and Android application'],
      services: ['user experience', 'design', 'iOS development', 'android development', 'API development', 'support'],
      description: 'Zoomy is New Zealand\'s own ridesharing service that is available in Auckland, Wellington, and Christchurch. ' +
        'The service connects users with friendly, local drivers at the tap of a button. Our task was to design an entirely new interface, ' +
        'provide technical support, implement new features, and fix bugs.',
      shortDescription: 'Zoomy is New Zealand\'s own ridesharing service that is available in Auckland, Wellington, and Christchurch.',
      publicLinks: [],
      slug: 'zoomy',
      gallery: IMAGES.projects.Zoomy,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Goodnest Mobile',
      year: 2016,
      tags: ['iOS and Android application'],
      services: ['design', 'iOS development', 'android development'],
      description: 'Goodnest is an online marketplace that quickly links people who are seeking home services with local professionals. ' +
        'Our task was to design and develop native applications for iOS and Android. The app should itergrate with Goodnest backend ' +
        'services and provide the most convenient way for a user to book and maintain jobs in Goodnest.',
      shortDescription: 'Goodnest is an online marketplace that quickly links people who are seeking home services with local professionals.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/nz/app/goodnest-book-trusted-nz-home/id1178449456?mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.goodnest.app&hl=en'
        }
      ],
      slug: 'goodnest-mobile',
      gallery: IMAGES.projects.Goodnest,
      testimonials: testimonials[4],
      titleColor: '#ffffff'
    },
    {
      name: 'Bubbly',
      year: 2016,
      tags: ['iOS application', 'Web service'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'API development', 'web development'],
      description: 'Bubbly is a service that lets people order alcohol from the nearest liquor store. We developed the entire service ' +
        'starting from branding and finishing with order processing and payment gateway integration. The app also allows users to track ' +
        'order delivery in real-time. The biggest challenge was to integrate with local stores POS systems to get stock updates in real-time.',
      shortDescription: 'Bubbly is a service that lets people order alcohol from the nearest liquor store.',
      publicLinks: [],
      slug: 'bubbly',
      gallery: IMAGES.projects.Bubbly,
      testimonials: {},
      titleColor: '#ffffff'
    }
  ],
  15: [
    {
      name: 'Super Shuttle',
      year: 2015,
      tags: ['web service', 'iOS and Android application'],
      services: ['user experience', 'design', 'iOS development', 'android development', 'API development', 'web development'],
      description: 'Super Shuttle service is the easiest and best way to book and manage Super Shuttle transfers around New Zealand. ' +
        'Our task was to develop a completely new website and mobile application for iOS and Android. We also integrated it into an ' +
        'internal booking system by developing a unique middle layer connecting applications and the website with Super Shuttle booking system.',
      shortDescription: 'Super Shuttle service is the easiest and best way to book and manage Super Shuttle transfers around New Zealand.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/nz/app/super-shuttle-nz/id1052541834?mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.supershuttle.app'
        },
        {
          linkName: WEBLINK,
          linkTo: 'https://www.supershuttle.co.nz'
        }
      ],
      slug: 'super-shuttle',
      gallery: IMAGES.projects.SuperShuttle,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Whip Around',
      year: 2015,
      tags: ['web service', 'iOS and Android application'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'android development', 'API development', 'web development', 'support'],
      description: 'Whip Around transforms paper-based vehicle inspection reports into mobile-friendly forms. Drivers can conduct their ' +
        'daily vehicle inspection reports (DVIR) on their smartphones via the mobile app. They can control what vehicles are inspected, ' +
        'add photos and comments, and automate time, date and GPS stamping. It also allows them to generate work orders when faults.',
      shortDescription: 'Whip Around transforms paper-based vehicle inspection reports into mobile-friendly forms.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'https://api.whip-around.com/dashboard'
        },
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/nz/app/whip-around-pre-trip-inspection/id1030219989?mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.co.whiparound.app&hl=en'
        }
      ],
      slug: 'whip-around',
      gallery: IMAGES.projects.WhipAround,
      testimonials: {},
      titleColor: '#3D3D3D'
    },
    {
      name: 'Raising Children',
      year: 2015,
      tags: ['website', 'iOS and Android application'],
      services: ['iOS development', 'android development', 'web development', 'support'],
      description: 'Raising Children is a user’s guide for parents, from the delivery suite to double digits – or as close to one as you’ll get. ' +
        'Divided into seven sections (Newborns, Infants, Crawlers, Toddlers, 2 Year Olds, Preschoolers and The Primary Years), ' +
        'the app has over 100 short video stories for parents to look at, with a succinct written summary and selection of top tips for each as well.',
      shortDescription: 'Raising Children is a user’s guide for parents, from the delivery suite to double digits – or as close to one as you’ll get.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/app/raising-children/id968639233?ls=1&mt=8'
        },
        {
          linkName: GOOGLE_PLAY,
          linkTo: 'https://play.google.com/store/apps/details?id=nz.org.raisingchildren.app'
        },
        {
          linkName: WEBLINK,
          linkTo: 'http://www.raisingchildren.org.nz/'
        }
      ],
      slug: 'raising-children',
      gallery: IMAGES.projects.RaisingChildren,
      testimonials: {},
      titleColor: '#ffffff'
    }
  ],
  14: [
    {
      name: 'Brightly',
      year: 2014,
      tags: ['iOS application'],
      services: ['iOS development'],
      description: 'Brightly is a helpful service for finding professionals using a simple request form. We developed an iOS application ' +
        'with an entirely custom UI and screen transitions.The user shall simply send a request through a free text message. ' +
        'Then the smart engine will extract keywords from the request, match them in the database, and connect the user with the right professional. ' +
        'The app is integrated with LinkedIn and the Brightly backend using REST API.',
      shortDescription: 'Brightly is a helpful service for finding professionals using a simple request form.',
      publicLinks: [],
      slug: 'brightly',
      gallery: IMAGES.projects.Brightly,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Day.ly',
      year: 2014,
      tags: ['iOS application'],
      services: ['iOS development'],
      description: 'Day.ly is an app inspired by the QuizUp app. This is a multiplayer trivia game where users can challenge each other ' +
        'and win real prizes every month. Users will compete with each other by answering quizzes every day and earning points ' +
        'for the correct answers. Every month there is a draw where users can win the current month\'s prize. ' +
        'The more points that the user earns per month, the more entries he has in the draw. ' +
        'We developed an iOS application with completely custom UI and screen transitions.',
      shortDescription: 'Day.ly is an app inspired by the QuizUp app. This is a multiplayer trivia game where users can ' +
        'challenge each other and win real prizes every month.',
      publicLinks: [],
      slug: 'dayly',
      gallery: IMAGES.projects.Dayly,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Must Visit NZ',
      year: 2014,
      tags: ['web service'],
      services: ['branding', 'user experience', 'design', 'web development', 'support'],
      description: 'Must Visit NZ is a website where tourists can book a tour around New Zealand\'s popular attractions. ' +
        'It\'s the perfect website for people who want to explore around the country through a safe and fun experience. ' +
        'We designed and developed the website to make it as stunning and intuitive as possible. ' +
        'We also integrated it with a payment gateway to ensure safe and secure transactions with the customers.',
      shortDescription: 'Must Visit NZ is a website where tourists can book a tour around New Zealand\'s popular attractions.',
      publicLinks: [
        {
          linkName: WEBLINK,
          linkTo: 'http://mustvisitnz.com/'
        }
      ],
      slug: 'must-visit-nz',
      gallery: IMAGES.projects.MVNZ,
      testimonials: {},
      titleColor: '#ffffff'
    },
    {
      name: 'Moving House',
      year: 2014,
      tags: ['Android application'],
      services: ['user experience', 'design', 'android development'],
      description: 'The Moving House Estimator Application is a helpful tool for people who are planning to move out of their house. ' +
        'It collects all the necessary data to estimate the user’s next moving costs. ' +
        'This app communicates with web API provided by the client to send data and user’s enquires.',
      shortDescription: 'The Moving House Estimator Application is a helpful tool for people who are planning to move out of their house.',
      publicLinks: [],
      slug: 'moving-house',
      gallery: IMAGES.projects.MoovingHouse,
      testimonials: {},
      titleColor: '#3D3D3D'
    }
  ],
  13: [
    {
      name: 'Offerbook',
      year: 2013,
      tags: ['iOS application'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'API development'],
      description: 'Offerbook is an easy-to-use location-based service to find deals around. ' +
        'It has helped people to discover and solicit the best offers and deals from nearby businesses. We have designed and built the entire product.',
      shortDescription: 'Offerbook is an easy-to-use location-based service to find deals around.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/us/app/discount-coupons-voucher-local/id789028068?mt=8'
        }
      ],
      slug: 'offerbook',
      gallery: IMAGES.projects.Offerbook,
      testimonials: {},
      titleColor: '#3D3D3D'
    },
    {
      name: 'Feed Time',
      year: 2013,
      tags: ['website', 'iOS application'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'web development', 'support'],
      description: 'Feedtime allows users to easily track, record, and monitor their baby’s feeding habits. ' +
        'This useful application helps young mothers to track feeding time, intervals, and duration by sending notifications. ' +
        'Mothers can also take note of their baby\'s weight and age through insightful statistics and graphs.',
      shortDescription: 'Feedtime allows users to easily track, record, and monitor their baby’s feeding habits.',
      publicLinks: [
        {
          linkName: APP_STORE,
          linkTo: 'https://itunes.apple.com/us/app/feed-time/id652576238?mt=8'
        },
        {
          linkName: WEBLINK,
          linkTo: 'http://feedtimeapp.com/'
        }
      ],
      slug: 'feed-time',
      gallery: IMAGES.projects.FeedTime,
      testimonials: {},
      titleColor: '#3D3D3D'
    },
    {
      name: 'Easy Words',
      year: 2013,
      tags: ['website', 'iOS application'],
      services: ['branding', 'user experience', 'design', 'iOS development', 'web development', 'support'],
      description: 'Easy Words is a helpful translation and language learning tool. It was created as a personal translator, ' +
        'custom dictionary, and multiple thematic word lists. This application also has built-in games that helps the users to learn and memorize new words.',
      shortDescription: 'Easy Words is a helpful translation and language learning tool. ' +
        'It was created as a personal translator, custom dictionary, and multiple thematic word lists.',
      publicLinks: [],
      slug: 'easy-words',
      gallery: IMAGES.projects.EasyWords,
      testimonials: {},
      titleColor: '#ffffff'
    }
  ]
}

// add unique ids to all projects
export default Object.assign({}, ...Object.keys(projects).map(key => ({ [key]: projects[key].map(item => Object.assign({}, item, { id: ID() })) })))
