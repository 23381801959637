/* eslint-disable max-len */
import React from 'react'

const AutomationIcon = props => (
  <svg width={28} height={28} viewBox='0 0 28 28' {...props}>
    <path
      fill='#FFF'
      d='M16 0c0 .477.055.953.156 1.477L14.188 3 16.5 7l2.29-.938a8.065 8.065 0 0 0 2.57 1.47L21.687 10h4.625l.328-2.469A8.713 8.713 0 0 0 28 6.875V4.406c-.71.64-1.555 1.14-2.516 1.383l-.648.164L24.563 8h-1.125l-.274-2.047-.648-.164a5.912 5.912 0 0 1-2.797-1.601l-.477-.485-1.89.781-.555-.968 1.633-1.258-.18-.649C18.078.992 18 .484 18 0h-2zm4 0c0 2.203 1.797 4 4 4 2.203 0 4-1.797 4-4h-2c0 1.102-.898 2-2 2-1.102 0-2-.898-2-2h-2zM8.586 6l-.32 2.453a8.853 8.853 0 0 0-3.407 2L2.531 9.5l-2.312 4 2.023 1.563A8.218 8.218 0 0 0 2 17c0 .617.078 1.258.242 1.938L.22 20.5l2.312 4 2.328-.96a8.888 8.888 0 0 0 3.407 2.015L8.586 28h4.625l.32-2.36a8.939 8.939 0 0 0 3.555-2.038l2.187.898 2.313-4-1.86-1.43A8.206 8.206 0 0 0 20 17c0-.664-.094-1.352-.281-2.07l1.86-1.43-2.313-4-2.188.898a8.834 8.834 0 0 0-3.555-2.039L13.211 6H8.586zm1.75 2h1.117l.266 2 .703.14c1.406.297 2.68 1.024 3.695 2.118l.477.508 1.812-.75.563.968-1.563 1.204.219.671c.25.782.375 1.485.375 2.141 0 .656-.125 1.36-.375 2.14l-.219.672 1.563 1.204-.555.968-1.82-.757-.477.515a6.9 6.9 0 0 1-3.695 2.11l-.695.148-.266 2h-1.125l-.274-2.047-.671-.156a6.924 6.924 0 0 1-3.547-2.11l-.477-.523-1.976.82-.563-.968 1.719-1.328-.203-.657C4.109 18.273 4 17.625 4 17c0-.625.11-1.273.344-2.031l.203-.656-1.719-1.329.563-.968 1.976.82.477-.524a6.978 6.978 0 0 1 3.547-2.109l.671-.156L10.337 8zM11 12c-2.758 0-5 2.242-5 5s2.242 5 5 5 5-2.242 5-5-2.242-5-5-5zm0 2a3 3 0 1 1-.002 6.002A3 3 0 0 1 11 14z'
    />
  </svg>
)

export default AutomationIcon
