import React from 'react'

const OvalIcon = props => (
  <svg width={4} height={4} viewBox='0 0 4 4' {...props}>
    <circle
      cx={2}
      cy={2}
      r={2}
      fill='#4A4A4A'
    />
  </svg>
)

export default OvalIcon
