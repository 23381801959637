import H1 from './H1'
import H2 from './H2'
import H3 from './H3'
import H4 from './H4'
import H5 from './H5'
import H5Bold from './H5Bold'
import H6 from './H6'
import View from './View'
import ViewHeader from './ViewHeader'
import P from './P'
import Caption from './Caption'
import TagSm from './TagSm'
import TagLg from './TagLg'

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H5Bold,
  H6,
  View,
  ViewHeader,
  P,
  Caption,
  TagSm,
  TagLg
}
