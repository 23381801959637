import PROJECT_LIST from '../content/projectList'
import PROCESS_LIST from '../content/processContent'
import SERVICES_LIST from '../content/servicesContent'
import { SHOWREEL, PRESENTATION } from './links'

const closestYear = Number(Object.keys(PROJECT_LIST).reverse()[0])

export function tagServices (string) {
  const base = string[0]
  const linkTo = SERVICES_LIST.stages[0].slug

  return `${base}${linkTo}`
}

export function tagProcess (string) {
  const base = string[0]
  const linkTo = PROCESS_LIST.stages[0].slug

  return `${base}${linkTo}`
}

export function tagProject (string) {
  const base = string[0]
  const linkTo = PROJECT_LIST[closestYear][0].slug

  return `${base}${linkTo}`
}

export const URLS = {
  approach: {
    id: 1,
    name: 'Approach',
    path: '/approach',
    routes: [
      {
        services: {
          name: 'Services',
          path: '/approach/services',
          routes: [...SERVICES_LIST.stages]
        }
      },
      {
        packages: {
          name: 'Packages',
          path: '/approach/packages'
        }
      },
      {
        process: {
          name: 'Process',
          path: '/approach/process',
          routes: [...PROCESS_LIST.stages]
        }
      },
      {
        technologies: {
          name: 'Technologies',
          path: '/approach/technologies'
        }
      }
    ]
  },
  portfolio: {
    id: 2,
    name: 'Portfolio',
    path: '/portfolio',
    routes: [
      {
        projects: {
          name: 'Projects',
          path: '/portfolio/projects',
          routes: [PROJECT_LIST[closestYear]]
        }
      },
      // {
      //   caseStudies: {
      //     name: 'Case Studies',
      //     path: '/portfolio/case-studies'
      //   }
      // },
      {
        clients: {
          name: 'Clients',
          path: '/portfolio/clients'
        }
      }
    ]
  },
  agency: {
    id: 3,
    name: 'Agency',
    path: '/agency',
    routes: [
      {
        company: {
          name: '7 glyphs',
          path: '/agency/company'
        }
      },
      {
        team: {
          name: 'Team',
          path: '/agency/team'
        }
      },
      {
        office: {
          name: 'Office',
          path: '/agency/office'
        }
      }
    ]
  },
  blog: {
    id: 4,
    name: 'Blog',
    path: '/blog'
  },
  getInTouch: {
    id: 5,
    name: 'Get in touch',
    path: '/get-in-touch',
    routes: [
      {
        visit: {
          name: 'Visit us',
          path: '/get-in-touch/visit'
        }
      },
      {
        brief: {
          name: 'Brief us',
          path: '/get-in-touch/brief'
        }
      },
      {
        join: {
          name: 'Join us',
          path: '/get-in-touch/join'
        }
      }
    ]
  }
}

export const FOOTER_URLS = [
  {
    id: 1,
    name: 'Home',
    links: [
      {
        name: 'homepage',
        linkTo: '/'
      },
      {
        name: 'showreel',
        linkTo: SHOWREEL,
        type: 'external'
      },
      {
        name: 'presentation',
        linkTo: PRESENTATION,
        type: 'external'
      }
    ]
  },
  {
    id: 2,
    name: 'Approach',
    links: [
      {
        name: 'services',
        linkTo: tagServices`/approach/services/`
      },
      {
        name: 'packages',
        linkTo: '/approach/packages'
      },
      {
        name: 'process',
        linkTo: tagProcess`/approach/process/`
      },
      {
        name: 'technologies',
        linkTo: '/approach/technologies'
      }
    ]
  },
  {
    id: 3,
    name: 'Portfolio',
    links: [
      {
        name: 'projects',
        linkTo: tagProject`/portfolio/projects/`
      },
      // {
      //   name: 'case studies',
      //   linkTo: '/portfolio/case-studies'
      // },
      {
        name: 'clients',
        linkTo: '/portfolio/clients'
      }
    ]
  },
  {
    id: 4,
    name: 'Agency',
    links: [
      {
        name: '7 glyphs',
        linkTo: '/agency/company'
      },
      {
        name: 'team',
        linkTo: '/agency/team'
      },
      {
        name: 'office',
        linkTo: '/agency/office'
      }
    ]
  },
  {
    id: 5,
    name: 'Blog',
    links: [
      {
        name: 'blog',
        linkTo: '/blog'
      },
      {
        name: 'last post',
        linkTo: '/blog'
      }
    ]
  },
  {
    id: 6,
    name: 'Get in touch',
    path: '/get-in-touch',
    links: [
      {
        name: 'brief us',
        linkTo: '/get-in-touch/brief'
      },
      {
        name: 'visit us',
        linkTo: '/get-in-touch/visit'
      },
      {
        name: 'join us',
        linkTo: '/get-in-touch/join'
      }
    ]
  }
]

const HOME_LINK = {
  home: {
    id: 0,
    name: 'Home',
    path: '/'
  }
}

export const MOBILE_URLS = Object.assign({}, HOME_LINK, URLS)
