import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Slider from 'rc-slider'
import ScrollAnimation from '../../_common/ScrollAnimation'
import ResponsiveBlock from '../../_common/ResponsiveBlock/ResponsiveBlock'
import './style.css'

class DesignSlider extends Component {
  state = {
    value: 50,
    projectIndex: 0,
    imageWidth: 0,
    imageHeight: 0
  }

  screenHeight = 0

  static propTypes = {
    heightRatio: PropTypes.number,
    presentationType: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.shape({}))
  }

  static defaultProps = {
    heightRatio: 100
  }

  componentDidMount () {
    const { presentationType } = this.props
    presentationType === 'web' && this.handleSizesSet()
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { screenSize } = this.props
    const { width } = screenSize
    if (width !== nextProps.screenSize.width) this.handleSizesSet()
  }

  handleRadioChange = (e) => {
    this.setState({ projectIndex: e.target.value })
  }

  handleSliderChange = (value) => {
    this.setState({ value })
  }

  handleSizesSet = () => {
    const { presentationType } = this.props
    if (presentationType === 'web') {
      const { image } = this
      this.setState({
        imageWidth: image ? image.clientWidth : 0,
        imageHeight: image ? image.clientHeight : 0
      })
    }
  }

  render () {
    const { heightRatio, presentationType, slides } = this.props
    const {
      value, projectIndex, imageWidth, imageHeight
    } = this.state
    const imageStyle = {
      width: `${value}%`
    }
    if (presentationType === 'web') {
      imageStyle.backgroundSize = `${imageWidth}px ${imageHeight - 40}px`
    }
    return (
      <div className={`service-slider service-slider_${presentationType}`}>
        <ScrollAnimation effect='fadeIn'>
          <ResponsiveBlock {...{ heightRatio }}>
            <ul className='service-slider__list'>
              {[...Array(slides.length)].map((slide, index) => (
                <li className='switcher' key={index}>
                  <div
                    className={`switcher__icon switcher__icon ${index === Number(projectIndex) ? 'switcher__icon_checked' : ''}`}
                  >
                    <input
                      onChange={this.handleRadioChange}
                      type='radio'
                      checked={index === Number(projectIndex)}
                      value={index}
                    />
                  </div>
                </li>))}
            </ul>
            {slides &&
            (
              <div
                style={{ backgroundImage: `url(${slides[projectIndex].ui})` }}
                className={`service-slider__image service-slider__image_${presentationType}`}
                ref={ref => (this.image = ref)}
              />)}
            <div
              className={`service-slider__image service-slider__image_${presentationType} service-slider__image_front`}
              style={Object.assign({}, imageStyle, { backgroundImage: `url(${slides[projectIndex].ux})` })}
            />
            <Slider
              {...{ value }}
              step={5}
              dots
              onChange={this.handleSliderChange}
            />
          </ResponsiveBlock>
        </ScrollAnimation>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  screenSize: state.screenSize
})

export default connect(mapStateToProps, null)(DesignSlider)
