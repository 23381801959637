import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-id-swiper'
import ScrollAnimation from '../../_common/ScrollAnimation'
import Reason from './Reason/Reason'
import { ViewHeader } from '../../typography'
import './style.css'

ReasonsSlider.propTypes = {
  reasons: PropTypes.arrayOf(PropTypes.shape({})),
  shouldStartAnimation: PropTypes.bool
}

function ReasonsSlider (props) {
  const { reasons, shouldStartAnimation } = props
  const sliderSettings = {
    pagination: {
      el: '.swiper-pagination',
      bulletActiveClass: 'swiper-pagination-bullet-active',
      renderBullet (index, className) {
        const num = index < 9 ? `0${index + 1}` : `${index + 1}`
        return `<span class="${className}">${num}</span>`
      },
      clickable: true
    },
    slidesPerView: 'auto',
    centeredSlides: false,
    loop: true,
    speed: 1200,
    autoplay: shouldStartAnimation
      ? { delay: 3000, disableOnInteraction: false }
      : false,
    rebuildOnUpdate: true
  }
  return (
    <div className='reasons-slider'>
      <ScrollAnimation effect='fadeInBigBottom'>
        <ViewHeader className='reasons-title'>{reasons.length} reasons to choose us</ViewHeader>
      </ScrollAnimation>
      <ScrollAnimation className='slider-wrapper' effect='fadeInRight' delay={200}>
        <div className='slider-item'>
          <Slider {...sliderSettings}>
            {reasons.map(reason => (
              <div key={reason.id}>
                <Reason {...reason} />
              </div>
            ))}
          </Slider>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default ReasonsSlider
