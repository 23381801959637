import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.shape({}),
    children: PropTypes.shape({})
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { location } = this.props
    if (location !== prevProps.location && !(location.state && location.state.restorePosition)) {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  render () {
    const { children } = this.props
    return children
  }
}

export default withRouter(ScrollToTop)
