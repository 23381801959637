/* eslint-disable max-len */
import React from 'react'

const PieChartIcon = props => (
  <svg width={26} height={26} {...props} viewBox='0 0 26 26'>
    <path
      fill='#FFF'
      d='M13 0C5.836 0 0 5.836 0 13s5.836 13 13 13 13-5.836 13-13S20.164 0 13 0zm-1 2.047v11.367l8.04 8.04A10.972 10.972 0 0 1 13 24C6.914 24 2 19.086 2 13 2 7.25 6.383 2.555 12 2.047zm2 0A10.98 10.98 0 0 1 23.945 12H14V2.047zM15.414 14h8.531a10.896 10.896 0 0 1-2.492 6.04L15.414 14z'
    />
  </svg>
)

export default PieChartIcon
