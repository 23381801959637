import React from 'react'

const style = {
  height: '28px',
  width: '18px',
  marginRight: '10px'
}

const DropDownIcon = props => (
  <svg width='100%' height='100%' viewBox='0 0 32 32' {...props} style={style}>
    <path
      d='M24.285 11.284L16 19.571l-8.285-8.288a1.01 1.01 0 1 0-1.429 1.43l8.999 9.002a1.009 1.009 0 0 0 1.428 0l8.999-9.002a1.01 1.01 0 1 0-1.427-1.429z'
      fill='#4a4a4a'
    />
  </svg>
)

export default DropDownIcon
