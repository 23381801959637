import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const H4 = ({ children, className }) => <h4 className={cn('H4', className)}>{children}</h4>

H4.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default H4
