/* eslint-disable max-len */
import React from 'react'

const UIRefinementsIcon = props => (
  <svg width={23} height={23} viewBox='0 0 23 23' {...props}>
    <path
      fill='#FFF'
      d='M11.289 0a.867.867 0 0 0-.836.572l-1.696 4.71-5.708-3.427a.867.867 0 0 0-.413-.121.867.867 0 0 0-.779 1.313l3.426 5.708L.574 10.45a.867.867 0 0 0 0 1.632l4.71 1.696-3.427 5.708a.867.867 0 0 0 1.192 1.191l5.708-3.426 1.696 4.71a.867.867 0 0 0 1.632 0l1.696-4.71 5.708 3.426a.867.867 0 0 0 1.191-1.191l-3.426-5.708 4.71-1.696a.867.867 0 0 0 0-1.632l-4.71-1.696 3.426-5.708a.867.867 0 0 0-1.191-1.192L13.78 5.282 12.085.572A.867.867 0 0 0 11.289 0zm-.02 3.426l.995 2.766-.995.596-.996-.596.996-2.766zM5.134 5.133l5.688 3.412a.867.867 0 0 0 .894 0l5.687-3.412-3.412 5.687a.867.867 0 0 0 0 .894l3.412 5.687-5.687-3.412a.867.867 0 0 0-.894 0L5.134 17.4l3.413-5.687a.867.867 0 0 0 0-.894L5.134 5.133zm1.06 5.139l.596.995-.596.995-2.766-.995 2.766-.995zm10.15 0l2.765.995-2.766.995-.595-.995.595-.995zm-5.075 5.474l.995.596-.995 2.766-.996-2.766.996-.596z'
    />
  </svg>
)

export default UIRefinementsIcon
