/* eslint-disable max-len */
import React from 'react'

const InfrastructureIcon = props => (
  <svg width={26} height={28} viewBox='0 0 26 28' {...props}>
    <path
      fill='#FFF'
      d='M13 0a3.01 3.01 0 0 0-3 3c0 1.297.844 2.398 2 2.813V8H9a3.03 3.03 0 0 0-1.695.531l-1.43-.719C5.945 7.556 6 7.282 6 7c0-1.648-1.352-3-3-3a3.01 3.01 0 0 0-3 3c0 1.648 1.352 3 3 3a3.03 3.03 0 0 0 1.695-.531l1.43.719c-.07.257-.125.53-.125.812v6c0 .297.063.578.14.844-.234.125-.25.14-.5.273-.327.164-.617.305-.906.445A2.967 2.967 0 0 0 3 18a3.01 3.01 0 0 0-3 3c0 1.648 1.352 3 3 3s3-1.352 3-3c0-.273-.047-.531-.117-.781.226-.11.43-.211.664-.336.398-.203.453-.235.781-.406A2.947 2.947 0 0 0 9 20h3v2.188c-1.156.414-2 1.515-2 2.812 0 1.648 1.352 3 3 3s3-1.352 3-3c0-1.297-.844-2.398-2-2.813V20h3a3.03 3.03 0 0 0 1.695-.531l1.43.718c-.07.258-.125.532-.125.813 0 1.648 1.352 3 3 3s3-1.352 3-3-1.352-3-3-3a3.03 3.03 0 0 0-1.695.531l-1.43-.718c.07-.258.125-.532.125-.813v-6c0-.281-.055-.555-.125-.813l1.43-.718c.484.328 1.07.531 1.695.531 1.648 0 3-1.352 3-3s-1.352-3-3-3a3.01 3.01 0 0 0-3 3c0 .281.055.555.125.813l-1.43.718A3.028 3.028 0 0 0 17 8h-3V5.812c1.156-.414 2-1.515 2-2.812 0-1.648-1.352-3-3-3zm0 2c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1zM3 6c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1zm20 0c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1zM9 10h8c.563 0 1 .438 1 1v6c0 .563-.438 1-1 1H9c-.563 0-1-.438-1-1v-6c0-.563.438-1 1-1zm2 2a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4zm-8 8c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1zm20 0c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1zm-10 4c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1z'
    />
  </svg>
)

export default InfrastructureIcon
