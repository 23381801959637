import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const TagLg = ({ children, className }) => <p className={cn('TagLg', className)}>{children}</p>

TagLg.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default TagLg
