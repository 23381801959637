import React, { Component } from 'react'
import Slider from 'react-id-swiper'
import ScrollAnimation from '../../_common/ScrollAnimation'
import testimonials from '../../../content/testimonials'
import Picture from '../../_common/Picture'
import { ViewHeader } from '../../typography'
import './style.css'

class Testimonials extends Component {
  state = {
    slideNum: 1
  }

  handleSlideChange = () => {
    this.slider && this.setState({ slideNum: this.slider.realIndex })
    this.slider && this.slider.slideReset(700)
  }

  handleNextBtnClick = () => {
    this.slider && this.slider.slideNext()
  }

  render () {
    const { slideNum } = this.state
    const sliderSettings = {
      slidesPerView: 'auto',
      initialSlide: 1,
      centeredSlides: false,
      slideToClickedSlide: true,
      grabCursor: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 1000,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3
      },
      on: {
        slideChange: () => this.handleSlideChange()
      },
      getSwiper: (sw) => { this.slider = sw }
    }
    return (
      <div className='packages-testimonials'>
        <ScrollAnimation effect='fadeInBigBottom'>
          <ViewHeader className='packages-testimonials__title'>Testimonials</ViewHeader>
        </ScrollAnimation>
        <ScrollAnimation className='slider-wrapper' effect='fadeInRight' delay={200}>
          <div className='slider-item testimonial-slide'>
            <div className='testimonial-slide__avatar-wrapper'>
              <Picture
                className='testimonial-slide__avatar'
                {...testimonials[slideNum].avatar}
                alt={testimonials[slideNum].avatar.alt}
              />
            </div>
            <div className='testimonial-slide__body'>
              <Slider {...sliderSettings}>
                {testimonials.map(({
                  avatar, text, author, authorPosition, companyDescription
                }) => (
                  <div key={author}>
                    <div className='testimonial-slide__text-block stripe_top'>
                      <div className='text'>{text}</div>
                      <div className='text-info-wrapper'>
                        <p className='text-info author-info'>{`${author}, ${authorPosition}`}</p>
                        <p className='text-info company-info'>{companyDescription}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <button type='button' role='tab' tabIndex={0} aria-selected className='next-button' onClick={this.handleNextBtnClick} />
          </div>
        </ScrollAnimation>
      </div>
    )
  }
}

export default Testimonials
