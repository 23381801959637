import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

ContactLink.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
}

function ContactLink (props) {
  let link
  const { type, value, className } = props
  switch (type) {
    case 'email':
      link = `mailto:${value}`
      break
    case 'telephone':
      link = `tel:${value.replace(/\s/g, '')}`
      break
    case 'address':
      link = value
      break
    default:
  }
  return (
    type === 'address'
      ? (<p className={className || 'contact-link'}>{link}</p>)
      : (<a itemProp={type} href={link} className={className || 'contact-link focus focus_full'}>{value}</a>)
  )
}

export default ContactLink
