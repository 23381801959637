import IMAGES from '../utils/imagesProvider'

export default {
  stages: [
    {
      number: '01',
      name: 'User Experience',
      text: 'A good product allows users to meet their needs in the simplest and smoothest process. We make sure that the UX is excellent, so customers have a fuss-free experience with the product. We wireframe all unique screens showing the functionality, controls, and screen layout. We approve, test, and plan behavior scenarios to create the best view which works for the clients and its users.',
      filterLinks: ['Competitors Analysis', 'Research', 'Trends', 'Brainstorming', 'Features & Improvements'],
      imgSet: IMAGES.services.stage1,
      slug: 'user_experience',
      titleColor: 'rgba(74, 74, 74, 0.3)'
    },
    {
      number: '02',
      name: 'Development',
      text: 'Using the right technologies to achieve the best results, we write the code for each platform, considering platform restraints. We have API integration, versioning support, and multiple environment support available for the client to test on commit to the repository. The code is checked by security professionals so all data is protected and raised security concerns are fixed.',
      filterLinks: ['Best practices', 'Functional', 'Easy to maintain', 'Scalable', 'Integration'],
      imgSet: IMAGES.services.stage2,
      slug: 'development',
      titleColor: 'rgba(74, 74, 74, 0.3)'
    },
    {
      number: '03',
      name: 'Design',
      text: 'We consider client requirements and follow platform-specific design guidelines to create the right application vibe. We design UI based on actual animations and define the overall look, color palette, imagery styles, fonts, text styles, and iconography that give a feeling of a modern and light application flow. Our team uses tools to create assets for each screen resolution, then test and present the design outcome on an actual device with full design documentation.',
      filterLinks: ['Platform specific', 'Clean', 'Accessibility', 'Unique', 'Beautiful', 'Responsive'],
      imgSet: IMAGES.services.stage3,
      slug: 'design',
      titleColor: 'rgba(74, 74, 74, 0.3)'
    },
    {
      number: '04',
      name: 'Branding',
      text: 'By creating a unique UI using your existing brand book or a new one created for you by our team, we aim to refresh your business’s identity. Through this, customers can easily distinguish and remember your business.',
      filterLinks: ['Unique', 'Brand book', 'Easy to remember', 'Logotype', 'Font', 'Promo materials'],
      imgSet: IMAGES.services.stage4,
      slug: 'branding',
      titleColor: '#ffffff'
    },
    {
      number: '05',
      name: 'Support',
      text: 'Aside from designing and developing apps and web solutions, we also provide reactive support to prevent or fix bugs in real-time. We monitor the app by using bug tracking and prepare reports every month .\nAt 7 glyphs, we don’t just design and build – we also offer technical support for your product. You can choose between 10, 20, or 40+ hours of monthly support in addition to your product development package.',
      filterLinks: ['Code updates', 'SLA', 'Monitoring', 'Bug and Crash tracking', 'Dependency updates', 'Security updates', 'Monthly reports'],
      imgSet: IMAGES.services.stage5,
      slug: 'support',
      titleColor: 'rgba(74, 74, 74, 0.3)'
    },
    {
      number: '06',
      name: 'Marketing',
      text: 'We employ the most effective digital marketing strategies to promote your business through different platforms. We monitor your target market views so we can devise a strategy on how to gain new customers and keep your existing customers satisfied.',
      filterLinks: ['Google Ads', 'Facebook', 'Instagram', 'Youtube', 'LinkedIn'],
      imgSet: IMAGES.services.stage6,
      slug: 'marketing',
      titleColor: '#ffffff'
    },
    {
      number: '07',
      name: 'Automation Testing',
      text: 'Testing the application is crucial before deployment to ensure it passes quality and validation tests. We write unit tests, run integration tests, and UI tests to guarantee successful product delivery. We automate as many tests as we can to make sure the new code runs smoothly without any interruptions. We write test cases and test products against them before every release.',
      filterLinks: ['Unit testing', 'Integration testing', 'UI Testing', 'Automation', 'Continuous delivery', 'Continuous integration'],
      imgSet: IMAGES.services.stage7,
      slug: 'automation_testing',
      titleColor: '#ffffff'
    }
  ]
}
