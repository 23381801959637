import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import LogoIcon from '../../_icons/LogoIcon'
import { MOBILE_URLS } from '../../../constants/urls'
import NavigationLink from '../NavigationLink/NavigationLink'
import MuiButton from '../../_common/MuiButton/MuiButton'
import FOOTER from '../../../content/footerContent'
import './style.css'

class MobileNavigation extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }

  state = {
    activeLinkId: 0,
    navOpen: false,
    colorTheme: 'light',
    borderShadow: false
  }

  DARK = 'dark'

  LIGHT = 'light'

  latestKnownScroll = 0

  targetElement = null

  componentDidMount () {
    const { location } = this.props
    const { pathname } = location
    const isHomePage = pathname === '/'
    this.setState({ colorTheme: isHomePage ? this.LIGHT : this.DARK })
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    this.targetElement = document.querySelector('#targetElementId')
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.latestKnownScrollY = window.scrollY
    this.toggleBorderStyle()
  }

  toggleBorderStyle = () => {
    const { borderShadow, navOpen } = this.state
    const { location } = this.props
    const { pathname } = location
    const isHomePage = pathname === '/'
    const HEADER_HEIGHT = 5
    if (isHomePage) this.setState({ borderShadow: false })
    if (this.latestKnownScrollY > HEADER_HEIGHT && !borderShadow && !isHomePage && !navOpen) this.setState({ borderShadow: true })
    if (this.latestKnownScrollY < HEADER_HEIGHT && borderShadow && !isHomePage) this.setState({ borderShadow: false })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { navOpen } = this.state
    const { location } = this.props
    const { pathname } = location
    if (prevProps.location.pathname !== pathname) {
      this.handleMenuClose()
    }
    if (prevProps.location.pathname !== pathname && pathname === '/') {
      this.toggleNavigationColorTheme(this.LIGHT)
      this.toggleBorderStyle()
    }
    if (prevProps.location.pathname !== pathname && prevProps.location.pathname === '/') {
      this.toggleNavigationColorTheme(this.DARK)
    }
    if (prevState.navOpen !== navOpen) {
      this.toggleBorderStyle()
    }
  }

  toggleNavigationColorTheme = (theme) => {
    this.setState({ colorTheme: theme })
  }

  handleActiveLinkIdChange = id => this.setState({ activeLinkId: id })

  handleMenuOpen = () => {
    this.setState({ navOpen: true })
    disableBodyScroll(this.targetElement)
  }

  handleMenuClose = () => {
    this.setState({ navOpen: false })
    enableBodyScroll(this.targetElement)
  }

  render () {
    const {
      activeLinkId, navOpen, colorTheme, borderShadow
    } = this.state
    const { contacts } = FOOTER
    return (
      <div className='header-mobile__wrapper'>
        <div
          className={
            `header-mobile theme__${colorTheme}
             navigation-panel__${navOpen ? 'expanded' : 'hidden'}
             ${borderShadow ? 'shadowed' : 'no'}-border`
          }
        >
          <div className='header-mobile__header'>
            <Link to='/' className='logo-nav-btn btn-left'>
              <LogoIcon className='header-mobile__header-logo' darkTheme={colorTheme === this.LIGHT} />
              <div className='header-mobile__header-title'>7 glyphs</div>
            </Link>
            <button
              type='button'
              className='logo-nav-btn btn-right'
              onClick={this.handleMenuOpen}
            >
              <LogoIcon className='header-mobile__header-logo logo-collapsed' darkTheme={colorTheme === this.LIGHT} />
            </button>
          </div>
        </div>
        <CSSTransition
          in={navOpen}
          addEndListener={() => {}}
          classNames='drawer'
          id='targetElementId'
        >
          <div className='drawer__wrapper'>
            <div className='header-mobile__header drawer-header'>
              <button
                type='button'
                className='logo-nav-btn btn-left'
                onClick={this.handleMenuClose}
              >
                <LogoIcon className={`header-mobile__header-logo logo-${navOpen ? 'collapsed' : 'expanded'}`} />
              </button>
              <button
                type='button'
                className='logo-nav-btn btn-right'
              >
                <LogoIcon className='header-mobile__header-logo' />
                <div className='header-mobile__header-title'>7 glyphs</div>
              </button>
            </div>
            <div className='drawer__body-container'>
              <nav className='drawer__body'>
                <ul>
                  {Object.keys(MOBILE_URLS).map(route => (
                    <NavigationLink
                      key={MOBILE_URLS[route].path}
                      {...MOBILE_URLS[route]}
                      activeLinkId={activeLinkId}
                      onActiveLinkIdChange={this.handleActiveLinkIdChange}
                    />
                  ))}
                </ul>
              </nav>
              <Link
                to='/get-in-touch/brief'
                className='mobile-navigation__btn'
                id='sidebar__nav__btn'
              >
                <MuiButton kind='black' fullWidth>
                  Brief us
                </MuiButton>
              </Link>
              <div className='address-col'>
                <div className='footer__menu-col-content'>
                  <a itemProp='email' href={`mailto:${contacts.email}`}>
                    {contacts.email}
                  </a>
                  <div>{contacts.address}</div>
                  <div className='social-links'>
                    {
                      contacts.socials.map(({ icon, alt, linkTo }) => (
                        <a href={linkTo} target='_blank' rel='noopener noreferrer' key={alt}><img src={icon} alt={alt} /></a>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default withRouter(MobileNavigation)
