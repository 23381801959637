/* eslint-disable max-len */
import React from 'react'

const MonitoringIcon = props => (
  <svg width={26} height={22} viewBox='0 0 26 22' {...props}>
    <path
      fill='#FFF'
      d='M2.786 0C1.258 0 0 1.242 0 2.75v12.833c0 1.508 1.258 2.75 2.786 2.75h3.82C5.964 19.416 5.57 20.66 5.57 22h1.86c0-3.048 2.483-5.5 5.571-5.5 3.088 0 5.571 2.452 5.571 5.5h1.858c0-1.34-.394-2.584-1.034-3.667h3.82c1.527 0 2.785-1.242 2.785-2.75V2.75C26 1.242 24.742 0 23.214 0H2.786zm0 1.833h20.428a.91.91 0 0 1 .929.917v12.833a.91.91 0 0 1-.929.917h-5.357a7.436 7.436 0 0 0-1.603-1.06c1.395-1.002 2.317-2.615 2.317-4.44 0-3.027-2.505-5.5-5.571-5.5S7.429 7.973 7.429 11c0 1.825.922 3.438 2.317 4.44a7.436 7.436 0 0 0-1.603 1.06H2.786a.91.91 0 0 1-.929-.917V2.75a.91.91 0 0 1 .929-.917zM13 7.333c2.062 0 3.714 1.631 3.714 3.667 0 2.036-1.652 3.667-3.714 3.667-2.062 0-3.714-1.631-3.714-3.667 0-2.036 1.652-3.667 3.714-3.667z'
    />
  </svg>
)

export default MonitoringIcon
