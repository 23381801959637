/* eslint-disable max-len */
import React from 'react'

const PresentationIcon = props => (
  <svg width={26} height={26} viewBox='0 0 26 26' {...props}>
    <path
      fill='#FFF'
      d='M0 0v2h26V0H0zm0 4v18h12v4h2v-4h12V4H0zm2 2h22v14H2V6zm6 2c-2.195 0-4 1.805-4 4 0 2.195 1.805 4 4 4 2.195 0 4-1.805 4-4 0-2.195-1.805-4-4-4zm0 2v2h2c0 1.117-.883 2-2 2s-2-.883-2-2 .883-2 2-2zm6 0v2h8v-2h-8zm0 4v2h6v-2h-6z'
    />
  </svg>
)

export default PresentationIcon
