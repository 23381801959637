import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { Link, withRouter } from 'react-router-dom'
import { LogoIcon } from '../../_icons/index'
import NavigationLink from '../NavigationLink/NavigationLink'
import MuiButton from '../../_common/MuiButton/MuiButton'
import { URLS } from '../../../constants/urls'
import { setSidebarTheme } from '../../../redux/reducer'
import { TABLET_VIEW } from '../../../constants/dimentions'
import './style.css'

class DesktopNavigation extends PureComponent {
  static propTypes = {
    isNavBody: PropTypes.bool.isRequired,
    isLogoBody: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    isDarkTheme: PropTypes.bool.isRequired,
    updateSidebarTheme: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }

  state = {
    activeLinkId: 0,
    mounted: false
  }

  globals = {
    evtListener: false,
    lastScrollY: 0,
    ticking: false,
    mounted: false,
    screenHeight: 0
  }

  LIGHT = 'light'

  DARK = 'dark'

  componentDidMount () {
    const { location } = this.props
    const homePage = location.pathname === '/'
    this.updateActiveLinkState()
    this.checkTheme()
    this.setScreenHeight()

    if (homePage) {
      this.setDesktopScrollListener()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { location, updateSidebarTheme } = this.props
    const homePage = location.pathname === '/'

    if (prevProps.location.pathname !== location.pathname) {
      this.updateActiveLinkState()
      if (homePage) {
        updateSidebarTheme(true)
        this.updateActiveLinkState()
        this.checkTheme()
        this.setDesktopScrollListener()
        this.requestTick()
      } else {
        updateSidebarTheme(false)
      }
    }
    if (prevProps.location.pathname !== location.pathname && prevProps.location.pathname === '/') {
      this.globals.evtListener = false
      window.removeEventListener('scroll', this.onScroll)
      this.cleanPollutedTheme()
      updateSidebarTheme(false)
    }
    this.setScreenHeight()
  }

  setScreenHeight = () => {
    this.globals.screenHeight = window.innerHeight
  }

  setDesktopScrollListener = () => {
    const { width } = this.props
    if (width > TABLET_VIEW) {
      window.addEventListener('scroll', this.onScroll, { passive: true })
      this.globals.evtListener = true
    } else {
      window.removeEventListener('scroll', this.onScroll)
      this.globals.evtListener = false
    }
  }

  onScroll = () => {
    this.globals.lastScrollY = window.scrollY
    window.scrollY <= this.globals.screenHeight && this.requestTick()
  }

  requestTick = () => {
    if (!this.globals.ticking) {
      requestAnimationFrame(this.handleSidebarTheme)
      this.globals.ticking = true
    }
  }

  DOM = () => {
    const homeBannerSection = document.getElementById('home-banner-section')
    const highLevelLinks = document.getElementsByClassName('navigation-link__href')
    const subLevelLinks = document.getElementsByClassName('navigation-sub-link__href')
    const logo = document.getElementById('app-logo')
    const sidebarNavBtn = document.getElementById('sidebar__nav__btn')
    return {
      homeBannerSection,
      highLevelLinks,
      subLevelLinks,
      logo,
      sidebarNavBtn
    }
  }

  handleSidebarTheme = () => {
    const { isDarkTheme, updateSidebarTheme } = this.props
    const DOM = this.DOM()
    const currentBannerBottomEdgeCoordinate = this.globals.screenHeight - this.globals.lastScrollY
    const logoCoords = DOM.logo && DOM.logo.getBoundingClientRect()
    const highLevelLinksCoordsArr = Array.prototype.map.call(DOM.highLevelLinks, link => link.getBoundingClientRect().top)
    const topThesholdLinks = highLevelLinksCoordsArr.filter(link => link < currentBannerBottomEdgeCoordinate)
    const bottomThesholdLinks = Array.from(DOM.highLevelLinks).slice(topThesholdLinks.length)
    const subLevelLinksCoordsArr = Array.prototype.map.call(DOM.subLevelLinks, link => link.getBoundingClientRect().top)

    Array.from(DOM.highLevelLinks).forEach((elt, i) => this.toggleEltTheme(elt, 'navigation-link__href', 'dark'))
    Array.from(bottomThesholdLinks).forEach((elt, i) => this.toggleEltTheme(elt, 'navigation-link__href', 'light'))

    subLevelLinksCoordsArr.forEach((link, index) => {
      if (link > 0) {
        link > currentBannerBottomEdgeCoordinate
          ? this.toggleEltTheme(DOM.subLevelLinks[index], 'navigation-sub-link__href', 'light')
          : this.toggleEltTheme(DOM.subLevelLinks[index], 'navigation-sub-link__href', 'dark')
      }
    })

    logoCoords && logoCoords.top > currentBannerBottomEdgeCoordinate
      ? this.toggleEltTheme(DOM.logo, 'sidebar__logo', 'light')
      : this.toggleEltTheme(DOM.logo, 'sidebar__logo', 'dark')

    if (currentBannerBottomEdgeCoordinate < 0 && isDarkTheme) {
      updateSidebarTheme(false)
    }

    if (currentBannerBottomEdgeCoordinate >= this.globals.screenHeight && !isDarkTheme) {
      updateSidebarTheme(true)
    }

    DOM.sidebarNavBtn && DOM.sidebarNavBtn.getBoundingClientRect().top <= currentBannerBottomEdgeCoordinate
      ? this.toggleEltTheme(DOM.sidebarNavBtn, 'sidebar__nav__btn', 'dark')
      : this.toggleEltTheme(DOM.sidebarNavBtn, 'sidebar__nav__btn', 'light')

    this.globals.ticking = false
  }

  toggleEltTheme = (elt, classBase, currentTheme) => {
    if (currentTheme === this.LIGHT) {
      elt && elt.classList.remove(`${classBase}__${this.LIGHT}`)
      elt && elt.classList.add(`${classBase}__${this.DARK}`)
    } else {
      elt && elt.classList.remove(`${classBase}__${this.DARK}`)
      elt && elt.classList.add(`${classBase}__${this.LIGHT}`)
    }
  }

  cleanPollutedTheme = () => {
    const DOM = this.DOM()
    this.cleanPollutedItem(DOM.logo, 'sidebar__logo')
    this.cleanPollutedItem(DOM.highLevelLinks, 'navigation-link__href')
    this.cleanPollutedItem(DOM.subLevelLinks, 'navigation-sub-link__href')
    this.cleanPollutedItem(DOM.sidebarNavBtn, 'sidebar__nav__btn')
  }

  cleanPollutedItem = (elt, classBase) => {
    if (elt instanceof HTMLCollection) {
      Array.from(elt).map((item) => {
        item.classList.remove(`${classBase}__${this.LIGHT}`)
        item.classList.remove(`${classBase}__${this.DARK}`)
        return null
      })
    } else {
      elt.classList.remove(`${classBase}__${this.LIGHT}`)
      elt.classList.remove(`${classBase}__${this.DARK}`)
    }
  }

  checkTheme = () => {
    const { location, updateSidebarTheme } = this.props
    if (location.pathname.slice(1) === '') {
      window.scrollTo(0, 0)
      updateSidebarTheme(true)
    }
    this.setState({ mounted: true })
  }

  updateActiveLinkState = () => {
    const { location } = this.props
    const routeMatch = Object.values(URLS).find(url => url.path.slice(1) && location.pathname.includes(url.path.slice(1)))
    this.setState({ activeLinkId: routeMatch ? routeMatch.id : 0 })
  }

  handleActiveLinkIdChange = (id) => {
    const { location } = this.props
    if (location.pathname === '/' && this.globals.lastScrollY < this.globals.screenHeight) {
      // imitate scroll to update inner navigation block on expansion in situation when banner bottom edge is
      // somewhere in the middle of sidebar panel
      window.scrollTo(0, window.scrollY + 1)
      window.scrollTo(0, window.scrollY - 1)
    }
    this.setState({ activeLinkId: id })
  }

  render () {
    const {
      isDarkTheme, isNavBody, isLogoBody, location, width
    } = this.props
    const { activeLinkId, mounted } = this.state
    const Logo = () => (
      <Link
        to='/'
        className={cn('sidebar__logo', { sidebar__logo__bodyless: !isLogoBody }, 'focus focus_full')}
        id='app-logo'
      >
        <LogoIcon darkTheme={isDarkTheme} className='logo-icon' />
        <span className='sidebar__company'>7 glyphs</span>
      </Link>
    )
    return (
      <aside
        className={cn('sidebar', { sidebar__light: isDarkTheme && location.pathname === '/' })}
        style={{ display: mounted && isNavBody && isLogoBody ? 'block' : 'none' }}
        id='sidebar-section'
      >
        <div className='sidebar__container container f fd-c jc-sb'>
          <div className='sidebar__block'>
            <CSSTransition
              in={!isLogoBody}
              addEndListener={() => {}}
              classNames='logo-hide'
            >
              <Logo />
            </CSSTransition>
            <CSSTransition
              in={!isNavBody}
              addEndListener={() => {}}
              classNames='sidebar-hide'
            >
              <nav className={cn('sidebar__navigation', { sidebar__navigation__bodyless: !isNavBody })}>
                <ul>
                  {Object.keys(URLS).map(route => (
                    <NavigationLink
                      key={URLS[route].path}
                      {...URLS[route]}
                      activeLinkId={activeLinkId}
                      darkTheme={isDarkTheme}
                      screenWidth={width}
                      onActiveLinkIdChange={this.handleActiveLinkIdChange}
                      location={location}
                    />
                  ))}
                </ul>
              </nav>
            </CSSTransition>
          </div>
          <CSSTransition
            in={!isNavBody}
            addEndListener={() => {}}
            classNames='nav-btn-hide'
          >
            <Link
              to='/get-in-touch/brief'
              className={cn('sidebar__nav__btn', { sidebar__nav__btn__bodyless: !isNavBody }, 'focus focus_full')}
              id='sidebar__nav__btn'
            >
              <MuiButton kind={`${isDarkTheme ? 'invertedLight' : 'black'}`} fullWidth tabIndex={-1}>
                Brief us
              </MuiButton>
            </Link>
          </CSSTransition>
        </div>
      </aside>
    )
  }
}

const mapStateToProps = state => ({
  isDarkTheme: state.isDarkTheme,
  width: state.screenSize.width,
  isNavBody: state.isNavBody,
  isLogoBody: state.isLogoBody
})

const mapDispatchToProps = dispatch => ({
  updateSidebarTheme: val => dispatch(setSidebarTheme(val))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesktopNavigation))
