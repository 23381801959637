/* eslint-disable max-len */
import React from 'react'

const SecurityIcon = props => (
  <svg width={26} height={31} viewBox='0 0 26 31' {...props}>
    <path
      fill='#FFF'
      d='M13 0C9.677 0 4.951 1.127 2.446 1.765A3.258 3.258 0 0 0 0 4.913v7.004c0 5.361 3.18 9.904 6.259 13.11 3.078 3.205 6.165 5.141 6.165 5.141l.584.364.576-.372s3.082-1.98 6.157-5.205C22.817 21.729 26 17.195 26 11.917V4.913a3.26 3.26 0 0 0-2.442-3.148h-.004C21.049 1.127 16.324 0 13 0zm0 2.167c2.803 0 7.568 1.073 10.017 1.697.488.125.816.547.816 1.05v7.003c0 4.388-2.775 8.52-5.658 11.544-2.592 2.72-4.685 4.059-5.18 4.388-.493-.321-2.58-1.623-5.17-4.32-2.88-3-5.658-7.124-5.658-11.612V4.913c0-.507.323-.925.812-1.05C5.427 3.24 10.196 2.168 13 2.168zm7.9 4.921l-9.254 9.255-4.922-4.921-1.532 1.531 6.454 6.454L22.433 8.62 20.9 7.088z'
    />
  </svg>
)

export default SecurityIcon
