/* eslint-disable quote-props */
import axios from 'axios'

const create = () => {
  const baseURL = 'https://api.7glyphs.com'
  const api = axios.create({
    baseURL,
    headers: {
      'Accept': 'application/json'
    }
  })
  const formHeaders = { headers: { 'Content-Type': 'multipart/form-data', 'Content-Disposition': 'form-data' } }
  const getPosts = (page, limit) => api.get(`api/blog/post?page=${page}&limit=${limit}`)
  const searchPostBySlug = slug => api.get(`api/blog/post/${slug}`)
  const searchPostsByTag = (str, page, limit) => api.get(`api/blog/post/search/tag/${str}?page=${page}&limit=${limit}`)
  const searchPostsByAuthor = (str, page, limit) => api.get(`api/blog/post/search/author/${str}?page=${page}&limit=${limit}`)
  const searchPostsBySearch = (str, page, limit) => api.get(`api/blog/post/search/${str}?page=${page}&limit=${limit}`)
  const submitJoinUsForm = inputData => api.post('api/mail/join-us', inputData, formHeaders)
  const submitBriefForm = inputData => api.post('api/mail/brief', inputData, formHeaders)

  return {
    getPosts,
    searchPostBySlug,
    searchPostsByTag,
    searchPostsByAuthor,
    searchPostsBySearch,
    submitJoinUsForm,
    submitBriefForm
  }
}

export default {
  create
}
