import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import NavigationSubLink from '../NavigationSubLink/NavigationSubLink'
import tagPath from '../../../utils/tagPath'
import './style.css'

class NavigationLinkTablet extends Component {
  static propTypes = {
    activeLinkId: PropTypes.number.isRequired,
    darkTheme: PropTypes.bool,
    path: PropTypes.string.isRequired,
    activeRoute: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({})),
    onActiveLinkIdChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    routes: null,
    darkTheme: false
  }

  handleLinkClick = (id) => {
    const { onActiveLinkIdChange } = this.props
    onActiveLinkIdChange(id)
  }

  render () {
    const {
      path, name, routes, id, activeLinkId
    } = this.props
    return (
      <li
        className='navigation-link'
        onClick={() => this.handleLinkClick(id)}
        role='presentation'
      >
        <div
          className={cn('navigation-link__wrapper',
            { 'navigation-link__wrapper_active': activeLinkId === id }
          )}
        >
          <NavLink
            className={cn('navigation-link__href', { 'navigation-link__href_active': activeLinkId === id })}
            to={!routes ? path : tagPath(Object.values(routes[0])[0].path)}
          >
            {name}
          </NavLink>
        </div>
        <ul className={cn('navigation-link__list', { 'navigation-link__list_active': activeLinkId === id })}>
          {routes && routes.map(route => (
            <NavigationSubLink key={Math.random()} {...route[Object.keys(route)]} />
          ))}
        </ul>
      </li>
    )
  }
}

export default NavigationLinkTablet
