/* eslint-disable max-len */
import React from 'react'

const UXAnalyticsIcon = props => (
  <svg width={26} height={26} viewBox='0 0 26 26' {...props}>
    <path
      fill='#FFF'
      d='M12 0v2H3a3.01 3.01 0 0 0-3 3v12c0 1.648 1.352 3 3 3h9v2.203l-7.969 1.82.438 1.954L13 24.03l8.531 1.946.438-1.954L14 22.203V20h9c1.648 0 3-1.352 3-3V5c0-1.648-1.352-3-3-3h-9V0h-2zM3 4h20c.563 0 1 .438 1 1v12c0 .563-.438 1-1 1H3c-.563 0-1-.438-1-1V5c0-.563.438-1 1-1zm15.008 2.453l-5.055 4.86-3.984-3.29-4.89 4.493 1.343 1.468 3.61-3.312 4.015 3.312 5.047-4.843 2.508 2.125 1.296-1.532-3.89-3.28z'
    />
  </svg>
)

export default UXAnalyticsIcon
