import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ScrollAnimation from '../../_common/ScrollAnimation'
import './style.css'

TechStackList.propTypes = {
  stackItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
    linkTo: PropTypes.string
  }))
}

function TechStackList (props) {
  const { stackItems } = props
  return (
    <div className='service-techstack'>{stackItems.map(
      (item, i) => (
        <ScrollAnimation key={item.name} className={cn('service-techstack__item', { [`tech-item-${item.id}`]: true })} effect='fadeIn' delay={150 * i}>
          <img className='tech-icon' src={item.icon} alt={item.name} />
          <div className='tech-content'>
            <div className='tech-content__name'>
              {item.name}
              {item.label && <span className='tech-content__label'>{item.label}</span>}
            </div>
            <span className='tech-content__link'>{item.linkTo}</span>
          </div>
        </ScrollAnimation>
      )
    )}
    </div>
  )
}

export default TechStackList
