/* eslint-disable max-len */
import React from 'react'

const IntegrationIcon = props => (
  <svg width={26} height={26} {...props} viewBox='0 0 26 26'>
    <path
      fill='#FFF'
      d='M3.664 0A1.68 1.68 0 0 0 2 1.664V6.25c0 .531.234.922.531 1.219.297.297.688.531 1.219.531H4v2.055L7.281 8H12v.25c0 .531.234.922.531 1.219.297.297.688.531 1.219.531h4.969L22 12.055V10h.25c.531 0 .922-.234 1.219-.531.297-.297.531-.688.531-1.219V3.664A1.68 1.68 0 0 0 22.336 2H14v-.336A1.68 1.68 0 0 0 12.336 0H3.664zM4 2h8v4H6.719L6 6.445V6H4V2zm10 2h8v4h-2v.445L19.281 8H14V4zM6 14c-2.195 0-4 1.805-4 4a4.01 4.01 0 0 0 1.125 2.766C1.273 21.789 0 23.742 0 26h2c0-2.219 1.781-4 4-4 2.219 0 4 1.781 4 4h2c0-2.258-1.273-4.21-3.125-5.234A4.006 4.006 0 0 0 10 18c0-2.195-1.805-4-4-4zm14 0c-2.195 0-4 1.805-4 4a4.01 4.01 0 0 0 1.125 2.766C15.273 21.789 14 23.742 14 26h2c0-2.219 1.781-4 4-4 2.219 0 4 1.781 4 4h2c0-2.258-1.273-4.21-3.125-5.234A4.006 4.006 0 0 0 24 18c0-2.195-1.805-4-4-4zM6 16c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm14 0c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2z'
    />
  </svg>
)

export default IntegrationIcon
