import React from 'react'
import PropTypes from 'prop-types'
import ScrollAnimation from '../../_common/ScrollAnimation'
import './style.css'

ServiceFeatures.propTypes = {
  keyItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.shape({})
  }))
}

function ServiceFeatures (props) {
  const { keyItems } = props
  return (
    <div className='service-features'>{keyItems.map(
      (feature, index) => (
        <ScrollAnimation key={index} className='service-features__feature' effect='fadeIn' delay={150 * index}>
          <span className='feature-icon'>{feature.icon}</span>
          <span className='feature-title'>{feature.title}</span>
        </ScrollAnimation>
      )
    )}
    </div>
  )
}

export default ServiceFeatures
