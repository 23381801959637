import React from 'react'
import PropTypes from 'prop-types'

LogoIcon.propTypes = {
  darkTheme: PropTypes.bool,
  className: PropTypes.string
}

LogoIcon.defaultProps = {
  darkTheme: false,
  className: 'logo-icon'
}

export default function LogoIcon (props) {
  const { darkTheme, className } = props
  return (
    <svg width='21' height='21' viewBox='0 0 21 21' role='img' aria-label='Logo' className={className}>
      <g fill={`${darkTheme ? '#ffffff' : '#4a4a4a'}`} fillRule='evenodd'>
        <circle className='circle1' cx='1.75' cy='1.75' r='1.75' />
        <circle className='circle2' cx='1.75' cy='18.55' r='1.75' />
        <circle className='circle3' cx='10.15' cy='1.75' r='1.75' />
        <circle className='circle4' cx='10.15' cy='10.15' r='1.75' />
        <circle className='circle5' cx='18.55' cy='1.75' r='1.75' />
        <circle className='circle6' cx='18.55' cy='10.15' r='1.75' />
        <circle className='circle7' cx='18.55' cy='18.55' r='1.75' />
      </g>
    </svg>
  )
}
