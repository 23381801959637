/* eslint-disable no-shadow */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { connect } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Reboot from '@material-ui/core/CssBaseline'
import JsonLD from '../components/JsonLD'
import ScrollToTop from '../components/_common/ScrollToTop'
import Sidebar from '../components/navigation/DesktopNavigation/DesktopNavigation'
import HeaderModile from '../components/navigation/MobileNavigation/MobileNavigation'
import HeaderTablet from '../components/navigation/TabletNavigation/TabletNavigation'
import {
  Home, Services, Packages, Process, Technologies, Projects, CaseStudies, Clients, Company, Team,
  Office, Blog, Post, Visit, Brief, Join
} from '../screens'
import { MOBILE_VIEW, TABLET_VIEW } from '../constants/dimentions'
import Package from '../components/packages/Package/Package'
import EmptyRoute from '../components/_common/EmptyRoute/EmptyRoute'
import muiTheme from '../styles/muiTheme'
import { updateScreenDimentions } from '../redux/reducer'
import { tagProject } from '../constants/urls'
import * as microData from '../content/structuredDataLD.json'
import './style.css'

const theme = createMuiTheme(muiTheme)

class App extends Component {
  static propTypes = {
    updateScreenSizes: PropTypes.func,
    width: PropTypes.number
  }

  updateDimensions = () => {
    const { updateScreenSizes } = this.props
    updateScreenSizes({
      width: window.innerWidth
    })
    smoothscroll.polyfill()
  }

  componentDidMount () {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions, { passive: true })
    window.addEventListener('beforeprint', this.handleScrollAnimationOnBeforePrint)
    window.addEventListener('afterprint', this.handleScrollAnimationOnAfterPrint)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
    window.removeEventListener('beforeprint', this.handleScrollAnimationOnBeforePrint)
    window.removeEventListener('afterprint', this.handleScrollAnimationOnAfterPrint)
  }

  handleScrollAnimationOnBeforePrint = () => {
    const animScrollElts = document.getElementsByClassName('scroll-animation')
    Array.from(animScrollElts).length && Array.from(animScrollElts).map(elt => elt.classList.add('scroll-animation-print'))
  }

  handleScrollAnimationOnAfterPrint = () => {
    const animScrollElts = document.getElementsByClassName('scroll-animation')
    Array.from(animScrollElts).length && Array.from(animScrollElts).map(elt => elt.classList.remove('scroll-animation-print'))
  }

  setNavigationPanel = () => {
    const { width } = this.props
    if (width > TABLET_VIEW) return <Sidebar />
    if (width <= TABLET_VIEW && width > MOBILE_VIEW) return <HeaderTablet />
    return <HeaderModile />
  }

  render () {
    const { width } = this.props
    const ConnectedRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={props => (<Component {...props} screenWidth={width} />)} />)

    return (
      <BrowserRouter>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            <div className='app'>
              <Reboot />
              { this.setNavigationPanel() }
              <main className='app__main fb-100'>
                <Switch>
                  <ConnectedRoute exact path='/' component={Home} />
                  <ConnectedRoute exact path='/approach/services/:slug' component={Services} />
                  <ConnectedRoute exact path='/approach/packages' component={Packages} />
                  <ConnectedRoute exact path='/approach/packages/:category/:package' component={Package} />
                  <ConnectedRoute exact path='/approach/process/:slug' component={Process} />
                  <ConnectedRoute path='/approach/technologies' component={Technologies} />
                  <Redirect exact from='/portfolio/projects' to={tagProject`/portfolio/projects/`} />
                  <ConnectedRoute path='/portfolio/projects/:slug' component={Projects} />
                  <ConnectedRoute exact path='/portfolio/case-studies' component={CaseStudies} />
                  <ConnectedRoute exact path='/portfolio/clients' component={Clients} />
                  <ConnectedRoute exact path='/agency/company' component={Company} />
                  <ConnectedRoute exact path='/agency/team' component={Team} />
                  <ConnectedRoute exact path='/agency/office' component={Office} />
                  <ConnectedRoute exact path='/blog/post/:slug' component={Post} />
                  <ConnectedRoute exact path='/blog' component={Blog} />
                  <ConnectedRoute path='/blog/posts/:category/:key' component={Blog} />
                  <ConnectedRoute exact path='/get-in-touch/visit' component={Visit} />
                  <ConnectedRoute path='/get-in-touch/brief' component={Brief} />
                  <ConnectedRoute exact path='/get-in-touch/join' component={Join} />
                  <Route path='/sitemap.xml' exact />
                  <Route path='/robots.txt' exact />
                  <ConnectedRoute component={EmptyRoute} />
                </Switch>
              </main>
              <JsonLD data={microData} />
            </div>
          </MuiThemeProvider>
        </ScrollToTop>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({
  width: state.screenSize.width
})

const mapDispatchToProps = dispatch => ({
  updateScreenSizes: sizes => dispatch(updateScreenDimentions(sizes))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
