import IMAGES from '../utils/imagesProvider'

export default {
  title: 'Process – The way we work!',
  subTitle: 'Our work process is similar to launching a space shuttle in that we have to pay attention to the smallest details. Here at 7 glyphs, we take each stage very seriously and make sure that our client is highly involved in every step of the way. Our goal is to build a software that adheres to the client’s expectations from the design down to the development.',
  stages: [
    {
      number: '01',
      name: 'Research',
      text: 'We analyze similar services around the world, examine current user needs, consider modern ' +
        'technologies, and use UI solutions that are easy to use and understandable to the users. ' +
        'We brainstorm all possible ideas with the team and visualize all application features and future enhancements ' +
        'in a documented project proposal. We also work with the clients regarding backend services support.',
      imgSet: IMAGES.process.stage1,
      slug: 'research'
    },
    {
      number: '02',
      name: 'UX Wireframing',
      text: 'We draw mind maps and logical schemes that describe user experience and screen flow. ' +
        'We wireframe all unique screens showing the functionality, controls, and screen layout. ' +
        'We approve, test, and plan behavior scenarios to create the best view which works for the clients and its users. ' +
        'Lastly, we make sure that all features fit well into the mobile layout for the clients\' and users\' ease of use.',
      imgSet: IMAGES.process.stage2,
      slug: 'ux_wireframing'
    },
    {
      number: '03',
      name: 'Design',
      text: 'We consider client requirements and follow design guidelines to create the right application vibe. ' +
        'We design UI based on actual animations and define overall look, color palette, imagery styles, fonts, ' +
        'text styles, and iconography that give a feeling of a modern and light application flow. Our team uses Zeplin ' +
        'to create assets for each screen resolution, then test and present the design outcome on an actual device with a ' +
        'full design documentation.',
      imgSet: IMAGES.process.stage3,
      slug: 'design'
    },
    {
      number: '04',
      name: 'Development',
      text: 'Using the right technologies to achieve the best results (Swift 3/4, Android 7, and Kotlin), we write ' +
        'native codes for each platform and consider platform restraints. We have API integration, versioning support, ' +
        'and multiple environment support available for the client to test on commit to the repository. ' +
        'Our codes are checked by security professionals so all data is protected and raised security concerns are fixed.',
      imgSet: IMAGES.process.stage4,
      slug: 'development'
    },
    {
      number: '05',
      name: 'Testing',
      text: 'We write test cases and test applications against them prior to every release. ' +
        'Our team automates test cases as much as possible. Lastly, we test not only success flows but also cover ' +
        'failure user behavior and edge cases.',
      imgSet: IMAGES.process.stage5,
      slug: 'testing'
    },
    {
      number: '06',
      name: 'Release',
      text: 'We prepare assets and release application to the AppStore. We prepare assets and release application to the Google Play. ',
      imgSet: IMAGES.process.stage6,
      slug: 'release'
    },
    {
      number: '07',
      name: 'Support',
      text: 'We use bug tracking to track bugs and new features. We prepare reports on the every month basis. ' +
        'We provide reactive support for major incidents. We monitor the app in realtime and react on bugs immediately.',
      imgSet: IMAGES.process.stage7,
      slug: 'support'
    }
  ]
}
