/* eslint-disable max-len */
import IMAGES from '../utils/imagesProvider'

export default [
  {
    id: 0,
    author: 'Gabriel Long',
    authorPosition: 'Digital Product & Content Manager, Air New Zealand',
    companyDescription: 'Cheap Flights with Air New Zealand\'s grabaseat - home of cheap airfares, hotels and travel packages.',
    avatar: {
      sm: IMAGES.testimonials.gabriel_long,
      md: IMAGES.testimonials.gabriel_long,
      lg: IMAGES.testimonials.gabriel_long,
      alt: 'Gabriel Long'
    },
    headline: 'very easy to work with',
    text: 'Great developers, have used them to support our ecommerce app that was originally ' +
      'developed by another agency. Very easy to work with.'
  },
  {
    id: 1,
    author: 'Matt Knight',
    authorPosition: 'CEO, Shared Space',
    companyDescription: 'Sharedspace is a simple way to find and list shared commercial space in New Zealand.',
    avatar: {
      sm: IMAGES.testimonials.matt_knight,
      md: IMAGES.testimonials.matt_knight,
      lg: IMAGES.testimonials.matt_knight,
      alt: 'Matt Knight'
    },
    headline: 'impressed with their quality of work',
    text: 'I\'ve worked with 7 glyphs on a number of projects ranging from web development, ' +
      'app development and design. I\'m always impressed with their quality of work and their ' +
      'punctuality. They are a great team to work with and I highly recommend them.'
  },
  {
    id: 2,
    author: 'Abi Bennett',
    authorPosition: 'Head of Operations, StarShipIT',
    companyDescription: 'StarShipIT – multi carrier shipping software.',
    avatar: {
      sm: IMAGES.testimonials.abi_bennett,
      md: IMAGES.testimonials.abi_bennett,
      lg: IMAGES.testimonials.abi_bennett,
      alt: 'Abi Bennett'
    },
    headline: 'very detailed process',
    text: 'Great team to work with, very detailed process. The feedback from our users is fantastic they love the new UI.'
  },
  {
    id: 3,
    author: 'Toss Grumley',
    authorPosition: 'CEO, Wolf & Fox',
    companyDescription: 'Wolf & Fox - growth business coaching & advisory',
    avatar: {
      sm: IMAGES.testimonials.toss_grumley,
      md: IMAGES.testimonials.toss_grumley,
      lg: IMAGES.testimonials.toss_grumley,
      alt: 'Toss Grumley'
    },
    headline: 'top app and web development company',
    text: 'I truly believe 7 glyphs are New Zealand\'s top app and web development company. ' +
      'I recommend all my clients to them who are looking for high-end, technically excellent ' +
      'products. I wouldn\'t hesitate to recommend Igor and his team to anyone looking for the ' +
      'best app and web builds available in New Zealand.'
  },
  {
    id: 4,
    author: 'Nick MacAvoy',
    authorPosition: 'CTO, Goodnest',
    companyDescription: 'Goodnest – book local home services faster, cheaper & easier.',
    avatar: {
      sm: IMAGES.testimonials.nick_macavoy,
      md: IMAGES.testimonials.nick_macavoy,
      lg: IMAGES.testimonials.nick_macavoy,
      alt: 'Nick MacAvoy'
    },
    headline: 'brilliant in all capacities',
    text: 'We\'ve worked with 7 glyphs for many years on many projects now so perhaps not all ' +
      'five star reviews are equal. We\'ve recommended 7 glyphs to many and continue to work with ' +
      'Igor and the talented team now and in the future. We\'ve found 7 glyphs brilliant in all ' +
      'capacities – project management, design, UX, UI, iOS & Android builds but most of all, ' +
      'great to work with.'
  },
  {
    id: 5,
    author: 'Hiten Parbhu',
    authorPosition: 'Founder & CEO, Rogue Travel',
    companyDescription: 'Rogue Travel – the new travel management app for businesses going places.',
    avatar: {
      sm: IMAGES.testimonials.hiten_parbhu,
      md: IMAGES.testimonials.hiten_parbhu,
      lg: IMAGES.testimonials.hiten_parbhu,
      alt: 'Hiten Parbhu'
    },
    headline: 'absolutely incredible',
    text: 'Absolutely incredible team to work with. I\'ve had the pleasure of having 7 Glyphs build my complex web application and it has been amazing. From their top notch UX talent to their development skills, Igor and his team are truly NZ\'s best web application company. I look forward to continuing my work with them for many more projects to come.'
  },
  {
    id: 6,
    author: 'Bill Chalmers',
    authorPosition: 'Creative Director, StrongHer',
    companyDescription: 'StrongHer – Nutrition & Workout Planner.',
    avatar: {
      sm: IMAGES.testimonials.bill_chalmers,
      md: IMAGES.testimonials.bill_chalmers,
      lg: IMAGES.testimonials.bill_chalmers,
      alt: 'Bill Chalmers'
    },
    headline: 'is simply amazing',
    text: 'The team at 7glyphs is simply amazing. They built the whole design and api for our app on Apple and Google and also our website. The design flow is incredible and they really think outside the box. You meet gifted people only so often in life and these guys are gifted with this stuff. We ran into them by luck but my gosh aren\'t we glad we did! 7glyphs are frickin amazing I don’t think you\'ll find anyone better. One thing I like is they personally invest themselves into what you are doing like its their own project. Thanks so much guys we really appreciate all the hard work you guys have done. All our clients are blown away with the APP and WEBSITE!'
  },
  {
    id: 7,
    author: 'Michal Garvey',
    authorPosition: 'Founder & Director, Foodprint',
    companyDescription: 'Foodprint – Reduce food waste. Help the environment.',
    avatar: {
      sm: IMAGES.testimonials.michal_garvey,
      md: IMAGES.testimonials.michal_garvey,
      lg: IMAGES.testimonials.michal_garvey,
      alt: 'Michal Garvey'
    },
    headline: 'top of their game',
    text: 'From brand development, design, UI, UX, development, release and support the team at 7glyphs are top of their game.  Their work, communication, project management and punctuality are exceptional.  From the first meeting, I knew they were the right team to bring my ideas into reality. I can’t recommend 7glyphs highly enough and in fact have recommended them to a friend even prior to the completion of my project.'
  },
  {
    id: 8,
    author: 'Rochelle Sheldon',
    authorPosition: 'Founder & Director, Joyable',
    companyDescription: 'Joyable is the global hub for heartfelt friends and family funded collaborative gifting.',
    avatar: {
      sm: IMAGES.testimonials.rochelle_sheldon,
      md: IMAGES.testimonials.rochelle_sheldon,
      lg: IMAGES.testimonials.rochelle_sheldon,
      alt: 'Rochelle Sheldon'
    },
    headline: 'the most professional and friendly',
    text: 'I was introduced to Igor and the 7glyphs team from a friend and I\'m so pleased I was. They turned an idea into reality, and that reality is our business Joyable. From the concept, brand, build, completion and support every step of the way has been professional, on time and seamless...with only one little hiccup that we were able to work through quickly together. I\'ve worked with many IT teams and people in the past and I can hand on heart say that 7glyphs is the most professional and friendly I\'ve come across. I look forward to many years of partnership ahead.'
  },
  {
    id: 9,
    author: 'Dr. Shaz MacAvoy',
    authorPosition: 'Founder & Director, Milk Orthodontics',
    companyDescription: '',
    avatar: {
      sm: IMAGES.testimonials.shaz_macavoy,
      md: IMAGES.testimonials.shaz_macavoy,
      lg: IMAGES.testimonials.shaz_macavoy,
      alt: 'Dr. Shaz MacAvoy'
    },
    headline: 'excellent service',
    text: 'Igor and his team are highly professional and provided excellent service throughout the redevelopment of our website. We absolutely love the new website, the design is beautiful and we will be recommending 7 glyphs widely.'
  },
]
