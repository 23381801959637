import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const H2 = ({ children, className }) => <h2 className={cn('H2', className)}>{children}</h2>

H2.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default H2
