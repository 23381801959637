/* eslint-disable max-len */
import React from 'react'

const VisitUsIcon = props => (
  <svg width={51} height={58} viewBox='0 0 51 58' {...props} className='visit-us-icon'>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FFF'
        d='M32.5 56.5h-26c-2.75 0-5-2.25-5-5v-45c0-2.75 2.25-5 5-5h26c2.75 0 5 2.25 5 5v45c0 2.75-2.25 5-5 5z'
      />
      <circle cx={19.5} cy={51} r={2} fill='#E9EEF4' />
      <path
        fill='#444B54'
        fillRule='nonzero'
        d='M32.5 58h-26C2.9 58 0 55.1 0 51.5v-45C0 2.9 2.9 0 6.5 0h26C36.1 0 39 2.9 39 6.5v45c0 3.6-2.9 6.5-6.5 6.5zM6.5 3C4.55 3 3 4.55 3 6.5v45C3 53.45 4.55 55 6.5 55h26c1.95 0 3.5-1.55 3.5-3.5v-45C36 4.55 34.45 3 32.5 3h-26z'
      />
      <path fill='#E9EEF4' d='M6 6h27v39.5H6z' />
      <g fill='#FFF'>
        <path
          d='M10.05 51.8c-.15 0-.35-.05-.5-.1-.8-.3-1.2-1.15-.9-1.9l16.4-45.1c.3-.8 1.15-1.2 1.9-.9.8.3 1.2 1.15.9 1.9l-16.4 45.1c-.2.65-.8 1-1.4 1z'
        />
        <path
          d='M22.2 18.45c-.15 0-.35-.05-.5-.1l-17.45-6.3c-.8-.3-1.2-1.15-.9-1.9.3-.8 1.15-1.2 1.9-.9l17.4 6.35c.8.3 1.2 1.15.9 1.9-.2.6-.75.95-1.35.95zM34.1 44.1c-.15 0-.35-.05-.5-.1l-18.8-6.8c-.8-.3-1.2-1.15-.9-1.9.3-.75 1.15-1.2 1.9-.9l18.8 6.85c.8.3 1.2 1.15.9 1.9-.2.55-.8.95-1.4.95z'
        />
      </g>
      <circle cx={19.5} cy={24.35} r={6.5} fill='#ACF7D0' opacity={0.3} />
      <circle cx={19.5} cy={24.35} r={1.5} fill='#8DE0B5' />
      <path
        fill='#444B54'
        d='M42.5 58h-10c-.85 0-1.5-.65-1.5-1.5s.65-1.5 1.5-1.5h10c.85 0 1.5.65 1.5 1.5s-.65 1.5-1.5 1.5zm7 0c-.4 0-.8-.15-1.05-.45a1.09 1.09 0 0 1-.2-.25c-.05-.1-.1-.15-.15-.25-.05-.1-.05-.2-.1-.3 0-.1-.05-.2-.05-.3 0-.4.15-.8.45-1.05.55-.55 1.55-.55 2.1 0 .3.3.45.65.45 1.05 0 .1 0 .2-.05.3 0 .1-.05.2-.1.3-.05.1-.1.2-.15.25-.05.1-.1.15-.2.25-.15.3-.55.45-.95.45z'
      />
    </g>
  </svg>
)
export default VisitUsIcon
