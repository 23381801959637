import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './typography.css'

const H1 = ({ children, className }) => <h1 className={cn('H1', className)}>{children}</h1>

H1.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default H1
