import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import PackService from '../PackService/PackService'
import ExamplesSlider from '../ExamplesSlider/ExamplesSlider'
import './style.css'

PackServices.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({})),
  examples: PropTypes.arrayOf(PropTypes.shape({})),
  images: PropTypes.shape({}),
  title: PropTypes.string,
  category: PropTypes.string
}

function PackServices (props) {
  const {
    services, images, title, examples, category
  } = props
  return (
    <div className='package-services'>
      <div className='service-cover-img'>
        <img src={images.serviceCover} alt={title} />
      </div>
      <div className={cn('services-container', { 'support-grid': category === 'care' })}>
        {services.map((service, index) => (
          <Fragment key={Math.random()}>
            <PackService {...service} index={index} level='service' />
            {service.subServices &&
            service.subServices.map(subService => (
              <PackService {...subService} key={Math.random()} level='subservice' />
            ))
            }
          </Fragment>
        ))}
      </div>
      {examples && <ExamplesSlider examples={examples} />}
    </div>
  )
}

export default PackServices
