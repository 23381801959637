import React from 'react'
import PropTypes from 'prop-types'

Picture.propTypes = {
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  imgStyle: PropTypes.shape({})
}

export default function Picture (props) {
  const {
    sm, md, lg, alt, className, imgStyle
  } = props
  return (
    <picture className={className}>
      <source
        media='(min-width: 1200px)'
        srcSet={`${lg}`}
      />
      <source
        media='(min-width: 768px)'
        srcSet={md ? `${md}` : `${lg}`}
      />
      <img src={`${sm}`} alt={alt} style={imgStyle} loading='lazy' className={`${className}__img`} />
    </picture>
  )
}
