/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'

JsonLD.propTypes = {
  data: PropTypes.shape({})
}

export default function JsonLD (props) {
  const { data } = props
  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  )
}
