import React from 'react'

const ArrowDownIcon = props => (
  <svg width={40} height={40} viewBox='0 0 40 40' {...props}>
    <g fill='none' fillRule='evenodd'>
      <rect width={40} height={40} rx={20} fill='#000' fillOpacity={0.7} />
      <path
        stroke='#FFF'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M24 19l-4.083 4L16 19'
      />
    </g>
  </svg>
)

export default ArrowDownIcon
