import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'
import store from './redux/store'
import App from './App/App'
import registerServiceWorker from './utils/registerServiceWorker'
import './utils/polyfills'
import './styles/base.css'

const tagManagerArgs = {
  gtmId: 'GTM-NGS5Z9H'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
registerServiceWorker()
